import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import {
  ADMIN_FETCH_MEMBERS_QUERY,
  ADMIN_FETCH_MEMBER_QUERY,
  ADMIN_MAKE_MEMBER,
  ADMIN_UPDATE_MEMBER,
} from "../../graphql/queries";
import {
  AdminFetchMember,
  AdminFetchMemberVariables,
} from "../../graphql/__generated__/AdminFetchMember";
import {
  AdminMakeMember,
  AdminMakeMemberVariables,
} from "../../graphql/__generated__/AdminMakeMember";
import {
  AdminUpdateMember,
  AdminUpdateMemberVariables,
} from "../../graphql/__generated__/AdminUpdateMember";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { generateMemberPagePath } from "../Member/MemberPage";

import "./MemberDetailsPage.css";

export const MEMBER_DETAILS_PAGE_PAGE_PATH_PATTERN = "/members/:username";

export function generateMemberDetailsPagePath(username: string): string {
  return generatePath(MEMBER_DETAILS_PAGE_PAGE_PATH_PATTERN, {
    username,
  });
}

/**
 * Return undefined for empty value (not set).
 * Return value otherwise.
 */
function optionalStrField(value: string): string | undefined {
  return value !== "" ? value : undefined;
}

/**
 * Return undefined if value doesn't change.
 * Return null if value is empty (clear).
 * Return value otherwise.
 */
function removableOptionalStrField(
  strValue: string,
  prevValue: string | null
): string | undefined | null {
  const value = strValue !== "" ? strValue : null;
  if (value === prevValue) {
    // undefined means "ignore"
    return undefined;
  }
  if (value === "") {
    // null means "clear"
    return null;
  }
  return value;
}

const MemberDetailsPage: React.FC = React.memo(() => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { username } = useParams<{ username: string }>();
  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (username && username === "new" ? "CREATE" : "UPDATE"),
    [username]
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [district, setDistrict] = useState("");
  const [password, setPassword] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);

  const {
    data: memberData,
    loading: loadingLead,
    error: fetchMemberError,
  } = useQuery<AdminFetchMember, AdminFetchMemberVariables>(
    ADMIN_FETCH_MEMBER_QUERY,
    {
      variables: {
        username,
      },
      skip: mode === "CREATE",
    }
  );

  const [requestMakeMember, { loading: requestingMakeMember }] = useMutation<
    AdminMakeMember,
    AdminMakeMemberVariables
  >(ADMIN_MAKE_MEMBER);

  const [requestUpdateMember, { loading: requestingUpdateMember }] =
    useMutation<AdminUpdateMember, AdminUpdateMemberVariables>(
      ADMIN_UPDATE_MEMBER
    );

  useErrorHandler(fetchMemberError, {
    name: "memberData",
  });

  const handleMakeMemberError = useImperativeErrorHandler({
    name: "makeMember",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          email: "電郵",
          password: "密碼",
          phone: "電話",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const handleUpdateMemberError = useImperativeErrorHandler({
    name: "updateMember",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          email: "電郵",
          password: "密碼",
          phone: "電話",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const makeMember = useCallback(async () => {
    try {
      await requestMakeMember({
        variables: {
          adminInput: {
            firstName: optionalStrField(firstName),
            lastName: optionalStrField(lastName),
            email: email,
            password: password,
            gender: optionalStrField(gender),
            phone: optionalStrField(phone),
            district: optionalStrField(district),
            blocked: isBlocked,
          },
        },
      });

      evictQueryCache(apolloClient.cache, ADMIN_FETCH_MEMBERS_QUERY);
      history.replace(generateMemberPagePath());
    } catch (error) {
      handleMakeMemberError(error);
    }
  }, [
    requestMakeMember,
    firstName,
    lastName,
    email,
    password,
    gender,
    phone,
    district,
    isBlocked,
    apolloClient.cache,
    history,
    handleMakeMemberError,
  ]);

  const updateMember = useCallback(async () => {
    try {
      if (!memberData) {
        return;
      }
      const existingMember = memberData.adminFetchMember;

      await requestUpdateMember({
        variables: {
          username,
          adminInput: {
            firstName: removableOptionalStrField(
              firstName,
              existingMember.firstName
            ),
            lastName: removableOptionalStrField(
              lastName,
              existingMember.lastName
            ),
            password: optionalStrField(password),
            gender: removableOptionalStrField(gender, existingMember.gender),
            phone: removableOptionalStrField(phone, existingMember.phone),
            district: removableOptionalStrField(
              district,
              existingMember.district
            ),
            blocked:
              isBlocked !== existingMember.blocked ? isBlocked : undefined,
          },
        },
      });
    } catch (error) {
      handleUpdateMemberError(error);
    }
  }, [
    district,
    firstName,
    gender,
    handleUpdateMemberError,
    isBlocked,
    lastName,
    memberData,
    password,
    phone,
    requestUpdateMember,
    username,
  ]);

  const onSave = useCallback(() => {
    if (mode === "CREATE") {
      makeMember();
    } else {
      updateMember();
    }
  }, [makeMember, mode, updateMember]);

  useEffect(() => {
    if (memberData) {
      setFirstName(memberData.adminFetchMember.firstName ?? "");
      setLastName(memberData.adminFetchMember.lastName ?? "");
      setGender(memberData.adminFetchMember.gender ?? "");
      setEmail(memberData.adminFetchMember.email);
      setPhone(memberData.adminFetchMember.phone ?? "");
      setDistrict(memberData.adminFetchMember.district ?? "");
      setPassword("");
      setIsBlocked(memberData.adminFetchMember.blocked);
    }
  }, [memberData]);

  return (
    <IonPage>
      <IonLoading
        isOpen={loadingLead || requestingMakeMember || requestingUpdateMember}
      />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton color="primary" defaultHref="/" />
          </IonButtons>
          <IonTitle>會員</IonTitle>

          <IonButtons slot="primary">
            <IonButton color="primary" onClick={onSave}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>{`${firstName} ${lastName}`}</h1>
            </IonCardTitle>
            <IonLabel>
              <h6>{`ID: ${mode === "UPDATE" ? username : "-"}`}</h6>
            </IonLabel>
          </IonCardContent>
        </IonCard>

        <IonCard className="lead-details-card ">
          <IonCardContent>
            <IonItem lines="none" color="white" disabled={true}>
              <IonLabel color="primary" position="stacked">
                加入日期
              </IonLabel>
              <IonText>
                {memberData
                  ? moment(memberData.adminFetchMember.createdAt).format(
                      "DD MMM YYYY"
                    )
                  : ""}
              </IonText>
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                電郵 *
              </IonLabel>
              <IonInput
                placeholder="輸入電郵"
                disabled={mode === "UPDATE"}
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
              />
            </IonItem>
            {mode === "CREATE" && (
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  密碼 *
                </IonLabel>
                <IonInput
                  type="password"
                  placeholder="輸入密碼"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                />
              </IonItem>
            )}
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                性別
              </IonLabel>
              <IonSelect
                value={gender}
                okText="Okay"
                cancelText="Dismiss"
                onIonChange={(e) => setGender(e.detail.value!)}
              >
                <IonSelectOption value="">-</IonSelectOption>
                <IonSelectOption value="M">M</IonSelectOption>
                <IonSelectOption value="F">F</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                電話
              </IonLabel>
              <IonInput
                placeholder="輸入電話"
                value={phone}
                onIonChange={(e) => setPhone(e.detail.value!)}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                分區
              </IonLabel>
              <IonInput
                placeholder="輸入區域"
                value={district}
                onIonChange={(e) => setDistrict(e.detail.value!)}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        {mode === "UPDATE" && (
          <IonCard color="white" className="no-margin-card">
            <IonCardContent>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  重設密碼
                </IonLabel>
                <IonInput
                  type="password"
                  placeholder="輸入新密碼"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <IonCard color="white" className="no-margin-card">
          <IonCardContent>
            <IonItem color="white" lines="none">
              <IonLabel>暫停使用</IonLabel>
              <IonToggle
                checked={isBlocked}
                color="danger"
                onClick={() => setIsBlocked((prev) => !prev)}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
});

export default MemberDetailsPage;
