import { useMutation } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useMemo, useState } from "react";
import {
  ADMIN_MAKE_PROTECTED_FILE,
  ADMIN_CONFIRM_FILE_UPLOADED,
  ADMIN_RENEW_PROTECTED_FILE,
} from "../../graphql/queries";
import {
  AdminConfirmFileUploaded,
  AdminConfirmFileUploadedVariables,
  AdminConfirmFileUploaded_adminConfirmFileUploaded,
} from "../../graphql/__generated__/AdminConfirmFileUploaded";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import { ProtectedFileStatusEnum } from "../../types/ProtectedFileStatus";
import "./UploadModal.css";
import { UploadModalFileType } from "./UploadModalFileType";

const UploadModal: React.FC<{
  isShowModal: boolean;
  onUploadFinished: (
    file: AdminConfirmFileUploaded_adminConfirmFileUploaded
  ) => void;
  dismissModal: any;
  existFileUuid?: string;
  currentFileStatus: ProtectedFileStatusEnum;
  path: string;
  title?: string;
  fileType?: UploadModalFileType;
}> = ({
  isShowModal,
  onUploadFinished,
  currentFileStatus,
  dismissModal,
  existFileUuid = "",
  path,
  title,
  fileType = UploadModalFileType.all,
}) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const initModal = () => {
    setSelectedFile(null);
    setIsUploaded(false);
    setIsUploading(false);
  };

  const selectedFileName = useMemo(() => {
    if (selectedFile && selectedFile.name) {
      return selectedFile.name;
    }
    return "";
  }, [selectedFile]);

  const selectedFileType = useMemo(() => {
    if (selectedFile && selectedFile.type) {
      return selectedFile.type;
    }
    return "";
  }, [selectedFile]);

  const [submitMakeProtectedFile, { loading: loadingMakeProtectedFile }] =
    useMutation(ADMIN_MAKE_PROTECTED_FILE);

  const [submitRenewProtectedFile, { loading: loadingRenewProtectedFile }] =
    useMutation(ADMIN_RENEW_PROTECTED_FILE);

  const [confirmFileUploaded, { loading: fileUploadLoading }] = useMutation<
    AdminConfirmFileUploaded,
    AdminConfirmFileUploadedVariables
  >(ADMIN_CONFIRM_FILE_UPLOADED);

  const handelStartMakingProtectedFileError = useImperativeErrorHandler({
    name: "startMakingProtectedFile",
  });

  const startMakingProtectedFile = async () => {
    try {
      setIsUploading(true);
      const protectedFile =
        currentFileStatus === ProtectedFileStatusEnum.HAS_MODEL_NO_FILE
          ? await submitRenewProtectedFile({
              variables: {
                contentType: selectedFileType,
                fileName: selectedFileName,
                uuid: existFileUuid,
                path,
              },
            })
          : await submitMakeProtectedFile({
              variables: {
                contentType: selectedFileType,
                fileName: selectedFileName,
                path,
              },
            });
      console.log("ProtectedFile", protectedFile);

      const { adminMakeProtectedFile } = protectedFile.data;

      const { uploadUrl } = adminMakeProtectedFile;

      console.log("start uplaoding file", uploadUrl, selectedFile);

      const requestOptions = {
        method: "PUT",
        body: selectedFile,
      };

      const uploadFile = await fetch(uploadUrl, requestOptions);

      console.log("File uploaded", uploadFile);

      const confirmed = await confirmFileUploaded({
        variables: {
          uuid: adminMakeProtectedFile.uuid,
        },
      });
      if (!confirmed.data) {
        return;
      }
      onUploadFinished(confirmed.data.adminConfirmFileUploaded);

      console.log("CONFIRMED", confirmed);
    } catch (error) {
      handelStartMakingProtectedFileError(error);
    } finally {
      setIsUploaded(true);
    }
  };

  return (
    <IonModal
      isOpen={isShowModal ?? false}
      onIonModalWillPresent={() => {
        initModal();
      }}
    >
      <IonToolbar color="white">
        <IonButtons slot="start">
          <IonButton
            color="primary"
            onClick={() => dismissModal()}
            disabled={isUploading}
          >
            Close
          </IonButton>
        </IonButtons>
        <IonTitle>{title ?? "Upload File"}</IonTitle>
      </IonToolbar>
      {!isUploading && (
        <IonCard className="no-margin-card">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Select your file
              </IonLabel>
              <input
                accept={fileType}
                onChange={(e) => {
                  setSelectedFile(e.target.files![0]);
                }}
                type="file"
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
      )}
      {isUploading && <IonProgressBar type="indeterminate"></IonProgressBar>}
      <IonCard color="white" className="no-margin-card ">
        <IonCardContent>
          <IonItem lines="none" color="white">
            <IonLabel color="primary" position="stacked">
              Filename
            </IonLabel>
            <IonInput placeholder="-" value={selectedFileName} readonly />
          </IonItem>
          <IonItem lines="none" color="white">
            <IonLabel color="primary" position="stacked">
              Content Type
            </IonLabel>
            <IonInput placeholder="-" value={selectedFileType} readonly />
          </IonItem>
        </IonCardContent>
      </IonCard>
      {!isUploading && (
        <IonCard color="white" className="no-margin-card">
          <IonCardContent>
            <IonButton
              color="primary"
              expand="block"
              onClick={() => startMakingProtectedFile()}
            >
              上傳
            </IonButton>
          </IonCardContent>
        </IonCard>
      )}
    </IonModal>
  );
};

export default UploadModal;
