import { round } from "lodash";

export function getFileSizeDisplayText(
  bytes: number,
  fractionDigits?: number
): string {
  if (bytes < 1000) {
    return `${bytes}B`;
  }
  if (bytes < 1000 * 1000) {
    return `${round(bytes / 1000, fractionDigits)}KB`;
  }
  return `${round(bytes / 1000 / 1000, fractionDigits)}MB`;
}
