import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { funnelSharp } from "ionicons/icons";
import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import SortOptionsPopovers, {
  SortOptionType,
} from "../../components/SortOptions/SortOptionsPopover";
import { ADMIN_FETCH_LEADS_QUERY } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import {
  getLeadStatusColorClass,
  getLeadStatusText,
  LeadStatusEnum,
} from "../../types/LeadStatus";
import { generateLeadDetailsPagePath } from "../LeadDetails/LeadDetailsPage";

import "./LeadPage.css";

export const LEAD_PAGE_PAGE_PATH_PATTERN = "/leads";

export function generateLeadPagePath(): string {
  return generatePath(LEAD_PAGE_PAGE_PATH_PATTERN);
}

const LEADS_PER_FETCH = 10;

const LeadPage: React.FC = React.memo(() => {
  const [newAddedFilter, setNewAddedFilter] = useState(false);
  const [processingFilter, setProcessingFilter] = useState(false);
  const [rejectedFilter, setRejectedFilter] = useState(false);
  const [sortingParams, setSortingParams] = useState({
    createdAt: "desc",
  });

  const sortOptions: SortOptionType[] = [
    { name: "Created Time", value: "createdAt", default: "desc" },
    { name: "Name", value: "name" },
  ];
  const filterParam = useMemo(() => {
    const params: any = [];
    if (newAddedFilter) {
      params.push(LeadStatusEnum.newAdded);
    }

    if (processingFilter) {
      params.push(LeadStatusEnum.processing);
    }

    if (rejectedFilter) {
      params.push(LeadStatusEnum.rejected);
    }

    return params;
  }, [newAddedFilter, processingFilter, rejectedFilter]);
  const {
    data: leadsData,
    loading: loadingLeads,
    fetchMore: fetchMoreLeads,
    error: fetchLeadsError,
  } = useQuery(ADMIN_FETCH_LEADS_QUERY, {
    variables: {
      orderBy: sortingParams,
      offset: 0,
      limit: LEADS_PER_FETCH,
      status: filterParam,
    },
  });

  useErrorHandler(fetchLeadsError, {
    name: "fetchLeadsError",
  });
  const handleFetchMoreLeadsError = useImperativeErrorHandler({
    name: "fetchMoreLeads",
  });

  const leadsList = useMemo(() => {
    return leadsData?.adminFetchLeads.data;
  }, [leadsData]);
  const count = useMemo(() => leadsData?.adminFetchLeads.count, [leadsData]);
  const allLoaded = useMemo(
    () => (!!leadsList ? leadsList.length >= count : false),
    [count, leadsList]
  );

  const loadMoreData = async (ev: any) => {
    try {
      if (allLoaded) return;
      await fetchMoreLeads({
        variables: {
          offset: leadsList.length,
        },
      });
    } catch (e) {
      handleFetchMoreLeadsError(e);
    } finally {
      ev.target.complete();
    }
  };

  const noFilterEnabled = useMemo(() => {
    return !(newAddedFilter || processingFilter || rejectedFilter);
  }, [newAddedFilter, processingFilter, rejectedFilter]);

  return (
    <IonPage>
      <IonLoading isOpen={loadingLeads} />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>

          <IonButtons slot="primary">
            <IonButton
              color="primary"
              routerLink={generateLeadDetailsPagePath("new")}
            >
              增加潛在客戶
            </IonButton>
            <IonButton color="primary" id="sort-button">
              <IonIcon slot="icon-only" ios={funnelSharp} md={funnelSharp} />
            </IonButton>
            <SortOptionsPopovers
              trigger={"sort-button"}
              options={sortOptions}
              onOptionsChange={(e: any) => {
                setSortingParams(e);
              }}
            />
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>潛在客戶</h1>
            </IonCardTitle>

            <IonList className="bg-white">
              <IonChip
                color={noFilterEnabled ? "danger" : "medium"}
                onClick={() => {
                  setNewAddedFilter(false);
                  setProcessingFilter(false);
                  setRejectedFilter(false);
                }}
              >
                <IonLabel color="dark">All</IonLabel>
              </IonChip>
              <IonChip
                color={
                  newAddedFilter
                    ? getLeadStatusColorClass(LeadStatusEnum.newAdded)
                    : "medium"
                }
                onClick={() => setNewAddedFilter(!newAddedFilter)}
              >
                <IonLabel color="dark">New-add</IonLabel>
              </IonChip>
              <IonChip
                color={
                  processingFilter
                    ? getLeadStatusColorClass(LeadStatusEnum.processing)
                    : "medium"
                }
                onClick={() => setProcessingFilter(!processingFilter)}
              >
                <IonLabel color="dark">Processing</IonLabel>
              </IonChip>
              <IonChip
                color={
                  rejectedFilter
                    ? getLeadStatusColorClass(LeadStatusEnum.rejected)
                    : "medium"
                }
                onClick={() => setRejectedFilter(!rejectedFilter)}
              >
                <IonLabel color="dark">Rejected</IonLabel>
              </IonChip>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonList>
          {leadsList?.map((l: any, index: number) => {
            return (
              <IonItem
                lines="none"
                routerLink={generateLeadDetailsPagePath(l.uuid)}
                color="white"
                key={`lead-item-${index}`}
              >
                <IonLabel className="ion-text-wrap">
                  <IonText color="primary">
                    <h2>{l.name}</h2>
                  </IonText>
                  <IonText color="medium">
                    <p>{l.phone}</p>
                  </IonText>
                </IonLabel>

                <IonChip
                  color={getLeadStatusColorClass(l.status)}
                  outline={true}
                  slot="end"
                >
                  <IonLabel> {getLeadStatusText(l.status)}</IonLabel>
                </IonChip>
              </IonItem>
            );
          })}
        </IonList>

        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
          // disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default LeadPage;
