import {
  IonButton,
  IonIcon,
  IonImg,
  IonTextarea,
  TextareaChangeEventDetail,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useCallback, useEffect, useState } from "react";
import { getFileSizeDisplayText } from "../../utils/text";

import "./UploadPhotoPreviewItem.css";

interface Props {
  file: File;
  caption: string;
  onCaptionChange: (caption: string) => void;
  onDelete: (file: File) => void;
}

const UploadPhotoPreviewItem: React.FC<Props> = React.memo((props) => {
  const { file, caption, onCaptionChange, onDelete } = props;

  const [fileObjectUrl, setFileObjectUrl] = useState<string | undefined>();

  useEffect(() => {
    const url = URL.createObjectURL(file);
    setFileObjectUrl(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  const _onDelete = useCallback(() => {
    onDelete(file);
  }, [file, onDelete]);

  const _onCaptionChange = useCallback(
    (event: CustomEvent<TextareaChangeEventDetail>) => {
      onCaptionChange(event.detail.value!);
    },
    [onCaptionChange]
  );

  return (
    <div className="upload-photo-preview-item">
      <div className="upload-photo-preview-item__image-wrapper">
        {fileObjectUrl && (
          <IonImg
            className="upload-photo-preview-item__image"
            src={fileObjectUrl}
          />
        )}
      </div>
      <IonButton
        className="upload-photo-preview-item__delete-btn"
        color="light"
        size="small"
        onClick={_onDelete}
      >
        <IonIcon slot="icon-only" icon={trash} />
      </IonButton>
      <div className="upload-photo-preview-item__details">
        <span
          className="upload-photo-preview-item__details__name"
          title={file.name}
        >
          {file.name}
        </span>
        <span className="upload-photo-preview-item__details__size">
          {getFileSizeDisplayText(file.size, 2)}
        </span>
      </div>
      <IonTextarea
        className="upload-photo-preview-item__caption-input"
        placeholder="Caption"
        autoGrow={true}
        rows={1}
        value={caption}
        onIonChange={_onCaptionChange}
      />
    </div>
  );
});

export default UploadPhotoPreviewItem;
