import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CookiesUtil } from '../utils/cookies';

export interface AuthContextValue {
  authToken: string | undefined;
  isLoggedIn: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthContextProvider = React.memo((props) => {
  const { children } = props;

  const [authToken, setAuthToken] = useState<string | undefined>(undefined);

  const isLoggedIn = useMemo(() => !!authToken, [authToken]);

  const login = useCallback((token: string) => {
    setAuthToken(token);
    CookiesUtil.saveToken(token);
  }, []);

  const logout = useCallback(() => {
    setAuthToken(undefined);
    CookiesUtil.removeSavedToken();
  }, []);

  const contextValue = useMemo<AuthContextValue>(() => {
    return {
      authToken,
      isLoggedIn,
      login,
      logout,
    };
  }, [authToken, isLoggedIn, login, logout]);

  // Restore saved token from cookies
  useEffect(() => {
    const savedToken = CookiesUtil.getSavedToken();
    if (savedToken !== undefined) {
      setAuthToken(savedToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth = () => {
  const value = useContext(AuthContext);
  if (!value) {
    throw Error("useAuth should be used inside AuthContextProvider");
  }
  return value;
};
