import { TypePolicies } from "@apollo/client";
import { createFieldPolicy, createKeyArgItem } from "../utils/apollo";
import {
  AdminFetchAlbumAndAlbumPhotosVariables,
  AdminFetchAlbumAndAlbumPhotos_adminFetchAlbumPhotos,
} from "./__generated__/AdminFetchAlbumAndAlbumPhotos";
import { AdminFetchAlbumsVariables, AdminFetchAlbums_adminFetchAlbums } from "./__generated__/AdminFetchAlbums";
import {
  AdminFetchArticlesVariables,
  AdminFetchArticles_adminFetchArticles,
} from "./__generated__/AdminFetchArticles";
import {
  AdminFetchEmailsVariables,
  AdminFetchEmails_adminFetchEmails,
} from "./__generated__/AdminFetchEmails";
import {
  AdminFetchEventsVariables,
  AdminFetchEvents_adminFetchEvents,
} from "./__generated__/AdminFetchEvents";
import {
  AdminFetchIncentivesVariables,
  AdminFetchIncentives_adminFetchIncentives,
} from "./__generated__/AdminFetchIncentives";
import {
  AdminFetchLeads_adminFetchLeads,
  AdminFetchLeadsVariables,
} from "./__generated__/AdminFetchLeads";
import {
  AdminFetchMembersVariables,
  AdminFetchMembers_adminFetchMembers,
} from "./__generated__/AdminFetchMembers";
import {
  AdminFetchQuizzesVariables,
  AdminFetchQuizzes_adminFetchQuizzes,
} from "./__generated__/AdminFetchQuizzes";

interface ListView<T> {
  count: number;
  current: number;
  data: T[];
}

function mergePaginatedListView<LV extends ListView<any>>(
  existing: LV | undefined,
  incoming: LV,
  offset: number
): LV {
  const mergedData = existing ? existing.data.slice(0) : [];
  for (let i = 0; i < incoming.data.length; i++) {
    mergedData[offset + i] = incoming.data[i];
  }

  return {
    ...(existing ?? {}),
    ...incoming,
    data: mergedData,
  };
}

export const TYPE_POLICIES: TypePolicies = {
  Query: {
    fields: {
      adminFetchArticles: createFieldPolicy<
        AdminFetchArticles_adminFetchArticles,
        AdminFetchArticlesVariables,
        AdminFetchArticlesVariables
      >({
        keyArgs: [],
        merge: (existing, incoming, options) => {
          const offset = options.args?.listQuery?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),

      adminFetchEvents: createFieldPolicy<
        AdminFetchEvents_adminFetchEvents,
        AdminFetchEventsVariables,
        AdminFetchEventsVariables
      >({
        keyArgs: [],
        merge: (existing, incoming, options) => {
          const offset = options.args?.listQuery?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),

      adminFetchIncentives: createFieldPolicy<
        AdminFetchIncentives_adminFetchIncentives,
        AdminFetchIncentivesVariables,
        AdminFetchIncentivesVariables
      >({
        keyArgs: [],
        merge: (existing, incoming, options) => {
          const offset = options.args?.listQuery?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),

      adminFetchLeads: createFieldPolicy<
        AdminFetchLeads_adminFetchLeads,
        AdminFetchLeadsVariables,
        AdminFetchLeadsVariables
      >({
        keyArgs: [createKeyArgItem("status"), createKeyArgItem("orderBy")],
        merge: (existing, incoming, options) => {
          const offset = options.args?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
      adminFetchMembers: createFieldPolicy<
        AdminFetchMembers_adminFetchMembers,
        AdminFetchMembersVariables,
        AdminFetchMembersVariables
      >({
        keyArgs: [createKeyArgItem("keyword"), createKeyArgItem("orderBy")],
        merge: (existing, incoming, options) => {
          const offset = options.args?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
      adminFetchEmails: createFieldPolicy<
        AdminFetchEmails_adminFetchEmails,
        AdminFetchEmailsVariables,
        AdminFetchEmailsVariables
      >({
        keyArgs: [createKeyArgItem("status"), createKeyArgItem("orderBy")],
        merge: (existing, incoming, options) => {
          const offset = options.args?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
      adminFetchQuizzes: createFieldPolicy<
        AdminFetchQuizzes_adminFetchQuizzes,
        AdminFetchQuizzesVariables,
        AdminFetchQuizzesVariables
      >({
        keyArgs: [
          createKeyArgItem("listQuery", [
            createKeyArgItem("keyword"),
            createKeyArgItem("orderBy"),
          ]),
        ],
        merge: (existing, incoming, options) => {
          const offset = options.args?.listQuery?.offset ?? 0;
          const merged = mergePaginatedListView(existing, incoming, offset);
          return merged;
        },
      }),
      adminFetchAlbums: createFieldPolicy<
        AdminFetchAlbums_adminFetchAlbums,
        AdminFetchAlbumsVariables,
        AdminFetchAlbumsVariables
      >({
        keyArgs: [
          createKeyArgItem("listQuery", [
            createKeyArgItem("keyword"),
            createKeyArgItem("orderBy"),
          ]),
        ],
        merge: (existing, incoming, options) => {
          const offset = options.args?.listQuery?.offset ?? 0;
          const merged = mergePaginatedListView(existing, incoming, offset);
          return merged;
        },
      }),
      adminFetchAlbumPhotos: createFieldPolicy<
        AdminFetchAlbumAndAlbumPhotos_adminFetchAlbumPhotos,
        AdminFetchAlbumAndAlbumPhotosVariables,
        AdminFetchAlbumAndAlbumPhotosVariables
      >({
        keyArgs: [
          createKeyArgItem("albumUuid"),
          createKeyArgItem("listQuery", [
            createKeyArgItem("keyword"),
            createKeyArgItem("orderBy"),
          ]),
        ],
        merge: (existing, incoming, options) => {
          const offset = options.args?.listQuery?.offset ?? 0;
          const merged = mergePaginatedListView(existing, incoming, offset);
          return merged;
        },
      }),
    },
  },
};
