import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import {
  ADMIN_FETCH_CATEGORY,
  ADMIN_FETCH_CATEGORY_DETAILS,
  ADMIN_FETCH_LESSON,
  ADMIN_MAKE_OR_UPDATE_LESSON,
  ADMIN_PROTECTED_ACCESS,
  ADMIN_REMOVE_LESSON,
} from "../../graphql/queries";
import {
  AdminFetchCategoryDetails,
  AdminFetchCategoryDetailsVariables,
} from "../../graphql/__generated__/AdminFetchCategoryDetails";
import {
  AdminFetchLesson,
  AdminFetchLessonVariables,
  AdminFetchLesson_adminFetchLesson_quizzes,
} from "../../graphql/__generated__/AdminFetchLesson";
import {
  AdminMakeOrUpdateLesson,
  AdminMakeOrUpdateLessonVariables,
} from "../../graphql/__generated__/AdminMakeOrUpdateLesson";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import QuizSelectModal from "../../modals/QuizSelect/QuizSelectModal";
import UploadModal from "../../modals/Upload/UploadModal";
import { UploadModalFileType } from "../../modals/Upload/UploadModalFileType";
import { ProtectedFileStatusEnum } from "../../types/ProtectedFileStatus";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { generateLessonsPagePath } from "../Lessons/LessonsPage";

import "./LessonDetailsPage.css";

export const LESSON_DETAILS_PAGE_PATH_PATTERN =
  "/categories/:categorySlug/:slug";

export function generateLessonDetailsPagePath(
  categorySlug: string,
  slug: string
): string {
  return generatePath(LESSON_DETAILS_PAGE_PATH_PATTERN, {
    categorySlug,
    slug,
  });
}

const LessonDetailsPage: React.FC = React.memo(() => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { slug, categorySlug } = useParams<{
    slug: string;
    categorySlug: string;
  }>();

  const [title, setTitle] = useState("");
  const [present] = useIonAlert();
  const [description, setDescription] = useState("");
  const [updateProtectedFileVideo, setUpdatedProtectedFileVideo] =
    useState<any>({});
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openQuizSelectModal, setOpenQuizSelectModal] = useState(false);
  const [signedPreviewUrl, setSignedPreviewUrl] = useState("");
  const [selectedQuizzes, setSelectedQuizzes] = useState<
    AdminFetchLesson_adminFetchLesson_quizzes[]
  >([]);
  const [videoLength, setVideoLength] = useState(0);
  const [slugData, setSlugData] = useState("");
  const [selectedThumbnail, setSelectedThumbnail] = useState<any>(null);
  const [seq, setSeq] = useState(0);
  const selectedThumbnailObjectUrl = useMemo(() => {
    if (selectedThumbnail) {
      return URL.createObjectURL(selectedThumbnail);
    }
    return "";
  }, [selectedThumbnail]);

  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (slug && slug === "new" ? "CREATE" : "UPDATE"),
    [slug]
  );

  const {
    data: categoryDetailsData,
    loading: loadingCategoryDetails,
    error: categoryDetailsError,
  } = useQuery<AdminFetchCategoryDetails, AdminFetchCategoryDetailsVariables>(
    ADMIN_FETCH_CATEGORY_DETAILS,
    {
      variables: {
        slug: categorySlug,
      },
    }
  );

  const [submitMakeOrUpdateLesson, { loading: loadingMakeOrUpdateLesson }] =
    useMutation<AdminMakeOrUpdateLesson, AdminMakeOrUpdateLessonVariables>(
      ADMIN_MAKE_OR_UPDATE_LESSON
    );

  useErrorHandler(categoryDetailsError, {
    name: "fetchCategoryDetails",
  });

  const handleMakeOrUpdateLessonError = useImperativeErrorHandler({
    name: "makeOrUpdateLessonError",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "Title",
          slug: "Slug",
          seq: "Seq",
          categorySlug: "Category",
          protectedFileVideoUuid: "Video",
          description: "Introduction",
          permissionLevel: "Permission level",
          thumbnailFile: "Thumbnail",
          videoLength: "Video length",
          quizzesUuids: "Quizzes",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const {
    data: lessonData,
    loading: loadingMoreLesson,
    error: fetchLessonDetailsError,
  } = useQuery<AdminFetchLesson, AdminFetchLessonVariables>(
    ADMIN_FETCH_LESSON,
    {
      variables: {
        slug,
      },
      skip: mode === "CREATE",
    }
  );

  useErrorHandler(fetchLessonDetailsError, {
    name: "fetchLessonDetailsError",
  });

  const [resolveProtectedUrl, { loading: loadingResolveProtectedUrl }] =
    useLazyQuery(ADMIN_PROTECTED_ACCESS);

  const [removeLesson, { loading: loadingRemoveLesson }] =
    useMutation(ADMIN_REMOVE_LESSON);

  const handleFetchSignedUrlError = useImperativeErrorHandler({
    name: "fetchSignedUrl",
  });
  const handleRemoveLessonError = useImperativeErrorHandler({
    name: "removeLesson",
  });

  const lessonDataView = useMemo(() => {
    return lessonData?.adminFetchLesson;
  }, [lessonData]);

  const protectedFileVideo = useMemo(
    () => lessonDataView?.protectedFileVideo,
    [lessonDataView]
  );

  const previewVideoFileUuid = useMemo(
    () =>
      updateProtectedFileVideo.uuid || lessonDataView?.protectedFileVideo?.uuid,
    [lessonDataView?.protectedFileVideo, updateProtectedFileVideo]
  );

  useEffect(() => {
    const fetchSignedUrl = async (protectedFileUuid: string) => {
      if (!protectedFileUuid || protectedFileUuid.length === 0) return "";

      const signedUrl = await resolveProtectedUrl({
        variables: {
          uuid: protectedFileUuid,
        },
      });
      const { data } = signedUrl;
      setSignedPreviewUrl(data.adminProtectedAccess);
    };

    fetchSignedUrl(previewVideoFileUuid).catch((error) => {
      handleFetchSignedUrlError(error);
    });
  }, [previewVideoFileUuid, resolveProtectedUrl, handleFetchSignedUrlError]);

  const newVideoUploaded = useMemo(() => {
    return (
      updateProtectedFileVideo &&
      updateProtectedFileVideo.uuid &&
      updateProtectedFileVideo.isExist
    );
  }, [updateProtectedFileVideo]);

  const lessonHasVideo = useMemo(() => {
    return newVideoUploaded || protectedFileVideo?.isExist;
  }, [newVideoUploaded, protectedFileVideo]);

  const currentFileStatus = useMemo(() => {
    if (!!protectedFileVideo && !protectedFileVideo.isExist) {
      return ProtectedFileStatusEnum.HAS_MODEL_NO_FILE;
    }
    if (!!protectedFileVideo && protectedFileVideo.isExist) {
      return ProtectedFileStatusEnum.HAS_MODEL_HAS_FILE;
    }
    return ProtectedFileStatusEnum.NO_MODEL_NO_FILE;
  }, [protectedFileVideo]);

  useEffect(() => {
    if (!!lessonDataView) {
      setTitle(lessonDataView.title);
      setDescription(lessonDataView.description);
      setVideoLength(lessonDataView.videoLength);
      setSlugData(lessonDataView.slug);
      setSeq(lessonDataView.seq);
      setSelectedQuizzes(lessonDataView.quizzes);
    }
  }, [lessonDataView]);

  const saveLesson = async () => {
    console.log("Save", {
      thumbnailFile: selectedThumbnail,
    });
    try {
      await submitMakeOrUpdateLesson({
        variables: {
          isUpdate: mode === "UPDATE",
          adminInput: {
            id: lessonDataView?.id,
            title,
            slug: slugData,
            seq,
            description,
            videoLength,
            categorySlug: categorySlug,
            ...(newVideoUploaded
              ? {
                  protectedFileVideoUuid: updateProtectedFileVideo.uuid,
                }
              : {}),
            ...(selectedThumbnail
              ? {
                  thumbnailFile: selectedThumbnail,
                }
              : {}),
            ...(selectedQuizzes
              ? {
                  quizzesUuids: selectedQuizzes.map((q) => q.uuid),
                }
              : {}),
          },
        },
      });

      if (mode === "CREATE") {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_CATEGORY);
      }
      history.replace(generateLessonsPagePath(categorySlug));
    } catch (e) {
      handleMakeOrUpdateLessonError(e);
    }
  };

  const deleteLesson = () => {
    if (!lessonDataView) {
      return;
    }
    present({
      header: "Delete Lesson",
      message: "Are you sure?<br> This action cannot be undone.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeLesson({
                variables: {
                  slug: lessonDataView.slug,
                },
              });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_CATEGORY);
              history.replace(generateLessonsPagePath(categorySlug));
            } catch (error) {
              handleRemoveLessonError(error);
            }
          },
        },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
  };

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingCategoryDetails ||
          loadingMoreLesson ||
          loadingResolveProtectedUrl ||
          loadingMakeOrUpdateLesson ||
          loadingRemoveLesson
        }
      />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={generateLessonsPagePath(categorySlug)}
            />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={() => saveLesson()}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonCard className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary">Video *</IonLabel>
            </IonItem>
            {lessonHasVideo && signedPreviewUrl && (
              <video
                key={signedPreviewUrl}
                className="lesson_details_paqe_preview_video_player"
                //  className={styles.video}
                controls={true}
                //   poster={lesson.thumbnail.url}
              >
                <source src={signedPreviewUrl} />
              </video>
            )}

            {!lessonHasVideo ? (
              <IonButton
                expand="block"
                color="primary"
                onClick={() => setOpenUploadModal(true)}
              >
                上傳影片
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                color="success"
                onClick={() => setOpenUploadModal(true)}
                disabled={newVideoUploaded}
              >
                {newVideoUploaded ? "File Upload Successfully" : "Alter Video"}
              </IonButton>
            )}
          </IonCardContent>
        </IonCard>

        <IonCard className="no-margin-card">
          <IonCardContent>
            <IonItem lines="none" color="white">
              {selectedThumbnail ? (
                <>
                  <IonLabel color="success" position="stacked">
                    Updated video thumbnail
                  </IonLabel>
                  <img alt="thumbnail" src={`${selectedThumbnailObjectUrl}`} />
                </>
              ) : (
                lessonDataView?.thumbnail?.url && (
                  <img
                    alt="thumbnail"
                    src={`${lessonDataView?.thumbnail?.url}`}
                  />
                )
              )}

              <IonLabel color="primary" position="stacked">
                Select your thumbnail *
              </IonLabel>
              <input
                accept="image/*"
                onChange={(e) => {
                  setSelectedThumbnail(e.target.files![0]);
                }}
                type="file"
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <IonCard color="white" className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white" disabled={true}>
              <IonLabel color="primary" position="stacked">
                Category
              </IonLabel>
              <IonInput
                value={categoryDetailsData?.adminFetchCategory?.title}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Title *
              </IonLabel>
              <IonInput
                placeholder="Enter title"
                value={title}
                onIonChange={(e) => {
                  setTitle(e.detail.value ?? "");
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Slug *
              </IonLabel>
              <IonInput
                placeholder="Enter slug"
                value={slugData}
                onIonChange={(e) => {
                  setSlugData(e.detail.value ?? "");
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Sequence *
              </IonLabel>
              <IonInput
                placeholder="Enter sequence"
                value={seq}
                type="number"
                onIonChange={(e) => {
                  setSeq(
                    !!e.detail.value ? parseFloat(e.detail.value ?? "0") : 0
                  );
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Introduction *
              </IonLabel>
              <IonInput
                placeholder="Enter introduction"
                value={description}
                onIonChange={(e) => {
                  setDescription(e.detail.value ?? "");
                }}
              />
            </IonItem>

            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Video Length (second) *
              </IonLabel>
              <IonInput
                placeholder="Enter video length"
                value={videoLength}
                onIonChange={(e) => {
                  setVideoLength(parseInt(e.detail.value!) ?? 0);
                }}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <UploadModal
          isShowModal={openUploadModal}
          onUploadFinished={(file) => {
            console.log("Upload Success", file);
            setUpdatedProtectedFileVideo(file);
            setOpenUploadModal(false);
          }}
          fileType={UploadModalFileType.video}
          existFileUuid={protectedFileVideo?.uuid}
          currentFileStatus={currentFileStatus}
          dismissModal={() => setOpenUploadModal(false)}
          path={"lesson-video"}
        />

        <QuizSelectModal
          dismissModal={() => setOpenQuizSelectModal(false)}
          onQuizUpdated={(selectedQuizzes) => {
            console.log("Close", selectedQuizzes);

            setSelectedQuizzes(selectedQuizzes);
          }}
          isShowModal={openQuizSelectModal}
          selectedQuiz={lessonDataView?.quizzes ?? []}
        />

        <IonCard className="no-margin-card">
          <IonCardContent>
            {selectedQuizzes.map((q) => (
              <IonItem lines="none" color="white" key={`quiz-${q.uuid}`}>
                <IonLabel className="ion-text-wrap">
                  <IonText color="dark">
                    <h2>{q.title}</h2>
                  </IonText>
                </IonLabel>
              </IonItem>
            ))}

            <IonButton
              expand="block"
              color="primary"
              onClick={() => setOpenQuizSelectModal(true)}
            >
              連結測驗
            </IonButton>
          </IonCardContent>
        </IonCard>

        {mode === "UPDATE" && (
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonButton
                expand="block"
                color="danger"
                onClick={() => deleteLesson()}
              >
                Delete Lesson
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
});

export default LessonDetailsPage;
