import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { generatePath, useHistory, useParams } from "react-router";
import {
  ADMIN_FETCH_ARTICLE,
  ADMIN_FETCH_ARTICLES,
  ADMIN_MAKE_OR_UPDATE_ARTICLE,
  ADMIN_REMOVE_ARTICLE,
} from "../../graphql/queries";
import {
  AdminFetchArticle,
  AdminFetchArticleVariables,
} from "../../graphql/__generated__/AdminFetchArticle";
import {
  AdminMakeOrUpdateArticle,
  AdminMakeOrUpdateArticleVariables,
} from "../../graphql/__generated__/AdminMakeOrUpdateArticle";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import {
  ArticleStatus,
  getArticleStatus18nText,
  getArticleStatusColor,
} from "../../types/ArticleStatus";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./ArticleDetailsPage.css";
import { evictQueryCache } from "../../utils/apollo";
import { generateArticlesPagePath } from "../Articles/ArticlesPage";

export const ARTICLE_DETAILS_PAGE_PATH_PATTERN = "/articles/:uuid";

export function generateArticleDetailsPagePath(uuid: string): string {
  return generatePath(ARTICLE_DETAILS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const ArticleDetailsPage: React.FC = React.memo(() => {
  const history = useHistory();
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const apolloClient = useApolloClient();

  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );
  const [present] = useIonAlert();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [pinned, setPinned] = useState(false);
  const [articleContent, setArticleContent] = useState(
    EditorState.createEmpty()
  );

  const [
    submitUpdateOrAddArticle,
    { loading: loadingSubmitUpdateOrAddArticle },
  ] = useMutation<AdminMakeOrUpdateArticle, AdminMakeOrUpdateArticleVariables>(
    ADMIN_MAKE_OR_UPDATE_ARTICLE
  );

  const handleUpdateArticleError = useImperativeErrorHandler({
    name: "updateArticleError",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          author: "作者",
          title: "標題",
          content: "內容",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const [removeArticle, { loading: loadingRemoveArticle }] =
    useMutation(ADMIN_REMOVE_ARTICLE);

  const handleRemoveArticleError = useImperativeErrorHandler({
    name: "removeArticleError",
  });

  const getHtmlContent = useCallback(() => {
    const contentState = articleContent.getCurrentContent();
    if (!contentState.hasText()) {
      return "";
    }
    const rawContent = convertToRaw(contentState);
    return draftToHtml(rawContent);
  }, [articleContent]);

  const {
    data: articleData,
    loading: loadingArticleData,
    error: fetchArticleError,
  } = useQuery<AdminFetchArticle, AdminFetchArticleVariables>(
    ADMIN_FETCH_ARTICLE,
    {
      variables: {
        uuid,
      },
      skip: mode === "CREATE",
    }
  );

  useErrorHandler(fetchArticleError, {
    name: "fetchArticleError",
  });

  const articleDataView = useMemo(() => {
    return articleData?.adminFetchArticle;
  }, [articleData?.adminFetchArticle]);

  useEffect(() => {
    if (!!articleDataView) {
      setTitle(articleDataView.title);
      setAuthor(articleDataView.author);
      setPinned(articleDataView.pinned);

      const blocksFromHtml = htmlToDraft(articleDataView.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      setArticleContent(editorState);
    }
  }, [articleDataView]);

  const saveArticle = useCallback(
    async (publishAfterSave: boolean = false) => {
      try {
        await submitUpdateOrAddArticle({
          variables: {
            publishAfterSave: publishAfterSave,
            adminInput: {
              content: getHtmlContent(),
              title,
              author,
              pinned,
              ...(mode === "CREATE"
                ? {
                    status: "draft",
                  }
                : {}),

              ...(mode === "CREATE"
                ? {}
                : {
                    uuid,
                  }),
            },
          },
        });
        if (mode === "CREATE") {
          evictQueryCache(apolloClient.cache, ADMIN_FETCH_ARTICLES);
        }
        history.replace(generateArticlesPagePath());
      } catch (e) {
        handleUpdateArticleError(e);
      }
    },
    [
      apolloClient.cache,
      author,
      getHtmlContent,
      handleUpdateArticleError,
      history,
      mode,
      pinned,
      submitUpdateOrAddArticle,
      title,
      uuid,
    ]
  );

  const saveToDraft = useCallback(
    async (publishAfterSave: boolean = false) => {
      try {
        await submitUpdateOrAddArticle({
          variables: {
            publishAfterSave: publishAfterSave,
            adminInput: {
              content: getHtmlContent(),
              title,
              author,
              status: "draft",
              uuid,
            },
          },
        });
        if (mode === "CREATE") {
          evictQueryCache(apolloClient.cache, ADMIN_FETCH_ARTICLES);
        }
        history.replace(generateArticlesPagePath());
      } catch (e) {
        alert("Sent article error" + e);
        handleUpdateArticleError(e);
      }
    },
    [
      submitUpdateOrAddArticle,
      getHtmlContent,
      title,
      author,
      uuid,
      mode,
      history,
      apolloClient.cache,
      handleUpdateArticleError,
    ]
  );

  const deleteArticle = useCallback(() => {
    if (!articleDataView) {
      return;
    }
    present({
      header: "Delete article",
      message: "Are you sure?<br> This action cannot be undo.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeArticle({
                variables: {
                  uuid: articleDataView.uuid,
                },
              });
              evictQueryCache(apolloClient.cache, ADMIN_FETCH_ARTICLES);
              history.replace(generateArticlesPagePath());
            } catch (e) {
              handleRemoveArticleError(e);
            }
          },
        },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
  }, [
    apolloClient.cache,
    articleDataView,
    handleRemoveArticleError,
    history,
    present,
    removeArticle,
  ]);

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingArticleData ||
          loadingSubmitUpdateOrAddArticle ||
          loadingRemoveArticle
        }
      />
      <IonHeader></IonHeader>
      <IonContent>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonBackButton
                color="primary"
                defaultHref={generateArticlesPagePath()}
              />
            </IonButtons>

            <IonTitle>最新消息</IonTitle>
            <IonButtons slot="primary">
              {articleDataView?.status === "published" && mode === "UPDATE" && (
                <IonButton color="danger" onClick={() => saveToDraft()}>
                  儲存為草稿
                </IonButton>
              )}

              <IonButton color="primary" onClick={() => saveArticle()}>
                儲存
              </IonButton>

              {(articleDataView?.status === "draft" || mode === "CREATE") && (
                <IonButton color="primary" onClick={() => saveArticle(true)}>
                  儲存並發佈
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>

          <IonCard className="no-margin-card ">
            <IonCardContent>
              {mode === "UPDATE" && (
                <IonItem lines="none" color="white">
                  <IonLabel color="primary" position="stacked">
                    狀態
                  </IonLabel>
                  <IonInput
                    value={getArticleStatus18nText(
                      articleDataView?.status as ArticleStatus | undefined
                    )}
                    readonly
                    color={getArticleStatusColor(
                      articleDataView?.status as ArticleStatus | undefined
                    )}
                  />
                </IonItem>
              )}

              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  作者 *
                </IonLabel>
                <IonInput
                  value={author}
                  placeholder="輸入作者名稱"
                  onIonChange={(e) => {
                    setAuthor(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  標題 *
                </IonLabel>
                <IonInput
                  placeholder="輸入標題"
                  value={title}
                  onIonChange={(e) => setTitle(e.detail.value ?? "")}
                />
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  置頂
                </IonLabel>
                <IonToggle
                  checked={pinned}
                  onIonChange={(e) => setPinned(e.detail.checked)}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>

          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  內容 *
                </IonLabel>
                <Editor
                  editorState={articleContent}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => {
                    setArticleContent(e);
                  }}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>

          {mode === "UPDATE" && (
            <IonCard className="no-margin-card">
              <IonCardContent>
                <IonButton
                  expand="block"
                  color="danger"
                  onClick={() => deleteArticle()}
                >
                  Delete Article
                </IonButton>
              </IonCardContent>
            </IonCard>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default ArticleDetailsPage;
