import {
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  IonText,
  IonToggle,
} from "@ionic/react";
import { useMemo, useState } from "react";
import { useEffectSkipFirst } from "../../hooks/useEffectSkipFirst";

export type SortOptionType = {
  name: string;
  value: string;
  default?: "desc" | "asc";
};

const SortOptionsPopovers: React.FC<{
  isOpen?: boolean;
  options: SortOptionType[];
  trigger?: string;
  onOptionsChange?: any;
}> = ({ isOpen, trigger, options, onOptionsChange }) => {
  const defaultOption = options.find((o: any) => !!o.default) ?? {
    value: "createdAt",
    default: "desc",
  };
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">(
    defaultOption.default ?? "desc"
  );

  const [selectedSortFields, setSelectedSortFields] = useState<string[]>([
    defaultOption.value,
  ]);

  const selectedSortOptions = useMemo(() => {
    let sortOptions: { [key: string]: string } = {};
    selectedSortFields.map((f) => {
      sortOptions[f] = sortDirection;
      return f;
    });
    return sortOptions;
  }, [selectedSortFields, sortDirection]);

  useEffectSkipFirst(() => {
    onOptionsChange(selectedSortOptions);
  }, [onOptionsChange, selectedSortOptions]);

  return (
    <IonPopover isOpen={isOpen} trigger={trigger} arrow={true}>
      <IonContent>
        <IonList>
          <IonListHeader>
            <IonLabel>Sort Options</IonLabel>
          </IonListHeader>
          {options && options.length > 0 ? (
            options.map((o, index) => {
              return (
                <IonItem key={`sort-options-${index}`}>
                  <IonLabel>{o.name}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    color="primary"
                    value={selectedSortFields.indexOf(o.value) !== -1}
                    checked={selectedSortFields.indexOf(o.value) !== -1}
                    onIonChange={(e) => {
                      const checked = e.detail.checked;

                      if (!checked) {
                        return;
                      }
                      const newSortOption = checked
                        ? [o.value]
                        : selectedSortFields.filter(
                            (f: any) => f.value !== o.value
                          );
                      setSelectedSortFields(newSortOption);
                    }}
                  />
                </IonItem>
              );
            })
          ) : (
            <IonText>No sort options Available</IonText>
          )}
          <IonItem>
            <IonLabel>{sortDirection.toLocaleUpperCase()}</IonLabel>
            <IonToggle
              color="primary"
              checked={sortDirection === "desc"}
              onClick={() => {
                setSortDirection(sortDirection === "asc" ? "desc" : "asc");
              }}
            />
          </IonItem>
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default SortOptionsPopovers;
