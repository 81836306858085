import Cookies from 'universal-cookie';

const COOKIE_KEY_AUTH_TOKEN = "authToken";

const cookies = new Cookies();
(window as any)["cookies"] = cookies;

export namespace CookiesUtil {
  export function getSavedToken(): string | undefined {
    return cookies.get(COOKIE_KEY_AUTH_TOKEN);
  }

  export function saveToken(token: string): void {
    cookies.set(COOKIE_KEY_AUTH_TOKEN, token, {
      path: "/",
    });
  }

  export function removeSavedToken(): void {
    cookies.remove(COOKIE_KEY_AUTH_TOKEN);
  }
}
