import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { addCircleSharp } from "ionicons/icons";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import {
  ADMIN_FETCH_QUIZ,
  ADMIN_FETCH_QUIZZIES,
  ADMIN_MAKE_OR_UPDATE_QUIZ,
  ADMIN_REMOVE_QUIZ,
} from "../../graphql/queries";
import {
  AdminFetchQuiz,
  AdminFetchQuizVariables,
} from "../../graphql/__generated__/AdminFetchQuiz";
import {
  AdminMakeOrUpdateQuiz,
  AdminMakeOrUpdateQuizVariables,
} from "../../graphql/__generated__/AdminMakeOrUpdateQuiz";
import {
  AdminRemoveQuiz,
  AdminRemoveQuizVariables,
} from "../../graphql/__generated__/AdminRemoveQuiz";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { updateListItem } from "../../utils/list";
import { generateQuizzesPagePath } from "../Quizzes/QuizzesPage";

import "./QuizDetailsPage.css";

export const QUIZ_DETAILS_PAGE_PAGE_PATH_PATTERN = "/quizzes/:uuid";

export function generateQuizDetailsPagePath(uuid: string): string {
  return generatePath(QUIZ_DETAILS_PAGE_PAGE_PATH_PATTERN, {
    uuid,
  });
}

interface QuizQuestion {
  content: string;
  answerChoices: string[];
  correctAnswerValue: string;
}

interface QuizEditData {
  title: string;
  questions: QuizQuestion[];
}

const QuizDetailsPage: React.FC = React.memo(() => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const [presentAlert] = useIonAlert();

  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );

  const [quizEditData, setQuizEditData] = useState<QuizEditData>({
    title: "",
    questions: [],
  });

  const {
    data: quizData,
    loading: loadingMoreQuiz,
    error: fetchQuizError,
  } = useQuery<AdminFetchQuiz, AdminFetchQuizVariables>(ADMIN_FETCH_QUIZ, {
    variables: {
      uuid,
    },
    skip: mode === "CREATE",
  });

  const [submitMakeOrUpdateQuiz, { loading: loadingMakeOrUpdateQuiz }] =
    useMutation<AdminMakeOrUpdateQuiz, AdminMakeOrUpdateQuizVariables>(
      ADMIN_MAKE_OR_UPDATE_QUIZ
    );

  const [removeQuiz, { loading: loadingRemoveQuiz }] = useMutation<
    AdminRemoveQuiz,
    AdminRemoveQuizVariables
  >(ADMIN_REMOVE_QUIZ);

  useErrorHandler(fetchQuizError, {
    name: "quizData",
  });
  const handleSubmitQuizError = useImperativeErrorHandler({
    name: "submitQuiz",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "測驗標題",
          quizQuestions: "題目",
          "quizQuestions.[].content": (idx) => `題目${idx[0] + 1} 標題`,
          "quizQuestions.[].correctAnswerValue": (idx) =>
            `題目${idx[0] + 1} 答案`,
          "quizQuestions.[].answerChoices": (idx) => `題目${idx[0] + 1} 選擇`,
          "quizQuestions.[].answerChoices.[]": (idx) =>
            `題目${idx[0] + 1} 選擇${idx[1] + 1}`,
        })
      ) {
        return true;
      }
      return false;
    },
  });
  const handleRemoveQuizError = useImperativeErrorHandler({
    name: "removeQuiz",
  });

  const quizView = useMemo(() => {
    return quizData?.adminFetchQuiz;
  }, [quizData]);

  useEffect(() => {
    if (!!quizView) {
      setQuizEditData({
        title: quizView.title,
        questions: quizView.questions.map((q) => ({
          content: q.content,
          answerChoices: q.answerChoices,
          correctAnswerValue: q.correctAnswerValue,
        })),
      });
    }
  }, [quizView]);

  const updateQuizState = (state: QuizEditData) => {
    setQuizEditData(state);
  };

  const removeQuestionChoice = ({
    questionIndex,
    choiceIndex,
  }: {
    questionIndex: number;
    choiceIndex: number;
  }) => {
    setQuizEditData((prev) => ({
      ...prev,
      questions: updateListItem(prev.questions, questionIndex, (prevQ) => {
        // Remove given choice item
        const answerChoices = [
          ...prevQ.answerChoices.slice(0, choiceIndex),
          ...prevQ.answerChoices.slice(choiceIndex + 1),
        ];

        // Clear correct answer if the corresponding choice is removed
        const shouldClearCorrectAnswer =
          prevQ.answerChoices[choiceIndex] === prevQ.correctAnswerValue &&
          !prevQ.answerChoices.some(
            (choice, index) =>
              choice === prevQ.correctAnswerValue && index !== choiceIndex
          );
        const correctAnswerValue = shouldClearCorrectAnswer
          ? ""
          : prevQ.correctAnswerValue;

        return {
          ...prevQ,
          answerChoices,
          correctAnswerValue,
        };
      }),
    }));
  };

  const addQuestionChoice = ({ questionIndex }: { questionIndex: number }) => {
    const _quizData = cloneDeep(quizEditData);
    _quizData.questions[questionIndex].answerChoices.push("");
    console.log("_Q");
    setQuizEditData(_quizData);
  };

  const addQuestion = () => {
    const _quizData = cloneDeep(quizEditData);
    _quizData.questions.push({
      content: "",
      answerChoices: [""],
      correctAnswerValue: "",
    });
    setQuizEditData(_quizData);
  };

  const removeQuestion = useCallback(
    ({ questionIndex }: { questionIndex: number }) => {
      setQuizEditData((prev) => ({
        ...prev,
        questions: [
          ...prev.questions.slice(0, questionIndex),
          ...prev.questions.slice(questionIndex + 1),
        ],
      }));
    },
    []
  );

  const deleteQuiz = async () => {
    presentAlert({
      header: "Delete quiz",
      message: "Are you sure?<br> This action cannot be undone.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeQuiz({ variables: { uuid } });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_QUIZZIES);
              history.replace(generateQuizzesPagePath());
            } catch (e) {
              handleRemoveQuizError(e);
            }
          },
        },
      ],
      onDidDismiss: () => console.log("did dismiss"),
    });
  };

  const submitQuiz = async () => {
    try {
      await submitMakeOrUpdateQuiz({
        variables: {
          adminInput: {
            title: quizEditData.title,
            uuid: mode === "CREATE" ? "" : uuid,
            quizQuestions: quizEditData.questions.map((q, index) => ({
              ...q,
              seq: index,
            })),
          },
        },
      });

      if (mode === "CREATE") {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_QUIZZIES);
      }
      history.replace(generateQuizzesPagePath());
    } catch (error) {
      handleSubmitQuizError(error);
    }
  };

  return (
    <IonPage>
      <IonLoading
        isOpen={loadingMoreQuiz || loadingMakeOrUpdateQuiz || loadingRemoveQuiz}
      />
      <IonContent>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={generateQuizzesPagePath()}
            />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton
              color="primary"
              onClick={() => {
                submitQuiz();
              }}
            >
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonCard className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                測驗標題 *
              </IonLabel>
              <IonInput
                placeholder="輸入標題"
                value={quizEditData?.title}
                onIonChange={(e) => {
                  const _quizData = cloneDeep(quizEditData);

                  _quizData.title = e.detail.value!;
                  setQuizEditData(_quizData);
                }}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <IonList>
          {quizEditData?.questions?.map((q, questionIndex) => {
            return (
              <IonCard
                className="no-margin-card"
                key={`question-${questionIndex}`}
              >
                <IonCardContent>
                  <IonItem lines="none" color="white">
                    <IonLabel color="primary" position="stacked">
                      題目 *
                    </IonLabel>
                    <IonInput
                      placeholder="輸入題目"
                      value={q.content}
                      onIonChange={(e) => {
                        const _quizData = cloneDeep(quizEditData);
                        _quizData.questions[questionIndex].content =
                          e.detail.value!;
                        updateQuizState(_quizData);
                      }}
                    />
                  </IonItem>
                  {q.answerChoices.map((c, choiceIndex) => {
                    return (
                      <IonItem
                        lines="none"
                        color="white"
                        key={`answerChoice-${choiceIndex}`}
                      >
                        <IonLabel color="primary">
                          選擇 {choiceIndex + 1}
                        </IonLabel>

                        <IonInput
                          value={c}
                          placeholder="輸入答案選擇"
                          onIonChange={(e) => {
                            const _quizData = cloneDeep(quizEditData);
                            _quizData.questions[questionIndex].answerChoices[
                              choiceIndex
                            ] = e.detail.value!;
                            updateQuizState(_quizData);
                          }}
                        />
                        <IonButton
                          fill="outline"
                          color="danger"
                          slot="end"
                          onClick={() => {
                            removeQuestionChoice({
                              questionIndex,
                              choiceIndex,
                            });
                          }}
                        >
                          刪除
                        </IonButton>
                      </IonItem>
                    );
                  })}

                  <IonButton
                    expand="block"
                    fill="outline"
                    size="small"
                    className="quiz-details-add-answer-choices-btn"
                    onClick={() => {
                      addQuestionChoice({ questionIndex });
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      ios={addCircleSharp}
                      md={addCircleSharp}
                    />
                  </IonButton>

                  <IonItem lines="none" color="white">
                    <IonLabel color="primary">答案 *</IonLabel>
                    <IonSelect
                      value={
                        quizEditData.questions[questionIndex].correctAnswerValue
                      }
                      okText="Okay"
                      cancelText="Dismiss"
                      onIonChange={(e) => {
                        const _quizData = cloneDeep(quizEditData);
                        _quizData.questions[questionIndex].correctAnswerValue =
                          e.detail.value;
                        updateQuizState(_quizData);
                      }}
                    >
                      {q.answerChoices.map((c, answerIndex) => {
                        return (
                          c && (
                            <IonSelectOption
                              value={c}
                              key={`answer-${answerIndex}`}
                            >
                              {c}
                            </IonSelectOption>
                          )
                        );
                      })}
                    </IonSelect>
                  </IonItem>

                  <IonButton
                    color="danger"
                    expand="block"
                    fill="solid"
                    size="small"
                    onClick={() => {
                      removeQuestion({
                        questionIndex,
                      });
                    }}
                  >
                    刪除題目
                  </IonButton>
                </IonCardContent>
              </IonCard>
            );
          })}
        </IonList>

        <IonCard color="white" className="no-margin-card ">
          <IonCardContent>
            <IonButton
              expand="block"
              fill="outline"
              size="small"
              className="quiz-details-add-answer-choices-btn"
              onClick={() => addQuestion()}
            >
              新增題目
            </IonButton>
          </IonCardContent>
        </IonCard>
        {mode !== "CREATE" && (
          <IonCard color="white" className="no-margin-card ">
            <IonCardContent>
              <IonButton
                color="danger"
                expand="block"
                fill="outline"
                size="small"
                className="quiz-details-add-answer-choices-btn"
                onClick={() => deleteQuiz()}
              >
                刪除測驗
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
});

export default QuizDetailsPage;
