export type YupErrorFieldNameConfig = string | ((indexItemValues: number[]) => string);

export type YupErrorFieldNameConfigMapping = Record<string, YupErrorFieldNameConfig>;

export class LocalValidationError extends Error {
  name = "LocalValidationError";

  constructor(errorMessages: string[]) {
    super(errorMessages.join("\n"));
  }
}
