import { GraphQLErrors } from "@apollo/client/errors";
import { StrapiGraphQLErrorExtensions } from "../models/strapi-gql";

export function isStrapiGraphQLErrorExtensions(
  value: any
): value is StrapiGraphQLErrorExtensions {
  return (
    !!value &&
    typeof value === "object" &&
    "code" in value &&
    "error" in value &&
    typeof value["code"] === "string" &&
    typeof value["error"] === "object" &&
    "name" in value["error"] &&
    "message" in value["error"] &&
    "details" in value["error"] &&
    typeof value["error"]["name"] === "string" &&
    typeof value["error"]["message"] === "string" &&
    typeof value["error"]["details"] === "object"
  );
}

export function findStrapiGraphQLErrorExtensionsByName(
  graphQLErrors: GraphQLErrors,
  strapiErrorName: string
): StrapiGraphQLErrorExtensions | undefined {
  for (const gqlError of graphQLErrors) {
    if (
      isStrapiGraphQLErrorExtensions(gqlError.extensions) &&
      gqlError.extensions.error.name === strapiErrorName
    ) {
      return gqlError.extensions;
    }
  }
  return undefined;
}
