import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { ADMIN_FETCH_QUIZZIES } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateQuizDetailsPagePath } from "../QuizDetails/QuizDetailsPage";

import "./QuizzesPage.css";

export const QUIZZES_PAGE_PAGE_PATH_PATTERN = "/quizzes";

export function generateQuizzesPagePath(): string {
  return generatePath(QUIZZES_PAGE_PAGE_PATH_PATTERN);
}

const QUIZZES_PER_FETCH = 20;

const QuizzesPage: React.FC = React.memo(() => {
  const [searchKeyword, setSearchKeyword] = useState("");

  const {
    data: quizzesData,
    loading: loadingMoreQuizzes,
    error: fetchQuizzesError,
    fetchMore: fetchMoreQuizzes,
  } = useQuery(ADMIN_FETCH_QUIZZIES, {
    variables: {
      listQuery: {
        keyword: searchKeyword,
        offset: 0,
        limit: QUIZZES_PER_FETCH,
      },
    },
  });

  useErrorHandler(fetchQuizzesError, {
    name: "quizzesData",
  });
  const handleFetchMoreQuizzesError = useImperativeErrorHandler({
    name: "fetchMoreQuizzes",
  });

  const quizzesList = useMemo(() => {
    return quizzesData?.adminFetchQuizzes.data;
  }, [quizzesData]);

  const count = useMemo(
    () => quizzesData?.adminFetchQuizzes.count,
    [quizzesData]
  );
  const allLoaded = useMemo(
    () => (!!quizzesList ? quizzesList.length >= count : false),
    [count, quizzesList]
  );
  const loadMoreData = async (ev: any) => {
    console.log("Load more");
    try {
      if (allLoaded) return;
      await fetchMoreQuizzes({
        variables: {
          listQuery: {
            keyword: searchKeyword,
            offset: quizzesList.length,
            limit: QUIZZES_PER_FETCH,
          },
        },
      });
    } catch (e) {
      handleFetchMoreQuizzesError(e);
    } finally {
      ev.target.complete();
    }
  };

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreQuizzes} />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonMenuButton color="primary" />
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                routerLink={generateQuizDetailsPagePath("new")}
              >
                新增測驗
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>測驗</h1>
              </IonCardTitle>

              <IonSearchbar
                placeholder="搜尋"
                onIonChange={(e) => {
                  setSearchKeyword(e.detail.value ?? "");
                }}
              ></IonSearchbar>
            </IonCardContent>
          </IonCard>

          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              {quizzesList?.map((m: any) => {
                return (
                  <IonItem
                    detail
                    lines="none"
                    color="white"
                    key={`quiz-${m.uuid}`}
                    routerLink={generateQuizDetailsPagePath(m.uuid)}
                  >
                    <IonLabel className="ion-text-wrap">
                      <IonText color="dark">
                        <h2>{m.title}</h2>
                      </IonText>
                      <IonText color="medium">
                        <p>{m.lessons[0]?.title}</p>
                      </IonText>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </IonCardContent>
          </IonCard>
        </IonList>
        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
          // disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default QuizzesPage;
