import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonTextarea,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import DateTimeInput from "../../components/Input/DateTimeInput";
import {
  ADMIN_FETCH_EVENT,
  ADMIN_FETCH_EVENTS,
  ADMIN_MAKE_OR_UPDATE_EVENT,
  ADMIN_REMOVE_EVENT,
} from "../../graphql/queries";
import {
  AdminMakeOrUpdateEvent,
  AdminMakeOrUpdateEventVariables,
} from "../../graphql/__generated__/AdminMakeOrUpdateEvent";
import {
  AdminRemoveEvent,
  AdminRemoveEventVariables,
} from "../../graphql/__generated__/AdminRemoveEvent";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { generateEventsPagePath } from "../Events/EventsPage";

import "./EventDetailsPage.css";

export const EVENT_DETAILS_PAGE_PATH_PATTERN = "/events/:uuid";

export function generateEventDetailsPagePath(uuid: string): string {
  return generatePath(EVENT_DETAILS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const EventDetailsPage: React.FC = React.memo(() => {
  const [presentAlert] = useIonAlert();
  const apolloClient = useApolloClient();

  const [startTime, setStartTime] = useState<moment.Moment | undefined>();
  const [endTime, setEndTime] = useState<moment.Moment | undefined>();

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [remarks, setRemarks] = useState("");

  const history = useHistory();

  const { uuid } = useParams<{
    uuid: string;
  }>();
  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );

  const [submitUpdateOrAddEvent, { loading: loadingSubmitUpdateOrAddEvent }] =
    useMutation<AdminMakeOrUpdateEvent, AdminMakeOrUpdateEventVariables>(
      ADMIN_MAKE_OR_UPDATE_EVENT
    );

  const [removeEvent, { loading: loadingRemoveEvent }] = useMutation<
    AdminRemoveEvent,
    AdminRemoveEventVariables
  >(ADMIN_REMOVE_EVENT);

  const handleUpdateEventError = useImperativeErrorHandler({
    name: "updateEventError",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "活動標題",
          startTime: "開始時間",
          endTime: "完結時間",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const handleRemoveEventError = useImperativeErrorHandler({
    name: "removeEvent",
  });

  const {
    data: eventData,
    loading: loadingEventData,
    error: fetchEventError,
  } = useQuery(ADMIN_FETCH_EVENT, {
    variables: {
      uuid,
    },
    skip: mode === "CREATE",
  });
  useErrorHandler(fetchEventError, {
    name: "eventData",
  });

  const eventDataView = useMemo(() => {
    return eventData?.adminFetchEvent;
  }, [eventData?.adminFetchEvent]);

  const saveEvent = async () => {
    try {
      await submitUpdateOrAddEvent({
        variables: {
          adminInput: {
            ...(mode === "UPDATE" ? { uuid } : {}),
            title,
            location,
            remarks,
            startTime: startTime?.toISOString(true) ?? "",
            endTime: endTime?.toISOString(true) ?? "",
          },
        },
      });

      if (mode === "CREATE") {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_EVENTS);
      }
      history.replace(generateEventsPagePath());
    } catch (e) {
      handleUpdateEventError(e);
    }
  };

  const onDelete = useCallback(() => {
    presentAlert({
      header: "Delete Event",
      message: "Are you sure?<br> This action cannot be undone.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeEvent({
                variables: {
                  uuid: uuid,
                },
              });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_EVENTS);
              history.replace(generateEventsPagePath());
            } catch (error) {
              handleRemoveEventError(error);
            }
          },
        },
      ],
    });
  }, [
    apolloClient.cache,
    handleRemoveEventError,
    history,
    presentAlert,
    removeEvent,
    uuid,
  ]);

  useEffect(() => {
    if (!!eventDataView) {
      setTitle(eventDataView.title);
      setLocation(eventDataView.location);
      setRemarks(eventDataView.remarks);
      setStartTime(moment(eventDataView.startTime));
      console.log("EVENT", eventDataView.startTime);
      setEndTime(moment(eventDataView.endTime));
    }
  }, [eventDataView]);

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingEventData ||
          loadingSubmitUpdateOrAddEvent ||
          loadingRemoveEvent
        }
      />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={generateEventsPagePath()}
            />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={() => saveEvent()}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>活動詳細</h1>
            </IonCardTitle>
          </IonCardContent>
        </IonCard>

        <IonCard className="lead-details-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                活動標題 *
              </IonLabel>
              <IonInput
                placeholder="輸入活動標題"
                value={title}
                onIonChange={(e) => setTitle(e.detail.value ?? "")}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                地點
              </IonLabel>
              <IonInput
                placeholder="輸入地點"
                value={location}
                onIonChange={(e) => setLocation(e.detail.value ?? "")}
              />
            </IonItem>

            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                備註
              </IonLabel>
              <IonTextarea
                placeholder="輸入備註"
                autoGrow={true}
                value={remarks}
                onIonChange={(e) => setRemarks(e.detail.value ?? "")}
              />
            </IonItem>
            <IonItem color="white" lines="none">
              <IonLabel color="primary" position="stacked">
                開始時間 *
              </IonLabel>
              <DateTimeInput
                displayFormat="YYYY/MM/DD HH:mm:ss"
                placeholder="選擇時間"
                ionDatetimeProps={{
                  max: endTime?.toISOString(true),
                }}
                value={startTime}
                onChange={(value) => setStartTime(value)}
              />
            </IonItem>
            <IonItem color="white" lines="none">
              <IonLabel color="primary" position="stacked">
                完結時間 *
              </IonLabel>
              <DateTimeInput
                displayFormat="YYYY/MM/DD HH:mm:ss"
                placeholder="選擇時間"
                ionDatetimeProps={{
                  min: startTime?.toISOString(true),
                }}
                value={endTime}
                onChange={(value) => setEndTime(value)}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        {mode === "UPDATE" && (
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonButton expand="block" color="danger" onClick={onDelete}>
                刪除活動
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
});

export default EventDetailsPage;
