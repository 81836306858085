import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonBackButton,
  useIonAlert,
  IonLoading,
} from "@ionic/react";
import { useState } from "react";
import "./BillboardDetailsPage.css";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  ADMIN_FETCH_BILLBOARD,
  ADMIN_MAKE_BILLBOARD,
} from "../../graphql/queries";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import moment from "moment";
import { generatePath, useHistory } from "react-router";
import DateTimeInput from "../../components/Input/DateTimeInput";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import {
  AdminMakeBillboard,
  AdminMakeBillboardVariables,
} from "../../graphql/__generated__/AdminMakeBillboard";
import { LocalValidationError } from "../../models/error";
import { evictQueryCache } from "../../utils/apollo";
import { generateBillboardsPagePath } from "../Billboards/BillboardsPage";

export const BILLBOARD_DETAILS_PAGE_PATH_PATTERN = "/billboard/new";

export function generateBillboardDetailsPagePath(): string {
  return generatePath(BILLBOARD_DETAILS_PAGE_PATH_PATTERN);
}

const BillboardDetailsPage: React.FC = () => {
  const [present] = useIonAlert();
  const apolloClient = useApolloClient();

  const [name, setName] = useState("");
  const [submittedFYC, setSubmittedFYC] = useState("0");
  const [submittedCases, setSubmittedCases] = useState("0");
  const [targetDate, setTargetDate] = useState(moment().startOf("month"));

  const history = useHistory();

  const [submitMakeBillboard, { loading: loadingSubmitUpdateOrAddArticle }] =
    useMutation<AdminMakeBillboard, AdminMakeBillboardVariables>(
      ADMIN_MAKE_BILLBOARD
    );

  const handleMakeBillboardError = useImperativeErrorHandler({
    name: "makeBillboardError",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          date: "日期",
          name: "名稱",
          submittedFYC: "Submitted FYC",
          submittedCases: "Submitted cases",
        })
      ) {
        return true;
      }
      if (error instanceof LocalValidationError) {
        ctx.showErrorModal(error.message);
        return true;
      }
      return false;
    },
  });

  const saveBillboard = async (publishAfterSave: boolean = false) => {
    try {
      const submittedFYCVal = parseInt(submittedFYC, 10);
      const submittedCasesVal = parseInt(submittedCases, 10);

      let errorMessages = [];
      if (isNaN(submittedFYCVal)) {
        errorMessages.push("Submitted FYC must be numeric");
      }
      if (isNaN(submittedCasesVal)) {
        errorMessages.push("Submitted cases must be numeric");
      }
      if (errorMessages.length > 0) {
        throw new LocalValidationError(errorMessages);
      }

      await submitMakeBillboard({
        variables: {
          adminInput: {
            date: targetDate.toISOString(),
            name,
            submittedFYC: submittedFYCVal,
            submittedCases: submittedCasesVal,
          },
        },
      });

      evictQueryCache(apolloClient.cache, ADMIN_FETCH_BILLBOARD);
      history.replace(generateBillboardsPagePath());
    } catch (e) {
      handleMakeBillboardError(e);
    } finally {
    }
  };

  return (
    <IonPage>
      <IonLoading isOpen={loadingSubmitUpdateOrAddArticle} />
      <IonContent>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonBackButton
                color="primary"
                defaultHref={generateBillboardsPagePath()}
              />
            </IonButtons>

            <IonTitle>龍虎榜紀錄</IonTitle>
            <IonButtons slot="primary">
              <IonButton color="primary" onClick={() => saveBillboard()}>
                儲存
              </IonButton>
            </IonButtons>
          </IonToolbar>

          <IonCard className="no-margin-card ">
            <IonCardContent>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  名稱 *
                </IonLabel>
                <IonInput
                  placeholder="輸入名稱"
                  value={name}
                  onIonChange={(e) => setName(e.detail.value ?? "")}
                />
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  Submitted Cases *
                </IonLabel>
                <IonInput
                  placeholder="Enter submitted cases"
                  value={submittedCases}
                  onIonChange={(e) => setSubmittedCases(e.detail.value ?? "")}
                />
              </IonItem>

              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  Submitted FYC *
                </IonLabel>
                <IonInput
                  placeholder="Enter submitted FYC"
                  value={submittedFYC}
                  onIonChange={(e) => setSubmittedFYC(e.detail.value ?? "")}
                />
              </IonItem>

              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  日期 *
                </IonLabel>
                <DateTimeInput
                  displayFormat="YYYY/MM/DD"
                  ionDatetimeProps={{
                    max: "2050",
                    presentation: "date",
                  }}
                  value={targetDate}
                  onChange={(value) => setTargetDate(value)}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default BillboardDetailsPage;
