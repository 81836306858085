import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useMemo } from "react";
import { generatePath } from "react-router-dom";
import { ADMIN_FETCH_EVENTS } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateEventDetailsPagePath } from "../EventDetails/EventDetailsPage";

import "./EventsPage.css";

export const EVENTS_PAGE_PATH_PATTERN = "/events";

export function generateEventsPagePath(): string {
  return generatePath(EVENTS_PAGE_PATH_PATTERN);
}

const EVENTS_PER_FETCH = 10;

const EventsPage: React.FC = React.memo(() => {
  const {
    data: eventsData,
    loading: loadingMoreEvents,
    fetchMore: fetchMoreEvents,
    error: fetchEventsError,
  } = useQuery(ADMIN_FETCH_EVENTS, {
    variables: {
      listQuery: {
        offset: 0,
        limit: EVENTS_PER_FETCH,
      },
    },
  });

  useErrorHandler(fetchEventsError, {
    name: "eventsData",
  });
  const handleFetchMoreEventsError = useImperativeErrorHandler({
    name: "fetchMoreEvents",
  });

  const eventsList = useMemo(() => {
    return eventsData?.adminFetchEvents.data;
  }, [eventsData]);

  const count = useMemo(() => {
    return eventsData?.adminFetchEvents.count;
  }, [eventsData]);

  const allLoaded = useMemo(
    () => (!!eventsList ? eventsList.length >= count : false),
    [count, eventsList]
  );

  const loadMoreData = async (ev: any) => {
    try {
      if (allLoaded) return;
      await fetchMoreEvents({
        variables: {
          listQuery: {
            offset: eventsList.length,
            limit: EVENTS_PER_FETCH,
          },
        },
      });
    } catch (e) {
      handleFetchMoreEventsError(e);
    } finally {
      ev.target.complete();
    }
  };

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreEvents} />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>

          <IonButtons slot="primary">
            {/* <SortOptionsPopovers
              trigger={"sort-button"}
              options={sortOptions}
              onOptionsChange={(e: any) => {
                setSortingParams(e);
              }}
            /> */}
            <IonButton
              color="primary"
              routerLink={generateEventDetailsPagePath("new")}
            >
              增加活動
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>活動</h1>
            </IonCardTitle>
          </IonCardContent>
        </IonCard>

        <IonList>
          {eventsList?.map((e: any) => {
            return (
              <IonItem
                key={`events-${e.title}`}
                detail
                lines="none"
                color="white"
                routerLink={`events/${e.uuid}`}
              >
                <IonLabel className="ion-text-wrap">
                  <IonText color="dark">
                    <h2>{e.title}</h2>
                  </IonText>
                  <IonText color="medium">
                    <p>{e.location}</p>
                  </IonText>
                  <IonText
                    className="events-page__item__remarks"
                    color="medium"
                  >
                    <p>{e.remarks}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>

        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default EventsPage;
