import { isApolloError } from "@apollo/client";
import { useIonAlert } from "@ionic/react";
import { useMemo, useCallback, useEffect, useRef } from "react";
import { StrapiErrorName } from "../constants/strapi";
import { getYupPathString, isNetworkConnectionError } from "../utils/error";
import { isStrapiYupValidationErrorDetails } from "../utils/strapi-error";
import { findStrapiGraphQLErrorExtensionsByName } from "../utils/strapi-gql";

import "./useErrorHandler.css";

export type ErrorHandler = (error: any) => void;

export interface ErrorHandlerContext {
  showErrorModal: (message: string, subHeader?: string) => Promise<void>;
}

export interface UseErrorHandlerOptions {
  name?: string;
  unknownErrorMessage?: string;
  errorHandler?: (error: any, context: ErrorHandlerContext) => boolean;
  defaultErrorModalCloseCallback?: () => void;
}

function defaultErrorHandler(error: any, ctx: ErrorHandlerContext): boolean {
  if (isApolloError(error)) {
    const extensions = findStrapiGraphQLErrorExtensionsByName(
      error.graphQLErrors,
      StrapiErrorName.VALIDATION_ERROR
    );
    if (extensions) {
      if (isStrapiYupValidationErrorDetails(extensions.error.details)) {
        const msg = extensions.error.details.errors
          .map((error) => `[${getYupPathString(error.path)}] ${error.message}`)
          .join("\n");
        ctx.showErrorModal(msg, "Validation Error");
      } else {
        ctx.showErrorModal(extensions.error.message, "Validation Error");
      }
      return true;
    }

    if (isNetworkConnectionError(error)) {
      ctx.showErrorModal("暫時無法連接到伺服器，請稍後再試");
      return true;
    }
  }
  return false;
}

export function useImperativeErrorHandler(
  options: UseErrorHandlerOptions
): ErrorHandler {
  const [present] = useIonAlert();
  const defaultErrorModalCloseCallbackRef = useRef<
    UseErrorHandlerOptions["defaultErrorModalCloseCallback"]
  >(options.defaultErrorModalCloseCallback);

  const showErrorModal = useCallback(
    (message: string, subHeader?: string): Promise<void> => {
      return new Promise((resolve) => {
        // Show alert for unknown error
        present({
          cssClass: "error-handler-alert",
          header: "Error Occurred",
          subHeader,
          message: message,
          buttons: ["Ok"],
          onDidDismiss: (e) => {
            resolve();
          },
        });
      });
    },
    [present]
  );

  const handlerName = useMemo(() => options.name, [options.name]);
  const unknownErrorMessage = useMemo(
    () => options.unknownErrorMessage,
    [options.unknownErrorMessage]
  );
  const errorHandler = useMemo(
    () => options.errorHandler,
    [options.errorHandler]
  );

  const handleError = useCallback(
    (error: any) => {
      // Try handling error
      if (errorHandler) {
        const handled = errorHandler(error, {
          showErrorModal,
        });
        if (handled) {
          return;
        }
      }

      // Default error handler
      const handledByDefaultErrorHandler = defaultErrorHandler(error, {
        showErrorModal,
      });
      if (handledByDefaultErrorHandler) {
        return;
      }

      // Log error
      if (handlerName) {
        console.error(handlerName, error, { ...error });
      } else {
        console.error(error, { ...error });
      }

      // Show alert for unknown error
      showErrorModal(unknownErrorMessage ?? String(error))
        .then(() => {
          defaultErrorModalCloseCallbackRef.current?.();
        })
        .catch((error) => console.error(error));
    },
    [errorHandler, handlerName, showErrorModal, unknownErrorMessage]
  );

  // Bind defaultErrorModalCloseCallbackRef
  useEffect(() => {
    defaultErrorModalCloseCallbackRef.current =
      options.defaultErrorModalCloseCallback;
  }, [options.defaultErrorModalCloseCallback]);

  return handleError;
}

export function useErrorHandler(
  error: any,
  options: UseErrorHandlerOptions
): void {
  const handleError = useImperativeErrorHandler(options);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
}
