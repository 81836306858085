import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { linkOutline } from "ionicons/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ADMIN_FETCH_QUIZZIES } from "../../graphql/queries";
import { AdminFetchLesson_adminFetchLesson_quizzes } from "../../graphql/__generated__/AdminFetchLesson";
import {
  AdminFetchQuizzes,
  AdminFetchQuizzesVariables,
} from "../../graphql/__generated__/AdminFetchQuizzes";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";

const QuizSelectModal: React.FC<{
  selectedQuiz: AdminFetchLesson_adminFetchLesson_quizzes[];
  isShowModal: boolean;
  dismissModal: () => void;
  onQuizUpdated: (quizzes: AdminFetchLesson_adminFetchLesson_quizzes[]) => void;
}> = ({ selectedQuiz, isShowModal, dismissModal, onQuizUpdated }) => {
  const QUIZZES_PER_FETCH = 10;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedQuizzes, setSelectedQuizzes] = useState<
    AdminFetchLesson_adminFetchLesson_quizzes[]
  >([]);
  const {
    data: quizzesData,
    error: fetchQuizzesDataError,
    loading: loadingMoreQuizzes,
    fetchMore: fetchMoreQuizzes,
  } = useQuery<AdminFetchQuizzes, AdminFetchQuizzesVariables>(
    ADMIN_FETCH_QUIZZIES,
    {
      variables: {
        listQuery: {
          keyword: searchKeyword,
          offset: 0,
          limit: QUIZZES_PER_FETCH,
        },
      },
    }
  );

  useErrorHandler(fetchQuizzesDataError, {
    name: "quizzesData",
  });
  const handleFetchMoreQuizzesError = useImperativeErrorHandler({
    name: "fetchMoreQuizzes",
  });

  useEffect(() => {
    setSelectedQuizzes(selectedQuiz);
  }, [selectedQuiz]);

  const quizzesList = useMemo(() => {
    return quizzesData?.adminFetchQuizzes?.data;
  }, [quizzesData]);

  const quizzesListExcluded = useMemo(() => {
    if (!quizzesList || !selectedQuizzes) {
      return [];
    }

    const excluded = quizzesList.filter((q) => {
      return selectedQuizzes.find((s) => s.uuid === q.uuid) === undefined;
    });

    return excluded;
  }, [quizzesList, selectedQuizzes]);

  const count = useMemo(
    () => quizzesData?.adminFetchQuizzes?.count,
    [quizzesData]
  );

  const allLoaded = useMemo(
    () =>
      !!quizzesList && count !== undefined
        ? quizzesList.length >= count
        : false,
    [count, quizzesList]
  );

  const addQuiz = (quiz: AdminFetchLesson_adminFetchLesson_quizzes) => {
    const _selectedQuiz = [...selectedQuizzes];
    _selectedQuiz.push(quiz);

    setSelectedQuizzes(_selectedQuiz);
  };

  const removeQuiz = ({ quizUuid }: { quizUuid: string }) => {
    const _selectedQuiz = [...selectedQuizzes];
    const _removed = _selectedQuiz.filter((s) => s.uuid !== quizUuid);
    setSelectedQuizzes(_removed);
  };

  const loadMoreData = useCallback(
    async (ev: any) => {
      try {
        if (!quizzesList) return;
        if (allLoaded) return;
        await fetchMoreQuizzes({
          variables: {
            offset: quizzesList.length,
          },
        });
      } catch (e) {
        handleFetchMoreQuizzesError(e);
      } finally {
        ev.target.complete();
      }
    },
    [allLoaded, fetchMoreQuizzes, handleFetchMoreQuizzesError, quizzesList]
  );

  //   const initModal = () => {
  //    // setSelectedQuizzes([]);
  //   };
  return (
    <IonModal
      isOpen={isShowModal ?? false}
      onIonModalWillPresent={() => {
        // initModal();
      }}
    >
      <IonLoading isOpen={loadingMoreQuizzes} />
      <IonContent>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={() => dismissModal()}>
                關閉
              </IonButton>
            </IonButtons>
            <IonTitle>連結測驗</IonTitle>
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                onClick={() => {
                  onQuizUpdated(selectedQuizzes);
                  dismissModal();
                }}
              >
                完成
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonSearchbar
                onIonChange={(e) => {
                  setSearchKeyword(e.detail.value ?? "");
                }}
              ></IonSearchbar>
            </IonCardContent>
          </IonCard>

          <IonListHeader>
            <IonLabel>已連結測驗</IonLabel>
          </IonListHeader>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              {selectedQuizzes?.map((m) => {
                return (
                  <IonItem
                    lines="none"
                    color="white"
                    key={`${m.title}`}
                    onClick={() => removeQuiz({ quizUuid: m.uuid })}
                  >
                    <IonLabel className="ion-text-wrap">
                      <IonText color="dark">
                        <h2>{m.title}</h2>
                      </IonText>
                      {/* <IonText color="medium">
                        <p>{m.lessons[0]?.title}</p>
                      </IonText> */}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      ios={linkOutline}
                      md={linkOutline}
                      slot="end"
                    ></IonIcon>
                  </IonItem>
                );
              })}
            </IonCardContent>
          </IonCard>

          <IonListHeader>
            <IonLabel>其他測驗</IonLabel>
          </IonListHeader>

          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              {quizzesListExcluded?.map((m) => {
                return (
                  <IonItem
                    lines="none"
                    color="white"
                    key={`${m.title}`}
                    onClick={() => {
                      addQuiz(m);
                    }}
                  >
                    <IonLabel className="ion-text-wrap">
                      <IonText color="dark">
                        <h2>{m.title}</h2>
                      </IonText>
                      <IonText color="medium">
                        <p>{m.lessons[0]?.title}</p>
                      </IonText>
                    </IonLabel>
                    {/* <IonIcon
                    color="primary"
                    ios={linkOutline}
                    md={linkOutline}
                    slot="end"
                  ></IonIcon> */}
                  </IonItem>
                );
              })}
            </IonCardContent>
          </IonCard>
        </IonList>
        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
          // disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonModal>
  );
};

export default QuizSelectModal;
