export enum EmailStatusEnum {
  sent = "sent",
  draft = "draft",
}

export function getEmailStatusColorClass(status: EmailStatusEnum): string {
  switch (status) {
    case EmailStatusEnum.sent:
      return "success";
    case EmailStatusEnum.draft:
      return "primary";

    default:
      return "black";
  }
}
