import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";

import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import {
  ADMIN_FETCH_LEADS_QUERY,
  ADMIN_FETCH_LEAD_QUERY,
  ADMIN_MAKE_LEAD,
  ADMIN_REMOVE_LEAD,
  ADMIN_UPDATE_LEAD,
} from "../../graphql/queries";
import {
  AdminFetchLead,
  AdminFetchLeadVariables,
} from "../../graphql/__generated__/AdminFetchLead";
import MemberSelectModal, {
  createSimpleUserFromUserLike,
  SimpleUser,
} from "../../modals/MemberSelect/MemberSelectModal";

import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import {
  getLeadStatusColorClass,
  getLeadStatusText,
  LeadStatusEnum,
} from "../../types/LeadStatus";

import {
  AdminMakeLead,
  AdminMakeLeadVariables,
} from "../../graphql/__generated__/AdminMakeLead";
import {
  AdminUpdateLead,
  AdminUpdateLeadVariables,
} from "../../graphql/__generated__/AdminUpdateLead";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import "./LeadDetailsPage.css";
import { evictQueryCache } from "../../utils/apollo";
import { generateLeadPagePath } from "../Lead/LeadPage";

export const LEAD_DETAILS_PAGE_PAGE_PATH_PATTERN = "/leads/:uuid";

export function generateLeadDetailsPagePath(uuid: string): string {
  return generatePath(LEAD_DETAILS_PAGE_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const LeadDetailsPage: React.FC = React.memo(() => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  const { uuid } = useParams<{ uuid: string }>();
  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [remarks, setRemarks] = useState("");
  const [allocatedStaff, setAllocatedStaff] = useState<
    SimpleUser | undefined
  >();
  const [status, setStatus] = useState<LeadStatusEnum>(LeadStatusEnum.noData);
  const [showMemberSelectModal, setShowMemberSelectModal] = useState(false);

  const {
    data: leadData,
    loading: loadingLead,
    error: fetchLeadError,
  } = useQuery<AdminFetchLead, AdminFetchLeadVariables>(
    ADMIN_FETCH_LEAD_QUERY,
    {
      variables: {
        uuid,
      },
      skip: mode === "CREATE",
    }
  );

  const [submitLeadUpdate, { loading: submittingLeadUpdate }] = useMutation<
    AdminUpdateLead,
    AdminUpdateLeadVariables
  >(ADMIN_UPDATE_LEAD);

  const [submitLeadCreation, { loading: submittingLeadCreation }] = useMutation<
    AdminMakeLead,
    AdminMakeLeadVariables
  >(ADMIN_MAKE_LEAD);

  const [removeLead, { loading: loadingRemoveLead }] =
    useMutation(ADMIN_REMOVE_LEAD);

  useErrorHandler(fetchLeadError, {
    name: "leadData",
  });
  const handleSubmitLeadCreationError = useImperativeErrorHandler({
    name: "submitLeadCreation",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          name: "名稱",
          email: "電郵",
          phone: "電話",
          status: "狀態",
          remarks: "備註",
        })
      ) {
        return true;
      }
      return false;
    },
  });
  const handleSubmitLeadUpdateError = useImperativeErrorHandler({
    name: "submitLeadUpdate",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          status: "狀態",
          remarks: "備註",
        })
      ) {
        return true;
      }
      return false;
    },
  });
  const handleRemoveLeadError = useImperativeErrorHandler({
    name: "removeLead",
  });

  const onConnectMemberClick = useCallback(() => {
    setShowMemberSelectModal(true);
  }, []);

  const onMemberSelectModalDismiss = useCallback(() => {
    setShowMemberSelectModal(false);
  }, []);

  const makeLeadEntry = useCallback(async () => {
    try {
      await submitLeadCreation({
        variables: {
          adminInput: {
            name,
            email,
            phone,
            status,
            allocatedStaffId: allocatedStaff?.id,
            remarks,
          },
        },
      });

      evictQueryCache(apolloClient.cache, ADMIN_FETCH_LEADS_QUERY);
      history.replace(generateLeadPagePath());
    } catch (error) {
      handleSubmitLeadCreationError(error);
    }
  }, [
    allocatedStaff?.id,
    apolloClient.cache,
    email,
    handleSubmitLeadCreationError,
    history,
    name,
    phone,
    remarks,
    status,
    submitLeadCreation,
  ]);

  const updateLeadEntry = useCallback(async () => {
    try {
      await submitLeadUpdate({
        variables: {
          adminInput: {
            uuid,
            status,
            allocatedStaffId: allocatedStaff?.id,
            remarks,
          },
        },
      });

      alert("Success!");
    } catch (error) {
      handleSubmitLeadUpdateError(error);
    }
  }, [
    allocatedStaff?.id,
    handleSubmitLeadUpdateError,
    remarks,
    status,
    submitLeadUpdate,
    uuid,
  ]);

  const onSave = useCallback(() => {
    if (mode === "CREATE") {
      makeLeadEntry();
    } else {
      updateLeadEntry();
    }
  }, [makeLeadEntry, mode, updateLeadEntry]);

  const onDelete = useCallback(() => {
    presentAlert({
      header: "Delete Incentive",
      message: "Are you sure?<br> This action cannot be undone.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeLead({
                variables: {
                  uuid: uuid,
                },
              });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_LEADS_QUERY);
              history.replace(generateLeadPagePath());
            } catch (error) {
              handleRemoveLeadError(error);
            }
          },
        },
      ],
    });
  }, [
    apolloClient.cache,
    handleRemoveLeadError,
    history,
    presentAlert,
    removeLead,
    uuid,
  ]);

  const leadDataView = useMemo(() => leadData?.adminFetchLead, [leadData]);

  useEffect(() => {
    if (!!leadDataView) {
      console.log("UPDATE", leadDataView.status);
      setName(leadDataView.name);
      setEmail(leadDataView.email);
      setPhone(leadDataView.phone);
      setRemarks(leadDataView.remarks ?? "");
      setAllocatedStaff(
        leadDataView.allocatedStaff
          ? createSimpleUserFromUserLike(leadDataView.allocatedStaff)
          : undefined
      );
      setStatus(leadDataView.status as LeadStatusEnum);
    }
  }, [leadDataView]);

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingLead ||
          submittingLeadUpdate ||
          submittingLeadCreation ||
          loadingRemoveLead
        }
      />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={generateLeadPagePath()}
            />
          </IonButtons>
          <IonTitle>潛在客戶</IonTitle>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={onSave}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>

        {mode === "UPDATE" && (
          <IonCard className="lead-details-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>{leadDataView?.name}</h1>
              </IonCardTitle>
              <IonChip
                className="no-margin-h"
                color={getLeadStatusColorClass(status)}
                outline={true}
              >
                <IonLabel>{getLeadStatusText(status)}</IonLabel>
              </IonChip>
            </IonCardContent>
          </IonCard>
        )}

        <IonCard className="lead-details-card ">
          <IonCardContent>
            {mode === "UPDATE" && (
              <IonItem lines="none" color="white" disabled={true}>
                <IonLabel color="primary" position="stacked">
                  Joining Time
                </IonLabel>
                <IonInput
                  readonly={true}
                  value={
                    leadDataView?.createdAt
                      ? moment(leadDataView.createdAt).format("DD MMM YYYY")
                      : "-"
                  }
                />
              </IonItem>
            )}
            {mode === "CREATE" && (
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  名稱 *
                </IonLabel>
                <IonInput
                  placeholder="輸入名稱"
                  value={name}
                  onIonChange={(e) => setName(e.detail.value!)}
                />
              </IonItem>
            )}
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                電郵 *
              </IonLabel>
              <IonInput
                placeholder="輸入電郵"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                電話 *
              </IonLabel>
              <IonInput
                placeholder="輸入電話"
                value={phone}
                onIonChange={(e) => setPhone(e.detail.value!)}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <IonCard color="white" className="lead-details-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                負責成員
              </IonLabel>
              <IonNote color={allocatedStaff ? "dark" : undefined}>
                {allocatedStaff?.username ?? "未分配"}
              </IonNote>
            </IonItem>
            <IonButton
              expand="block"
              color="primary"
              onClick={onConnectMemberClick}
            >
              連結成員
            </IonButton>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                狀態 *
              </IonLabel>
              <IonSelect
                value={status}
                okText="Okay"
                cancelText="Dismiss"
                placeholder="選擇狀態"
                onIonChange={(e) => {
                  if (e.detail.value) {
                    setStatus(e.detail.value);
                  }
                }}
              >
                <IonSelectOption value="processing">Processing</IonSelectOption>
                <IonSelectOption value="new-added">New-add</IonSelectOption>
                <IonSelectOption value="rejected">Rejected</IonSelectOption>
                <IonSelectOption value="subscribed">Subscribed</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                備註
              </IonLabel>
              <IonInput
                value={remarks}
                onIonChange={(e) => {
                  if (e.detail.value) {
                    setRemarks(e.detail.value);
                  }
                }}
                placeholder="輸入備註（如有）"
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        {mode === "UPDATE" && (
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonButton expand="block" color="danger" onClick={onDelete}>
                Delete lead
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>

      <MemberSelectModal
        isShowModal={showMemberSelectModal}
        selectedMember={allocatedStaff}
        dismissModal={onMemberSelectModalDismiss}
        onSelect={(selectedMember) => setAllocatedStaff(selectedMember)}
      />
    </IonPage>
  );
});

export default LeadDetailsPage;
