export enum LeadStatusEnum {
  processing = "processing",
  success = "success",
  rejected = "rejected",
  newAdded = "new-added",
  noData = "",
}

export function getLeadStatusColorClass(status: LeadStatusEnum): string {
  switch (status) {
    case LeadStatusEnum.processing:
      return "processing";
    case LeadStatusEnum.success:
      return "primary";
    case LeadStatusEnum.rejected:
      return "danger";
    case LeadStatusEnum.newAdded:
      return "success";

    default:
      return "black";
  }
}

export function getLeadStatusText(status: LeadStatusEnum): string {
  switch (status) {
    case LeadStatusEnum.processing:
      return "Processing";
    case LeadStatusEnum.success:
      return "Success";
    case LeadStatusEnum.rejected:
      return "Rejected";
    case LeadStatusEnum.newAdded:
      return "New added";

    default:
      return "No Data";
  }
}
