import { useMutation, useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonNote,
  IonPage,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { ADMIN_UPDATE_ME, FETCH_ME } from "../../graphql/queries";
import {
  AdminUpdateMe,
  AdminUpdateMeVariables,
} from "../../graphql/__generated__/AdminUpdateMe";
import { FetchMe } from "../../graphql/__generated__/FetchMe";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";

export const SETTING_PAGE_PAGE_PATH_PATTERN = "/setting";

export function generateSettingPagePath(): string {
  return generatePath(SETTING_PAGE_PAGE_PATH_PATTERN);
}

const SettingPage: React.FC = React.memo(() => {
  const auth = useAuth();
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const {
    data: meData,
    loading: loadingMe,
    error: fetchMeError,
  } = useQuery<FetchMe>(FETCH_ME);

  const [requestUpdateMe, { loading: requestingUpdateMe }] = useMutation<
    AdminUpdateMe,
    AdminUpdateMeVariables
  >(ADMIN_UPDATE_ME);

  const handleUpdateMeError = useImperativeErrorHandler({
    name: "updateMe",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          password: "密碼",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  useErrorHandler(fetchMeError, {
    name: "fetchMe",
  });

  const dateJoined = useMemo(() => {
    if (!meData) {
      return undefined;
    }
    return moment(meData.fetchMe.createdAt).format("DD MMM YYYY");
  }, [meData]);

  const logout = useCallback(() => {
    presentAlert("你確定要登出嗎？", [
      {
        text: "取消",
        role: "cancel",
      },
      {
        text: "確定",
        handler: () => {
          auth.logout();
          history.replace("/");
        },
      },
    ]);
  }, [auth, history, presentAlert]);

  const onSave = useCallback(async () => {
    try {
      await requestUpdateMe({
        variables: {
          changePassword: {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          },
        },
      });

      await presentAlert("密碼已更新");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      handleUpdateMeError(error);
    }
  }, [
    confirmPassword,
    currentPassword,
    handleUpdateMeError,
    newPassword,
    presentAlert,
    requestUpdateMe,
  ]);

  return (
    <IonPage>
      <IonLoading isOpen={loadingMe || requestingUpdateMe} />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>

          <IonButtons slot="primary">
            <IonButton color="primary" onClick={onSave}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>設定</h1>
            </IonCardTitle>
          </IonCardContent>
        </IonCard>

        <IonCard className="lead-details-card">
          <IonCardContent>
            <IonList>
              <IonItem color="white" lines="none">
                <IonLabel color="primary" position="stacked">
                  電郵
                </IonLabel>
                <IonNote>{meData?.fetchMe.email}</IonNote>
              </IonItem>
              <IonItem color="white" lines="none">
                <IonLabel color="primary" position="stacked">
                  加入日期
                </IonLabel>
                <IonNote>{dateJoined}</IonNote>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard className="lead-details-card">
          <IonCardContent>
            <IonList>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  現時密碼
                </IonLabel>
                <IonInput
                  type="password"
                  placeholder="輸入現時密碼"
                  value={currentPassword}
                  onIonChange={(e) => setCurrentPassword(e.detail.value!)}
                />
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  新密碼
                </IonLabel>
                <IonInput
                  type="password"
                  placeholder="輸入新密碼"
                  value={newPassword}
                  onIonChange={(e) => setNewPassword(e.detail.value!)}
                />
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  確認密碼
                </IonLabel>
                <IonInput
                  type="password"
                  placeholder="重新輸入新密碼"
                  value={confirmPassword}
                  onIonChange={(e) => setConfirmPassword(e.detail.value!)}
                />
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard className="lead-details-card">
          <IonCardContent>
            <IonList>
              <IonItem
                color="white"
                lines="none"
                button={true}
                onClick={logout}
              >
                <IonLabel color="danger">登出</IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>


        <IonCard className="lead-details-card">
          <IonCardContent>
            <IonList>
              <IonItem color="white" lines="none">
                <IonLabel color="dark">
                  Powered by Prophecy Marketing International
                </IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
});

export default SettingPage;
