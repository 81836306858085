import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { useMemo, useRef } from "react";
import { TYPE_POLICIES } from "../graphql/policies";
import { useRefToValueBinding } from "../hooks/useRefToValueBinding";
import { isApolloServerError } from "../utils/apollo";
import { useAuth } from "./AuthContext";

interface ContextForApolloClient {
  logout: () => void;
  getAuthToken: () => string | undefined;
}

function crateApolloClient(uri: string, context: ContextForApolloClient) {
  const errorLink = onError((errorResp) => {
    if (errorResp.networkError && isApolloServerError(errorResp.networkError)) {
      switch (errorResp.networkError.statusCode) {
        case 401:
          console.log(
            "[ApolloProvider] will logout as received 401 status code"
          );
          if (context.getAuthToken() !== undefined) {
            context.logout();
          }
          break;
      }
    }
  });

  const httpLink = createHttpLink({
    uri,
  });

  const uploadLink = createUploadLink({
    uri,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from cookies if it exists
    const token = context.getAuthToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  const client = new ApolloClient({
    //@ts-ignore
    link: ApolloLink.from([errorLink, authLink, uploadLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: TYPE_POLICIES,
      dataIdFromObject: (object, ctx) => {
        // Return default data id if exists
        const defaultDataId = defaultDataIdFromObject(object, ctx);
        if (defaultDataId !== undefined) {
          return defaultDataId;
        }
        // Return data id from uuid if exists
        if ("uuid" in object && typeof object.uuid === "string") {
          return `${object.__typename}:${object.uuid}`;
        }
        // No id
        return undefined;
      },
    }),
    // defaultOptions,
  });

  return client;
}

interface Props {
  uri: string;
}

export const ApolloServiceProvider: React.FC<Props> = React.memo((props) => {
  const { uri, children } = props;

  const auth = useAuth();

  const authRef = useRef(auth);
  useRefToValueBinding(authRef, auth);

  const client = useMemo(() => {
    const context = {
      logout: authRef.current.logout,
      getAuthToken: () => authRef.current.authToken,
    };
    return crateApolloClient(uri, context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
});
