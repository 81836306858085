import { useMutation, useQuery } from "@apollo/client";
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonList,
  IonLabel,
  IonItem,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import { pencilSharp, star, starOutline } from "ionicons/icons";
import React, { useCallback, useEffect, useMemo } from "react";
import { generatePath } from "react-router-dom";
import {
  ADMIN_FETCH_ARTICLES,
  ADMIN_MAKE_OR_UPDATE_ARTICLE,
} from "../../graphql/queries";
import {
  AdminFetchArticles,
  AdminFetchArticlesVariables,
} from "../../graphql/__generated__/AdminFetchArticles";
import {
  AdminMakeOrUpdateArticle,
  AdminMakeOrUpdateArticleVariables,
} from "../../graphql/__generated__/AdminMakeOrUpdateArticle";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import {
  ArticleStatus,
  getArticleStatus18nText,
  getArticleStatusColor,
} from "../../types/ArticleStatus";
import { generateArticleDetailsPagePath } from "../ArticleDetails/ArticleDetailsPage";
import "./ArticlesPage.css";

export const ARTICLES_PAGE_PATH_PATTERN = "/articles";

export function generateArticlesPagePath(): string {
  return generatePath(ARTICLES_PAGE_PATH_PATTERN);
}

const ARTICLES_PER_FETCH = 10;

const ArticlesPage: React.FC = React.memo(() => {
  const {
    data: articlesData,
    loading: loadingMoreArticles,
    error: fetchArticlesError,
    fetchMore: fetchMoreArticles,
  } = useQuery<AdminFetchArticles, AdminFetchArticlesVariables>(
    ADMIN_FETCH_ARTICLES,
    {
      variables: {
        listQuery: {
          offset: 0,
          limit: ARTICLES_PER_FETCH,
        },
      },
    }
  );

  const [submitUpdateArticle, { loading: submittingUpdateArticle }] =
    useMutation<AdminMakeOrUpdateArticle, AdminMakeOrUpdateArticleVariables>(
      ADMIN_MAKE_OR_UPDATE_ARTICLE
    );

  useErrorHandler(fetchArticlesError, {
    name: "articlesData",
  });
  const handleFetchMoreArticlesError = useImperativeErrorHandler({
    name: "fetchMoreArticles",
  });
  const handleUpdateArticlePinnedError = useImperativeErrorHandler({
    name: "updateArticlePinned",
  });

  const articlesList = useMemo(
    () => articlesData?.adminFetchArticles.data,
    [articlesData]
  );

  const count = useMemo(() => {
    return articlesData?.adminFetchArticles.count;
  }, [articlesData]);

  const allLoaded = useMemo(
    () =>
      !!articlesList && count !== undefined
        ? articlesList.length >= count
        : false,
    [count, articlesList]
  );

  const loadMoreData = useCallback(
    async (ev: any) => {
      try {
        if (!articlesList) return;
        if (allLoaded) return;
        await fetchMoreArticles({
          variables: {
            listQuery: {
              offset: articlesList.length,
              limit: ARTICLES_PER_FETCH,
            },
          },
        });
      } catch (e) {
        handleFetchMoreArticlesError(e);
      } finally {
        ev.target.complete();
      }
    },
    [allLoaded, articlesList, fetchMoreArticles, handleFetchMoreArticlesError]
  );

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreArticles || submittingUpdateArticle} />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>

          <IonButtons slot="primary">
            {/* <SortOptionsPopovers
                trigger={"sort-button"}
                options={sortOptions}
                onOptionsChange={(e: any) => {
                  setSortingParams(e);
                }}
              /> */}

            <IonButton
              color="primary"
              routerLink={generateArticleDetailsPagePath("new")}
            >
              <IonIcon slot="icon-only" ios={pencilSharp} md={pencilSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>最新消息</h1>
            </IonCardTitle>
          </IonCardContent>
        </IonCard>

        <IonList>
          {articlesList?.map((a) => {
            const onPinButtonClick = async (
              event: React.MouseEvent<HTMLIonButtonElement>
            ) => {
              try {
                event.preventDefault();
                event.stopPropagation();

                await submitUpdateArticle({
                  variables: {
                    adminInput: {
                      uuid: a.uuid,
                      pinned: !a.pinned,
                    },
                  },
                });
              } catch (error) {
                handleUpdateArticlePinnedError(error);
              }
            };

            return (
              <IonItem
                detail
                lines="none"
                color="white"
                key={a.uuid}
                routerLink={generateArticleDetailsPagePath(a.uuid)}
              >
                <IonButton slot="start" fill="clear" onClick={onPinButtonClick}>
                  <IonIcon
                    slot="icon-only"
                    color="primary"
                    icon={a.pinned ? star : starOutline}
                  />
                </IonButton>
                <IonLabel className="ion-text-wrap">
                  <IonText
                    color={getArticleStatusColor(a.status as ArticleStatus)}
                  >
                    <h6>
                      {getArticleStatus18nText(a.status as ArticleStatus)}
                    </h6>
                  </IonText>
                  <IonText color="dark">
                    <h2> {a.title}</h2>
                  </IonText>
                  <IonText color="medium">
                    <p> {a.author}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>

        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default ArticlesPage;
