import { IonApp, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Bootstrap from "./bootstrap/Bootstrap";
import { AuthContextProvider } from "./contexts/AuthContext";
import { ApolloServiceProvider } from "./contexts/ApolloProvider";
import { EnvironmentVariables } from "./utils/env";

setupIonicReact({
  mode: "ios",
});

const apolloUri = EnvironmentVariables.GRAPHQL_ENDPOINT_URI;

const App: React.FC = () => (
  <IonApp>
    <AuthContextProvider>
      <ApolloServiceProvider uri={apolloUri}>
        <Bootstrap />
      </ApolloServiceProvider>
    </AuthContextProvider>
  </IonApp>
);

export default App;
