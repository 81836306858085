import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonReorder,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { ADMIN_FETCH_CATEGORIES } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateCategoryDetailsPageCreationPath } from "../CategoryDetails/CategoryDetailsPage";
import { generateLessonsPagePath } from "../Lessons/LessonsPage";

import "./CategoriesPage.css";

export const VIDEO_CATEGORIES_PAGE_PATH_PATTERN = "/categories";

export function generateVideoCategoriesPagePath(): string {
  return generatePath(VIDEO_CATEGORIES_PAGE_PATH_PATTERN);
}

const CATEGORIES_PER_FETCH = 10;

const VideoCategoriesPage: React.FC = React.memo(() => {
  const [isReOrdering, setIsReOrdering] = useState(false);

  const {
    data: categoriesData,
    loading: loadingMoreCategories,
    fetchMore: fetchMoreCategories,
    error: fetchCategoriesError,
  } = useQuery(ADMIN_FETCH_CATEGORIES, {
    variables: {
      offset: 0,
      limit: CATEGORIES_PER_FETCH,
    },
  });

  useErrorHandler(fetchCategoriesError, {
    name: "fetchCategoriesError",
  });
  const handleFetchMoreCategoriesError = useImperativeErrorHandler({
    name: "fetchMoreCategories",
  });

  const categoriesList = useMemo(() => {
    console.log("data");
    return categoriesData?.adminFetchCategoriesList.data;
  }, [categoriesData]);
  const count = useMemo(
    () => categoriesData?.adminFetchCategoriesList.count,
    [categoriesData]
  );
  const allLoaded = useMemo(
    () => (!!categoriesList ? categoriesList.length >= count : false),
    [count, categoriesList]
  );

  const loadMoreData = async (ev: any) => {
    try {
      if (allLoaded) return;
      await fetchMoreCategories({
        variables: {
          offset: categoriesList.length,
        },
      });
    } catch (e) {
      handleFetchMoreCategoriesError(e);
    } finally {
      ev.target.complete();
    }
  };

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreCategories} />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonMenuButton color="primary" />
            </IonButtons>

            <IonButtons slot="primary">
              <IonButton
                color="primary"
                routerLink={generateCategoryDetailsPageCreationPath()}
              >
                增加分類
              </IonButton>
            </IonButtons>
            {/* <IonButtons slot="primary">
              <IonButton
                color="primary"
                onClick={() => {
                  setIsReOrdering(!isReOrdering);
                }}
              >
                <IonIcon
                  slot="icon-only"
                  ios={swapVerticalSharp}
                  md={swapVerticalSharp}
                />
              </IonButton>
            </IonButtons> */}
          </IonToolbar>

          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>課堂分類</h1>
              </IonCardTitle>
            </IonCardContent>
          </IonCard>

          {categoriesList &&
            categoriesList?.map((c: any, index: number) => {
              return (
                <IonItem
                  key={`category-item-${index}`}
                  detail
                  lines="none"
                  color="white"
                  routerLink={
                    isReOrdering ? undefined : generateLessonsPagePath(c.slug)
                  }
                >
                  <IonLabel className="ion-text-wrap">
                    <IonText color="dark">
                      <h2>{c.title}</h2>
                    </IonText>
                    <IonText color="medium">
                      <p>{c.lessonCount} Lessons</p>
                    </IonText>
                  </IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              );
            })}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default VideoCategoriesPage;
