import { gql } from "@apollo/client";

const LOGIN_QUERY = gql`
  mutation Login($username: String!, $password: String!) {
    login(input: { identifier: $username, password: $password }) {
      jwt
    }
  }
`;

const FETCH_ME = gql`
  query FetchMe {
    fetchMe {
      id
      username
      email
      createdAt
    }
  }
`;

const ADMIN_UPDATE_ME = gql`
  mutation AdminUpdateMe($changePassword: AdminChangePasswordInput!) {
    adminUpdateMe(changePassword: $changePassword) {
      id
      username
      email
      createdAt
    }
  }
`;

const ADMIN_FETCH_ALBUMS = gql`
  query AdminFetchAlbums($listQuery: ListQueryInput) {
    adminFetchAlbums(listQuery: $listQuery) {
      count
      current
      data {
        title
        uuid
        description
        albumCoverPhoto {
          uuid
          image {
            uuid
            readSignedUrl
          }
        }
        albumPhotosCount
      }
    }
  }
`;

const ADMIN_FETCH_ALBUM = gql`
  query AdminFetchAlbum($uuid: ID!) {
    adminFetchAlbum(uuid: $uuid) {
      title
      uuid
      description
      albumCoverPhoto {
        uuid
        image {
          uuid
          readSignedUrl
        }
      }
      albumPhotosCount
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_ALBUM = gql`
  mutation AdminMakeOrUpdateAlbum($adminInput: AlbumAdminInput!) {
    adminMakeOrUpdateAlbum(adminInput: $adminInput) {
      title
      uuid
      description
      albumCoverPhoto {
        uuid
        image {
          uuid
          readSignedUrl
        }
      }
      albumPhotosCount
    }
  }
`;

const ADMIN_MAKE_ALBUM_PHOTOS = gql`
  mutation AdminMakeAlbumPhotos(
    $albumUuid: ID!
    $albumPhotos: [AlbumPhotoAdminInput!]!
  ) {
    adminMakeAlbumPhotos(albumUuid: $albumUuid, albumPhotos: $albumPhotos)
  }
`;

const ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTO = gql`
  query AdminFetchAlbumAndAlbumPhoto($albumPhotoUuid: ID!) {
    adminFetchAlbumByAlbumPhoto(albumPhotoUuid: $albumPhotoUuid) {
      uuid
      title
      description
    }
    adminFetchAlbumPhoto(uuid: $albumPhotoUuid) {
      uuid
      alt
      image {
        uuid
        readSignedUrl
      }
    }
  }
`;

const ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS = gql`
  query AdminFetchAlbumAndAlbumPhotos(
    $albumUuid: ID!
    $listQuery: ListQueryInput
  ) {
    adminFetchAlbum(uuid: $albumUuid) {
      title
      uuid
      description
      albumCoverPhoto {
        uuid
        image {
          uuid
          readSignedUrl
        }
      }
      albumPhotosCount
    }
    adminFetchAlbumPhotos(albumUuid: $albumUuid, listQuery: $listQuery) {
      count
      current
      data {
        uuid
        alt
        image {
          readSignedUrl
        }
      }
    }
  }
`;

const ADMIN_FETCH_EMAILS = gql`
  query AdminFetchEmails(
    $orderBy: String
    $offset: Int
    $limit: Int
    $status: [String!]
  ) {
    adminFetchEmails(
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      status: $status
    ) {
      current
      count
      data {
        uuid
        title
        target
        status
        createdAt
      }
    }
  }
`;

const ADMIN_FETCH_CATEGORIES = gql`
  query AdminFetchCategoriesList($orderBy: String, $offset: Int, $limit: Int) {
    adminFetchCategoriesList(
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      count
      current
      data {
        title
        slug
        description
        createdAt
        seq
        lessonCount
      }
    }
  }
`;

const ADMIN_FETCH_CATEGORY = gql`
  query AdminFetchCategory($slug: String!) {
    adminFetchCategory(slug: $slug) {
      id
      title
      description
      lessons {
        title
        slug
        videoLength
      }
    }
  }
`;

const ADMIN_FETCH_CATEGORY_DETAILS = gql`
  query AdminFetchCategoryDetails($slug: String!) {
    adminFetchCategory(slug: $slug) {
      title
      id
      description
      thumbnail {
        url
      }
      coverImage {
        url
      }
      seq
      slug
    }
  }
`;

const ADMIN_FETCH_MEMBERS_QUERY = gql`
  query AdminFetchMembers(
    $permissionLevel: Int
    $orderBy: JSON
    $offset: Int
    $limit: Int
    $district: String
    $keyword: String
  ) {
    adminFetchMembers(
      permissionLevel: $permissionLevel
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      district: $district
      keyword: $keyword
    ) {
      count
      current
      data {
        username
        firstName
        lastName
        email
        id
        permissionLevel
        phone
        district
        createdAt
      }
    }
  }
`;

const ADMIN_FETCH_MEMBER_QUERY = gql`
  query AdminFetchMember($username: String!) {
    adminFetchMember(username: $username) {
      username
      firstName
      lastName
      email
      gender
      id
      permissionLevel
      phone
      district
      createdAt
      blocked
    }
  }
`;

const ADMIN_FETCH_LEADS_QUERY = gql`
  query AdminFetchLeads(
    $status: [String!]
    $orderBy: JSON
    $offset: Int
    $limit: Int
  ) {
    adminFetchLeads(
      status: $status
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      count
      current
      data {
        name
        uuid
        phone
        status
        createdAt
      }
    }
  }
`;

const ADMIN_FETCH_LEAD_QUERY = gql`
  query AdminFetchLead($uuid: ID!) {
    adminFetchLead(uuid: $uuid) {
      name
      email
      phone
      uuid
      status
      createdAt
      remarks
      allocatedStaff {
        username
        id
      }
    }
  }
`;

const ADMIN_MAKE_PROTECTED_FILE = gql`
  mutation AdminMakeProtectedFile(
    $contentType: String!
    $fileName: String!
    $path: String
  ) {
    adminMakeProtectedFile(
      fileName: $fileName
      contentType: $contentType
      path: $path
    ) {
      filePath
      uploadUrl
      originalFileName
      uuid
      isExist
    }
  }
`;

const ADMIN_MAKE_PROTECTED_FILES = gql`
  mutation AdminMakeProtectedFiles($adminInput: [ProtectedFileAdminInput!]!) {
    adminMakeProtectedFiles(adminInput: $adminInput) {
      filePath
      uploadUrl
      originalFileName
      uuid
      isExist
    }
  }
`;

const ADMIN_REMOVE_EMAIL = gql`
  mutation AdminRemoveEmail($uuid: ID!) {
    adminRemoveEmail(uuid: $uuid) {
      isSuccess
    }
  }
`;
const ADMIN_REMOVE_CATEGORY = gql`
  mutation AdminRemoveCategory($slug: ID!) {
    adminRemoveCategory(slug: $slug) {
      isSuccess
    }
  }
`;

const ADMIN_REMOVE_LESSON = gql`
  mutation AdminRemoveLesson($slug: ID!) {
    adminRemoveLesson(slug: $slug) {
      isSuccess
    }
  }
`;

const ADMIN_REMOVE_INCENTIVE = gql`
  mutation AdminRemoveIncentive($uuid: ID!) {
    adminRemoveIncentive(uuid: $uuid) {
      isSuccess
    }
  }
`;

const ADMIN_RENEW_PROTECTED_FILE = gql`
  mutation AdminRenewProtectedFile(
    $fileName: String!
    $contentType: String!
    $path: String
    $uuid: String!
  ) {
    adminRenewProtectedFile(
      fileName: $fileName
      contentType: $contentType
      path: $path
      uuid: $uuid
    ) {
      filePath
      uploadUrl
      originalFileName
      uuid
      isExist
    }
  }
`;

const ADMIN_CONFIRM_FILE_UPLOADED = gql`
  mutation AdminConfirmFileUploaded($uuid: ID!) {
    adminConfirmFileUploaded(uuid: $uuid) {
      filePath
      uploadUrl
      originalFileName
      uuid
      isExist
    }
  }
`;

const ADMIN_CONFIRM_FILES_UPLOADED = gql`
  mutation AdminConfirmFilesUploaded($uuids: [ID!]!) {
    adminConfirmFilesUploaded(uuids: $uuids) {
      filePath
      uploadUrl
      originalFileName
      uuid
      isExist
    }
  }
`;

const ADMIN_UPDATE_ALBUM_COVER_PHOTO = gql`
  mutation AdminUpdateAlbumCoverPhoto($albumUuid: ID!, $albumPhotoUuid: ID) {
    adminUpdateAlbumCoverPhoto(
      albumUuid: $albumUuid
      albumPhotoUuid: $albumPhotoUuid
    ) {
      title
      uuid
      description
      albumCoverPhoto {
        uuid
        image {
          uuid
          readSignedUrl
        }
      }
    }
  }
`;

const ADMIN_UPDATE_ALBUM_PHOTO = gql`
  mutation AdminUpdateAlbumPhoto($uuid: ID!, $alt: String) {
    adminUpdateAlbumPhoto(uuid: $uuid, alt: $alt) {
      uuid
      alt
      image {
        uuid
        readSignedUrl
      }
    }
  }
`;

const ADMIN_MAKE_LEAD = gql`
  mutation AdminMakeLead($adminInput: MakeLeadAdminInput!) {
    adminMakeLead(adminInput: $adminInput) {
      name
      email
      phone
      uuid
      status
      createdAt
      remarks
      allocatedStaff {
        id
        username
      }
    }
  }
`;

const ADMIN_UPDATE_LEAD = gql`
  mutation AdminUpdateLead($adminInput: UpdateLeadAdminInput!) {
    adminUpdateLead(adminInput: $adminInput) {
      name
      email
      phone
      uuid
      status
      createdAt
      remarks
      allocatedStaff {
        id
        username
      }
    }
  }
`;

const ADMIN_REMOVE_LEAD = gql`
  mutation AdminRemoveLead($uuid: ID!) {
    adminRemoveLead(uuid: $uuid) {
      isSuccess
    }
  }
`;

const ADMIN_FETCH_LESSON = gql`
  query AdminFetchLesson($slug: String!) {
    adminFetchLesson(slug: $slug) {
      id
      title
      slug
      seq
      description
      permissionLevel
      thumbnail {
        url
      }
      videoLength
      category {
        title
        slug
      }
      protectedFileVideo {
        uuid
        filePath
        isExist
      }
      quizzes {
        uuid
        title
      }
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_CATEGORY = gql`
  mutation AdminMakeOrUpdateCategory(
    $adminInput: CategoryAdminInput!
    $isUpdate: Boolean!
  ) {
    adminMakeOrUpdateCategory(adminInput: $adminInput, isUpdate: $isUpdate) {
      id
      title
      slug
      seq
      description
      thumbnail {
        url
      }
      coverImage {
        url
      }
    }
  }
`;

const ADMIN_FETCH_QUIZZIES = gql`
  query AdminFetchQuizzes($listQuery: ListQueryInput) {
    adminFetchQuizzes(listQuery: $listQuery) {
      count
      current
      data {
        title
        uuid
        lessons {
          title
        }
      }
    }
  }
`;

const ADMIN_FETCH_QUIZ = gql`
  query AdminFetchQuiz($uuid: ID!) {
    adminFetchQuiz(uuid: $uuid) {
      title
      uuid
      questions {
        seq
        content
        answerChoices
        correctAnswerValue
      }
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_LESSON = gql`
  mutation AdminMakeOrUpdateLesson(
    $adminInput: LessonAdminInput!
    $isUpdate: Boolean!
  ) {
    adminMakeOrUpdateLesson(adminInput: $adminInput, isUpdate: $isUpdate) {
      id
      title
      slug
      seq
      description
      permissionLevel
      videoLength
      category {
        slug
        title
      }
      protectedFileVideo {
        uuid
        isExist
        filePath
      }
    }
  }
`;

const ADMIN_UPDATE_OR_ADD_EMAIL = gql`
  mutation AdminUpdateOrAddEmail(
    $adminInput: EmailAdminInput!
    $sendAfterSave: Boolean
  ) {
    adminUpdateOrAddEmail(
      adminInput: $adminInput
      sendAfterSave: $sendAfterSave
    ) {
      uuid
      title
      target
      status
      content
    }
  }
`;

const ADMIN_FETCH_EMAIL = gql`
  query AdminFetchEmail($uuid: ID!) {
    adminFetchEmail(uuid: $uuid) {
      uuid
      title
      target
      status
      content
      createdAt
    }
  }
`;

const ADMIN_FETCH_ARTICLES = gql`
  query AdminFetchArticles($listQuery: ListQueryInput) {
    adminFetchArticles(listQuery: $listQuery) {
      count
      current
      data {
        uuid
        title
        status
        author
        content
        pinned
        createdAt
      }
    }
  }
`;

const ADMIN_FETCH_ARTICLE = gql`
  query AdminFetchArticle($uuid: ID!) {
    adminFetchArticle(uuid: $uuid) {
      uuid
      title
      status
      author
      content
      pinned
      createdAt
    }
  }
`;

const ADMIN_FETCH_BILLBOARD = gql`
  query AdminFetchBillBoard($startDate: String!, $endDate: String!) {
    adminFetchBillboard(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      availableYears
      billboardData {
        uuid
        submittedFYC
        name
        submittedCases
      }
    }
  }
`;

const ADMIN_MAKE_BILLBOARD = gql`
  mutation AdminMakeBillboard($adminInput: BillboardAdminInput!) {
    adminMakeBillboard(adminInput: $adminInput) {
      date
      name
      submittedFYC
      submittedCases
    }
  }
`;

const ADMIN_REMOVE_BILLBOARD = gql`
  mutation AdminRemoveBillboard($uuid: ID!) {
    adminRemoveBillboard(uuid: $uuid) {
      isSuccess
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_ARTICLE = gql`
  mutation AdminMakeOrUpdateArticle(
    $adminInput: ArticleAdminInput!
    $publishAfterSave: Boolean
  ) {
    adminMakeOrUpdateArticle(
      adminInput: $adminInput
      publishAfterSave: $publishAfterSave
    ) {
      uuid
      title
      author
      status
      content
      pinned
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_EVENT = gql`
  mutation AdminMakeOrUpdateEvent($adminInput: EventAdminInput!) {
    adminMakeOrUpdateEvent(adminInput: $adminInput) {
      uuid
      title
    }
  }
`;

const ADMIN_REMOVE_EVENT = gql`
  mutation AdminRemoveEvent($uuid: ID!) {
    adminRemoveEvent(uuid: $uuid) {
      isSuccess
      message
    }
  }
`;

const ADMIN_MAKE_MEMBER = gql`
  mutation AdminMakeMember($adminInput: MakeMemberAdminInput!) {
    adminMakeMember(adminInput: $adminInput) {
      username
      firstName
      lastName
      email
      id
      gender
      permissionLevel
      phone
      district
      createdAt
      blocked
    }
  }
`;

const ADMIN_UPDATE_MEMBER = gql`
  mutation AdminUpdateMember(
    $username: String!
    $adminInput: UpdateMemberAdminInput!
  ) {
    adminUpdateMember(username: $username, adminInput: $adminInput) {
      username
      firstName
      lastName
      email
      id
      gender
      permissionLevel
      phone
      district
      createdAt
      blocked
    }
  }
`;

const ADMIN_REMOVE_ALBUM = gql`
  mutation AdminRemoveAlbum($uuid: ID!) {
    adminRemoveAlbum(uuid: $uuid) {
      isSuccess
      message
    }
  }
`;

const ADMIN_REMOVE_ALBUM_PHOTO = gql`
  mutation AdminRemoveAlbumPhoto($uuid: ID!) {
    adminRemoveAlbumPhoto(uuid: $uuid) {
      isSuccess
      message
    }
  }
`;

const ADMIN_REMOVE_ARTICLE = gql`
  mutation AdminRemoveArticle($uuid: ID!) {
    adminRemoveArticle(uuid: $uuid) {
      isSuccess
    }
  }
`;

const ADMIN_FETCH_EVENT = gql`
  query AdminFetchEvent($uuid: ID!) {
    adminFetchEvent(uuid: $uuid) {
      uuid
      title
      location
      remarks
      startTime
      endTime
    }
  }
`;

const ADMIN_FETCH_EVENTS = gql`
  query AdminFetchEvents($listQuery: ListQueryInput) {
    adminFetchEvents(listQuery: $listQuery) {
      count
      current
      data {
        uuid
        title
        location
        remarks
        startTime
        endTime
      }
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_QUIZ = gql`
  mutation AdminMakeOrUpdateQuiz($adminInput: QuizAdminInput!) {
    adminMakeOrUpdateQuiz(adminInput: $adminInput) {
      title
      uuid
    }
  }
`;

const ADMIN_REMOVE_QUIZ = gql`
  mutation AdminRemoveQuiz($uuid: ID!) {
    adminRemoveQuiz(uuid: $uuid) {
      isSuccess
    }
  }
`;

const ADMIN_MAKE_OR_UPDATE_INCENTIVE = gql`
  mutation AdminMakeOrUpdateIncentive($adminInput: IncentiveAdminInput!) {
    adminMakeOrUpdateIncentive(adminInput: $adminInput) {
      title
      uuid
      description
      startDate
      endDate
    }
  }
`;

const ADMIN_PROTECTED_ACCESS = gql`
  query AdminProtectedAccess($uuid: ID!) {
    adminProtectedAccess(uuid: $uuid)
  }
`;

const ADMIN_FETCH_INCENTIVE = gql`
  query adminFetchIncentive($uuid: ID!) {
    adminFetchIncentive(uuid: $uuid) {
      title
      uuid
      description
      startDate
      endDate
      thumbnail {
        uuid
        filePath
        isExist
      }
      protectedFile {
        uuid
        filePath
        isExist
      }
    }
  }
`;

const ADMIN_FETCH_INCENTIVES = gql`
  query AdminFetchIncentives($listQuery: ListQueryInput) {
    adminFetchIncentives(listQuery: $listQuery) {
      count
      current
      data {
        uuid
        title
        startDate
        endDate
        description
      }
    }
  }
`;

export {
  LOGIN_QUERY,
  FETCH_ME,
  ADMIN_UPDATE_ME,
  ADMIN_FETCH_ARTICLES,
  ADMIN_FETCH_ARTICLE,
  ADMIN_MAKE_OR_UPDATE_ARTICLE,
  ADMIN_FETCH_BILLBOARD,
  ADMIN_FETCH_CATEGORY,
  ADMIN_FETCH_CATEGORIES,
  ADMIN_FETCH_CATEGORY_DETAILS,
  ADMIN_FETCH_INCENTIVE,
  ADMIN_FETCH_INCENTIVES,
  ADMIN_FETCH_ALBUM,
  ADMIN_FETCH_ALBUMS,
  ADMIN_MAKE_OR_UPDATE_ALBUM,
  ADMIN_MAKE_ALBUM_PHOTOS,
  ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTO,
  ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS,
  ADMIN_MAKE_OR_UPDATE_INCENTIVE,
  ADMIN_FETCH_MEMBERS_QUERY,
  ADMIN_FETCH_MEMBER_QUERY,
  ADMIN_FETCH_LEADS_QUERY,
  ADMIN_FETCH_LEAD_QUERY,
  ADMIN_UPDATE_ALBUM_COVER_PHOTO,
  ADMIN_UPDATE_ALBUM_PHOTO,
  ADMIN_MAKE_LEAD,
  ADMIN_UPDATE_LEAD,
  ADMIN_REMOVE_LEAD,
  ADMIN_FETCH_LESSON,
  ADMIN_FETCH_EMAILS,
  ADMIN_MAKE_PROTECTED_FILE,
  ADMIN_MAKE_PROTECTED_FILES,
  ADMIN_MAKE_BILLBOARD,
  ADMIN_CONFIRM_FILE_UPLOADED,
  ADMIN_CONFIRM_FILES_UPLOADED,
  ADMIN_RENEW_PROTECTED_FILE,
  ADMIN_MAKE_OR_UPDATE_LESSON,
  ADMIN_MAKE_OR_UPDATE_CATEGORY,
  ADMIN_MAKE_OR_UPDATE_EVENT,
  ADMIN_REMOVE_EVENT,
  ADMIN_MAKE_MEMBER,
  ADMIN_UPDATE_MEMBER,
  ADMIN_MAKE_OR_UPDATE_QUIZ,
  ADMIN_UPDATE_OR_ADD_EMAIL,
  ADMIN_FETCH_QUIZZIES,
  ADMIN_FETCH_QUIZ,
  ADMIN_FETCH_EMAIL,
  ADMIN_REMOVE_ALBUM,
  ADMIN_REMOVE_ALBUM_PHOTO,
  ADMIN_REMOVE_ARTICLE,
  ADMIN_REMOVE_BILLBOARD,
  ADMIN_REMOVE_EMAIL,
  ADMIN_REMOVE_CATEGORY,
  ADMIN_REMOVE_LESSON,
  ADMIN_REMOVE_INCENTIVE,
  ADMIN_REMOVE_QUIZ,
  ADMIN_FETCH_EVENTS,
  ADMIN_FETCH_EVENT,
  ADMIN_PROTECTED_ACCESS,
};
