import { IonButton } from "@ionic/react";
import classNames from "classnames";
import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import "./FileDropZone.css";

interface FileDropZoneProps
  extends Omit<DropzoneOptions, "noClick" | "multiple"> {}

const FileDropZone: React.FC<FileDropZoneProps> = React.memo((props) => {
  const { ...otherProps } = props;

  const { isDragActive, open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    multiple: true,
    ...otherProps,
  });

  return (
    <div
      className={classNames("file-drop-zone", {
        "drag-active": isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="file-drop-zone__content">
        <p>Drag 'n' drop some files here, or click to select files</p>
        <IonButton size="small" onClick={open}>
          Select files
        </IonButton>
      </div>
    </div>
  );
});

export default FileDropZone;
