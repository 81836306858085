import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useMemo } from "react";
import { generatePath, useParams } from "react-router-dom";
import { ADMIN_FETCH_CATEGORY } from "../../graphql/queries";
import {
  AdminFetchCategory,
  AdminFetchCategoryVariables,
} from "../../graphql/__generated__/AdminFetchCategory";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { generateVideoCategoriesPagePath } from "../Categories/CategoriesPage";
import { generateCategoryDetailsPageEditPath } from "../CategoryDetails/CategoryDetailsPage";
import { generateLessonDetailsPagePath } from "../LessonDetails/LessonDetailsPage";

import "./LessonsPage.css";

export const LESSONS_PAGE_PAGE_PATH_PATTERN = "/categories/:slug";

export function generateLessonsPagePath(slug: string): string {
  return generatePath(LESSONS_PAGE_PAGE_PATH_PATTERN, {
    slug,
  });
}

const LessonsPage: React.FC = React.memo(() => {
  const { slug } = useParams<{ slug: string }>();

  const {
    data: categoriesData,
    loading: loadingMoreCategory,
    error: fetchLessonsError,
  } = useQuery<AdminFetchCategory, AdminFetchCategoryVariables>(
    ADMIN_FETCH_CATEGORY,
    {
      variables: {
        slug,
      },
    }
  );

  useErrorHandler(fetchLessonsError, {
    name: "fetchLessonsError",
  });

  const categoryDataView = useMemo(
    () => categoriesData?.adminFetchCategory,
    [categoriesData?.adminFetchCategory]
  );

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreCategory} />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonButton
                color="primary"
                routerLink={generateVideoCategoriesPagePath()}
              >
                返回
              </IonButton>
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                routerLink={generateCategoryDetailsPageEditPath(slug)}
              >
                修改
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>{categoryDataView?.title}</h1>
              </IonCardTitle>
              <p style={{ marginTop: 12 }}>{categoryDataView?.description}</p>

              <IonButton
                color="success"
                shape="round"
                expand="block"
                style={{ marginTop: 20 }}
                routerLink={generateLessonDetailsPagePath(slug, "new")}
              >
                增加課程
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonReorderGroup disabled={true}>
            {categoryDataView?.lessons.map((l: any, index: number) => {
              return (
                <IonItem
                  key={`category-lesson-${index}`}
                  detail
                  lines="none"
                  color="white"
                  routerLink={generateLessonDetailsPagePath(slug, l.slug)}
                >
                  <IonLabel className="ion-text-wrap">
                    <IonText color="dark">
                      <h2>{`${l?.title}`}</h2>
                    </IonText>
                    <IonText color="medium">
                      <p>{l?.videoLength} sec</p>
                    </IonText>
                  </IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              );
            })}
          </IonReorderGroup>
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default LessonsPage;
