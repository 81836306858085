import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { funnelSharp } from "ionicons/icons";
import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import SortOptionsPopovers, {
  SortOptionType,
} from "../../components/SortOptions/SortOptionsPopover";
import { ADMIN_FETCH_MEMBERS_QUERY } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateMemberDetailsPagePath } from "../MemberDetail/MemberDetailsPage";
import "./MemberPage.css";

export const MEMBER_PAGE_PAGE_PATH_PATTERN = "/members";

export function generateMemberPagePath(): string {
  return generatePath(MEMBER_PAGE_PAGE_PATH_PATTERN);
}

const MEMBERS_PER_FETCH = 10;

const MemberPage: React.FC = React.memo(() => {
  const [sortingParams, setSortingParams] = useState({
    createdAt: "desc",
  });

  const [searchKeyword, setSearchKeyword] = useState("");

  const {
    data: membersData,
    loading: loadingMoreMembers,
    error: fetchMembersError,
    fetchMore: fetchMoreMembers,
  } = useQuery(ADMIN_FETCH_MEMBERS_QUERY, {
    variables: {
      keyword: searchKeyword,
      orderBy: sortingParams,
      offset: 0,
      limit: MEMBERS_PER_FETCH,
    },
  });

  useErrorHandler(fetchMembersError, {
    name: "membersData",
  });
  const handleFetchMoreMembersError = useImperativeErrorHandler({
    name: "fetchMoreMembers",
  });

  const sortOptions: SortOptionType[] = [
    { name: "Join date", value: "createdAt", default: "desc" },
    { name: "Name", value: "username" },
  ];

  const membersList = useMemo(() => {
    return membersData?.adminFetchMembers.data;
  }, [membersData]);
  const count = useMemo(
    () => membersData?.adminFetchMembers.count,
    [membersData]
  );
  const allLoaded = useMemo(
    () => (!!membersList ? membersList.length >= count : false),
    [count, membersList]
  );
  const loadMoreData = async (ev: any) => {
    try {
      if (allLoaded) return;
      await fetchMoreMembers({
        variables: {
          offset: membersList.length,
        },
      });
    } catch (e) {
      handleFetchMoreMembersError(e);
    } finally {
      ev.target.complete();
    }
  };

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreMembers} />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonMenuButton color="primary" />
            </IonButtons>

            <IonButtons slot="primary">
              <IonButton
                color="primary"
                routerLink={generateMemberDetailsPagePath("new")}
              >
                增加會員
              </IonButton>
              <IonButton color="primary" id="sort-button">
                <IonIcon slot="icon-only" ios={funnelSharp} md={funnelSharp} />
              </IonButton>
              <SortOptionsPopovers
                trigger={"sort-button"}
                options={sortOptions}
                onOptionsChange={(e: any) => {
                  setSortingParams(e);
                }}
              />
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>會員</h1>
              </IonCardTitle>

              <IonSearchbar
                placeholder="搜尋"
                onIonChange={(e) => {
                  setSearchKeyword(e.detail.value ?? "");
                }}
              ></IonSearchbar>
            </IonCardContent>
          </IonCard>

          {membersList?.map((m: any) => {
            return (
              <IonItem
                detail
                lines="none"
                color="white"
                routerLink={generateMemberDetailsPagePath(m.username)}
                key={`${m.username}`}
              >
                <IonLabel className="ion-text-wrap">
                  <IonText color="dark">
                    <h2>{m.username}</h2>
                  </IonText>
                  <IonText color="medium">
                    <p>{m.email}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
          // disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default MemberPage;
