import { useMutation } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UploadPhotoPreviewItem from "../../components/Album/UploadPhotoPreviewItem";
import FileDropZone from "../../components/Input/FileDropZone";
import { ACCEPT_IMAGE_FILE } from "../../constants/file";
import {
  ADMIN_CONFIRM_FILES_UPLOADED,
  ADMIN_MAKE_ALBUM_PHOTOS,
  ADMIN_MAKE_PROTECTED_FILES,
} from "../../graphql/queries";
import {
  AdminConfirmFilesUploaded,
  AdminConfirmFilesUploadedVariables,
} from "../../graphql/__generated__/AdminConfirmFilesUploaded";
import {
  AdminMakeAlbumPhotos,
  AdminMakeAlbumPhotosVariables,
} from "../../graphql/__generated__/AdminMakeAlbumPhotos";
import {
  AdminMakeProtectedFiles,
  AdminMakeProtectedFilesVariables,
} from "../../graphql/__generated__/AdminMakeProtectedFiles";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import { useProgressDialog } from "../../hooks/useProgressDialog";
import {
  batchUploadFilesToS3,
  calculateTotalUploadProgress,
} from "../../utils/api";

import "./AlbumImagesUploadModal.css";

interface ImageItem {
  file: File;
  caption: string;
}

interface Props {
  albumUuid: string;
  uploadPath: string;
  isShowModal: boolean;
  onDismiss: () => void;
  onUploaded: () => void;
}

const AlbumImagesUploadModal: React.FC<Props> = React.memo((props) => {
  const { albumUuid, uploadPath, isShowModal, onDismiss, onUploaded } = props;

  const presentProgressDialog = useProgressDialog();

  const [makeProtectedFiles] = useMutation<
    AdminMakeProtectedFiles,
    AdminMakeProtectedFilesVariables
  >(ADMIN_MAKE_PROTECTED_FILES);

  const [confirmFilesUploaded] = useMutation<
    AdminConfirmFilesUploaded,
    AdminConfirmFilesUploadedVariables
  >(ADMIN_CONFIRM_FILES_UPLOADED);

  const [makeAlbumPhotos] = useMutation<
    AdminMakeAlbumPhotos,
    AdminMakeAlbumPhotosVariables
  >(ADMIN_MAKE_ALBUM_PHOTOS);

  const handleUploadImagesError = useImperativeErrorHandler({
    name: "uploadImages",
  });

  const [imageItems, setImageItems] = useState<ImageItem[]>([]);

  const onModalDidDismiss = useCallback(() => {
    if (isShowModal) {
      onDismiss();
    }
  }, [isShowModal, onDismiss]);

  const onClickClose = useCallback(() => {
    onDismiss();
  }, [onDismiss]);

  const onClickUpload = useCallback(async () => {
    if (imageItems.length === 0) {
      return;
    }

    const { updateMessage, updateProgress, dismiss } = presentProgressDialog();
    updateMessage("上傳中...");

    try {
      // Create protected files
      const makeFilesResult = await makeProtectedFiles({
        variables: {
          adminInput: imageItems.map((imageItem) => ({
            fileName: imageItem.file.name,
            contentType: imageItem.file.type,
            path: uploadPath,
          })),
        },
      });
      if (!makeFilesResult.data) {
        return;
      }

      updateProgress(0.1);

      // Upload files to S3
      await batchUploadFilesToS3(
        makeFilesResult.data.adminMakeProtectedFiles.map(
          (protectedFile, index) => ({
            url: protectedFile.uploadUrl!,
            file: imageItems[index].file,
          })
        ),
        (_, progresses) => {
          const totalProgress = calculateTotalUploadProgress(progresses);
          const uploadProgress = totalProgress.loaded / totalProgress.total;
          const lowerLimit = 0.1;
          const upperLimit = 0.8;
          updateProgress(lowerLimit + (upperLimit - lowerLimit) * uploadProgress);
        }
      );
      updateProgress(0.8);

      // Confirm files is uploaded
      const confirmFilesResult = await confirmFilesUploaded({
        variables: {
          uuids: makeFilesResult.data.adminMakeProtectedFiles.map(
            (protectedFile) => protectedFile.uuid
          ),
        },
      });
      if (!confirmFilesResult.data) {
        return;
      }
      updateProgress(0.9);

      await makeAlbumPhotos({
        variables: {
          albumUuid: albumUuid,
          albumPhotos: confirmFilesResult.data.adminConfirmFilesUploaded.map(
            (protectedFile, index) => {
              // Protected files returned by confirmFilesUploaded should have same order with imageItems.
              // So it is reliable to obtain image item by index.
              const { caption } = imageItems[index];
              return {
                alt: caption !== "" ? caption : undefined,
                imageFileUuid: protectedFile.uuid,
              };
            }
          ),
        },
      });

      updateProgress(1);
      onUploaded();
      onDismiss();
    } catch (error) {
      handleUploadImagesError(error);
    } finally {
      dismiss();
    }
  }, [
    imageItems,
    presentProgressDialog,
    makeProtectedFiles,
    confirmFilesUploaded,
    makeAlbumPhotos,
    albumUuid,
    onUploaded,
    onDismiss,
    uploadPath,
    handleUploadImagesError,
  ]);

  const onDropPhotoFiles = useCallback((files: File[]) => {
    const newImageItems = files.map((file) => ({
      file,
      caption: "",
    }));
    setImageItems((prev) => [...prev, ...newImageItems]);
  }, []);

  const onDeletePhotoFileForUpload = useCallback((file: File) => {
    setImageItems((prev) => {
      const index = prev.findIndex((imageItem) => imageItem.file === file);
      if (index === -1) {
        return prev;
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  }, []);

  const photoPreviewItemListComponent = useMemo(() => {
    if (imageItems.length === 0) {
      return <span>No images</span>;
    }
    return (
      <IonRow>
        {imageItems.map((imageItem, index) => (
          <IonCol key={index} sizeLg="3" sizeMd="4" sizeSm="6" sizeXs="12">
            <UploadPhotoPreviewItem
              caption={imageItem.caption}
              file={imageItem.file}
              onDelete={onDeletePhotoFileForUpload}
              onCaptionChange={(caption) => {
                setImageItems((prev) => {
                  const newImageItems = prev.slice();
                  newImageItems[index].caption = caption;
                  return newImageItems;
                });
              }}
            />
          </IonCol>
        ))}
      </IonRow>
    );
  }, [onDeletePhotoFileForUpload, imageItems]);

  // Reset files state when closing modal
  useEffect(() => {
    if (!isShowModal) {
      setImageItems([]);
    }
  }, [isShowModal]);

  return (
    <IonModal
      className="images-upload-modal"
      isOpen={isShowModal}
      onDidDismiss={onModalDidDismiss}
    >
      <IonToolbar color="white">
        <IonButtons slot="start">
          <IonButton color="primary" onClick={onClickClose}>
            關閉
          </IonButton>
        </IonButtons>
        <IonTitle>上傳圖片</IonTitle>
        <IonButtons slot="primary">
          <IonButton
            color="primary"
            disabled={imageItems.length === 0}
            onClick={onClickUpload}
          >
            上載
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <IonList>
          <IonCard>
            <IonCardContent>
              <FileDropZone
                accept={ACCEPT_IMAGE_FILE}
                onDrop={onDropPhotoFiles}
              />
            </IonCardContent>
          </IonCard>
          <IonCard className="no-margin-card no-margin-top">
            <IonCardContent>{photoPreviewItemListComponent}</IonCardContent>
          </IonCard>
        </IonList>
      </IonContent>
    </IonModal>
  );
});

export default AlbumImagesUploadModal;
