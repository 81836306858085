import axios from "axios";

interface S3UploadItem {
  url: string;
  file: File;
}

interface UploadProgress {
  loaded: number;
  total: number;
}

export async function batchUploadFilesToS3(
  items: S3UploadItem[],
  onUploadProgress: (event: ProgressEvent, progresses: UploadProgress[]) => void
): Promise<void> {
  const progresses: UploadProgress[] = items.map(({ file }) => ({
    loaded: 0,
    total: file.size,
  }));

  await Promise.all(
    items.map(({ url, file }, index) =>
      axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (event: ProgressEvent) => {
          progresses[index] = {
            loaded: event.loaded,
            total: event.total,
          };
          onUploadProgress(event, progresses);
        },
      })
    )
  );
}

export function calculateTotalUploadProgress(
  progresses: UploadProgress[]
): UploadProgress {
  return progresses.reduce(
    (totalProgress, progress) => ({
      loaded: totalProgress.loaded + progress.loaded,
      total: totalProgress.total + progress.total,
    }),
    { loaded: 0, total: 0 }
  );
}
