import { useMutation, useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToolbar,
} from "@ionic/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import {
  ADMIN_FETCH_BILLBOARD,
  ADMIN_REMOVE_BILLBOARD,
} from "../../graphql/queries";
import {
  AdminFetchBillBoard,
  AdminFetchBillBoardVariables,
} from "../../graphql/__generated__/AdminFetchBillBoard";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateBillboardDetailsPagePath } from "../BillboardDetails/BillboardDetailsPage";
import "./BillboardsPage.css";

export const BILLBOARDS_PAGE_PATH_PATTERN = "/billboard";

export function generateBillboardsPagePath(): string {
  return generatePath(BILLBOARDS_PAGE_PATH_PATTERN);
}

const BillboardsPage: React.FC = React.memo(() => {
  const [month, setMonth] = useState(moment().format("MM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [yearSelectionRerenderKey, setYearSelectionRerenderKey] = useState(0);

  const timeRange = useMemo(() => {
    const selectedMonth = moment(`${year}-${month}`, "YYYY-MM");
    const startDate = selectedMonth.startOf("month").toISOString(true);
    const endDate = selectedMonth.endOf("month").toISOString(true);
    return {
      start: startDate,
      end: endDate,
    };
  }, [month, year]);

  const {
    data: billboardFetch,
    loading: loadingMoreBillboardData,
    error: fetchBillboardsError,
    refetch: reFetchBillboardData,
  } = useQuery<AdminFetchBillBoard, AdminFetchBillBoardVariables>(
    ADMIN_FETCH_BILLBOARD,
    {
      variables: {
        startDate: timeRange.start,
        endDate: timeRange.end,
      },
    }
  );

  const [removeBillboard, { loading: loadingRemoveBillboard }] = useMutation(
    ADMIN_REMOVE_BILLBOARD
  );

  useErrorHandler(fetchBillboardsError, {
    name: "billboardFetch",
  });

  const handleDeleteRecordError = useImperativeErrorHandler({
    name: "deleteRecord",
  });

  const billboardFetchResult = useMemo(() => {
    console.log(
      "billboardFetch?.adminFetchBillboardd",
      billboardFetch?.adminFetchBillboard
    );
    return billboardFetch?.adminFetchBillboard;
  }, [billboardFetch]);

  // Force year selection to render when availableYears changed
  useEffect(() => {
    setYearSelectionRerenderKey((prev) => prev + 1);
  }, [billboardFetchResult?.availableYears]);

  const deleteRecord = useCallback(
    async (uuid: string) => {
      try {
        await removeBillboard({
          variables: {
            uuid,
          },
        });
        await reFetchBillboardData();
      } catch (error) {
        handleDeleteRecordError(error);
      }
    },
    [reFetchBillboardData, removeBillboard, handleDeleteRecordError]
  );

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreBillboardData || loadingRemoveBillboard} />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonMenuButton color="primary" />
            </IonButtons>

            <IonButtons slot="primary">
              <IonButton color="primary" routerLink={generateBillboardDetailsPagePath()}>
                增加紀錄
              </IonButton>
            </IonButtons>
          </IonToolbar>

          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>龍虎榜</h1>
              </IonCardTitle>
            </IonCardContent>
          </IonCard>

          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonItem lines="none" color="white">
                <IonLabel>月份</IonLabel>
                <IonSelect
                  okText="Okay"
                  value={month}
                  cancelText="Dismiss"
                  onIonChange={(e) => setMonth(e.detail.value)}
                >
                  <IonSelectOption value="01">一月</IonSelectOption>
                  <IonSelectOption value="02">二月</IonSelectOption>
                  <IonSelectOption value="03">三月</IonSelectOption>
                  <IonSelectOption value="04">四月</IonSelectOption>
                  <IonSelectOption value="05">五月</IonSelectOption>
                  <IonSelectOption value="06">六月</IonSelectOption>
                  <IonSelectOption value="07">七月</IonSelectOption>
                  <IonSelectOption value="08">八月</IonSelectOption>
                  <IonSelectOption value="09">九月</IonSelectOption>
                  <IonSelectOption value="10">十月</IonSelectOption>
                  <IonSelectOption value="11">十一月</IonSelectOption>
                  <IonSelectOption value="12">十二月</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem lines="none" color="white">
                <IonLabel>年份</IonLabel>
                <IonSelect
                  key={yearSelectionRerenderKey}
                  okText="Okay"
                  cancelText="Dismiss"
                  value={year}
                  onIonChange={(e) => setYear(e.detail.value)}
                >
                  {billboardFetchResult?.availableYears?.map((y) => {
                    return (
                      <IonSelectOption key={y} value={y}>
                        {y}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </IonCardContent>
          </IonCard>
          {billboardFetchResult?.billboardData.map((b, index) => {
            return (
              <IonItemSliding key={b.uuid}>
                <IonItem lines="none" color="white" key={`item-fyc-${index}`}>
                  <IonLabel className="ion-text-wrap">
                    <IonText>
                      <h6>{b.name}</h6>
                    </IonText>
                    <IonText color="dark">
                      <p>Submitted FYC: {b.submittedFYC}</p>
                    </IonText>
                    <IonText color="medium">
                      <p>Submitted Cases: {b.submittedCases}</p>
                    </IonText>
                  </IonLabel>
                </IonItem>

                <IonItemOptions side="end">
                  <IonItemOption
                    color="danger"
                    expandable
                    onClick={() => deleteRecord(b.uuid)}
                  >
                    刪除紀錄
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            );
          })}
          {billboardFetchResult &&
            billboardFetchResult.billboardData.length === 0 && (
              <IonItem lines="none" color="medium">
                <IonLabel className="ion-text-center">
                  <IonText>No records</IonText>
                </IonLabel>
              </IonItem>
            )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default BillboardsPage;
