import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { generatePath, useHistory, useParams } from "react-router";
import {
  ADMIN_FETCH_EMAIL,
  ADMIN_FETCH_EMAILS,
  ADMIN_REMOVE_EMAIL,
  ADMIN_UPDATE_OR_ADD_EMAIL,
} from "../../graphql/queries";
import {
  AdminUpdateOrAddEmail,
  AdminUpdateOrAddEmailVariables,
} from "../../graphql/__generated__/AdminUpdateOrAddEmail";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./EmailEditPage.css";
import { evictQueryCache } from "../../utils/apollo";
import { generateEmailPagePath } from "../Email/EmailPage";

export const EMAIL_EDIT_PAGE_PAGE_PATH_PATTERN = "/emails/:uuid";

export function generateEmailEditPagePath(uuid: string): string {
  return generatePath(EMAIL_EDIT_PAGE_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const EmailEditPage: React.FC = React.memo(() => {
  const history = useHistory();
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );
  const [present] = useIonAlert();
  const apolloClient = useApolloClient();

  const [title, setTitle] = useState("");
  const [target, setTarget] = useState("");
  const [emailContent, setEmailContent] = useState(EditorState.createEmpty());

  const [submitUpdateOrAddEmail, { loading: loadingSubmitUpdateOrAddEmail }] =
    useMutation<AdminUpdateOrAddEmail, AdminUpdateOrAddEmailVariables>(
      ADMIN_UPDATE_OR_ADD_EMAIL
    );

  const handleMakeOrUpdateEmailError = useImperativeErrorHandler({
    name: "makeOrUpdateEmailError",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "Title",
          target: "Target",
          status: "Status",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const [removeEmail, { loading: loadingRemoveEmail }] =
    useMutation(ADMIN_REMOVE_EMAIL);

  const handleRemoveEmailError = useImperativeErrorHandler({
    name: "removeEmailError",
  });

  const getHtmlContent = () => {
    const contentState = emailContent.getCurrentContent();
    if (!contentState.hasText()) {
      return "";
    }
    const rawContent = convertToRaw(contentState);
    return draftToHtml(rawContent);
  };

  const {
    data: emailData,
    loading: loadingEmailData,
    error: fetchEmailError,
  } = useQuery(ADMIN_FETCH_EMAIL, {
    variables: {
      uuid,
    },
    skip: mode === "CREATE",
  });

  useErrorHandler(fetchEmailError, {
    name: "fetchEmailError",
  });

  const emailDataView = useMemo(() => {
    return emailData?.adminFetchEmail;
  }, [emailData?.adminFetchEmail]);

  useEffect(() => {
    if (!!emailDataView) {
      setTitle(emailDataView.title);
      setTarget(emailDataView.target);

      const blocksFromHtml = htmlToDraft(emailDataView.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      setEmailContent(editorState);
    }
  }, [emailDataView]);

  const saveToDraft = async (sendAfterSave: boolean = false) => {
    try {
      await submitUpdateOrAddEmail({
        variables: {
          sendAfterSave: sendAfterSave,
          adminInput: {
            content: getHtmlContent(),
            title,
            target,
            ...(mode === "CREATE"
              ? {
                  status: "draft",
                }
              : {}),

            ...(mode === "CREATE"
              ? {}
              : {
                  uuid,
                }),
          },
        },
      });

      if (mode === "CREATE") {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_EMAILS);
      }
      history.replace(generateEmailPagePath());
    } catch (e) {
      handleMakeOrUpdateEmailError(e);
    }
  };

  const deleteEmail = () => {
    present({
      header: "Delete email",
      message: "Are you sure?<br> This action cannot be undo.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeEmail({
                variables: {
                  uuid: emailDataView.uuid,
                },
              });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_EMAILS);
              history.replace(generateEmailPagePath());
            } catch (e) {
              handleRemoveEmailError(e);
            }
          },
        },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
  };

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingEmailData ||
          loadingSubmitUpdateOrAddEmail ||
          loadingRemoveEmail
        }
      />
      <IonHeader></IonHeader>
      <IonContent>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonBackButton
                color="primary"
                defaultHref={generateEmailPagePath()}
              />
            </IonButtons>

            <IonTitle></IonTitle>
            <IonButtons slot="primary">
              <IonButton color="primary" onClick={() => saveToDraft()}>
                Save
              </IonButton>

              <IonButton color="primary" onClick={() => saveToDraft(true)}>
                Save and Send
              </IonButton>
            </IonButtons>
          </IonToolbar>

          <IonCard className="no-margin-card ">
            <IonCardContent>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  Status
                </IonLabel>
                <IonInput
                  value={mode === "CREATE" ? "draft" : emailDataView?.status}
                  readonly
                />
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  Target *
                </IonLabel>
                <IonSelect
                  value={target}
                  okText="Okay"
                  cancelText="Dismiss"
                  onIonChange={(e) => {
                    setTarget(e.detail.value!);
                  }}
                >
                  <IonSelectOption value="members">Members</IonSelectOption>
                  <IonSelectOption value="leads">Subscriber</IonSelectOption>
                  <IonSelectOption value="all">all</IonSelectOption>
                </IonSelect>{" "}
              </IonItem>
              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  Title *
                </IonLabel>
                <IonInput
                  placeholder="Enter title"
                  value={title}
                  onIonChange={(e) => setTitle(e.detail.value ?? "")}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>

          <IonCard className="no-margin-card">
            <IonCardContent>
              <Editor
                editorState={emailContent}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  console.log("E", e);
                  setEmailContent(e);
                }}
              />
            </IonCardContent>
          </IonCard>

          {mode === "UPDATE" && (
            <IonCard className="no-margin-card">
              <IonCardContent>
                <IonButton
                  expand="block"
                  color="danger"
                  onClick={() => deleteEmail()}
                >
                  Delete Email
                </IonButton>
              </IonCardContent>
            </IonCard>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default EmailEditPage;
