import React from "react";
import HorizontalPaddingContainer from "./HorizontalPaddingContainer";
import MaxWidthContainer from "./MaxWidthContainer";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const ContentContainer: React.FC<Props> = React.memo((props) => {
  const { className, children } = props;

  return (
    <MaxWidthContainer className={className}>
      <HorizontalPaddingContainer>{children}</HorizontalPaddingContainer>
    </MaxWidthContainer>
  );
});

export default ContentContainer;
