import classNames from "classnames";
import React from "react";

import "./MaxWidthContainer.css";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const MaxWidthContainer: React.FC<Props> = React.memo((props) => {
  const { className, children } = props;

  return (
    <div className={classNames("max-width-container", className)}>
      {children}
    </div>
  )
});

export default MaxWidthContainer;
