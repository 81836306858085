import { IonContent, IonLabel, IonProgressBar, IonSpinner } from "@ionic/react";
import React from "react";

import "./ProgressDialog.css";

export interface ProgressDialogProps {
  progress: number;
  message?: string;
}

const ProgressDialog: React.FC<ProgressDialogProps> = React.memo((props) => {
  const { progress, message } = props;

  return (
    <IonContent className="ion-padding">
      <IonSpinner className="progress-dialog__spinner" />
      <IonLabel className="progress-dialog__label">{message}</IonLabel>
      <IonProgressBar value={progress} />
    </IonContent>
  );
});

export default ProgressDialog;
