export function updateListItem<T>(
  list: T[],
  index: number,
  updater: (item: T) => T
): T[] {
  return [
    ...list.slice(0, index),
    updater(list[index]),
    ...list.slice(index + 1),
  ];
}
