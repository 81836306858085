import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useCallback, useMemo } from "react";
import { generatePath } from "react-router-dom";
import { ADMIN_FETCH_INCENTIVES } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateIncentiveDetailsPagePath } from "../IncentiveDetails/IncentiveDetailsPage";

import "./IncentivesPage.css";

export const INCENTIVES_PAGE_PAGE_PATH_PATTERN = "/incentives";

export function generateIncentivesPagePath(): string {
  return generatePath(INCENTIVES_PAGE_PAGE_PATH_PATTERN);
}

const INCENTIVES_PER_FETCH = 10;

const IncentivesPage: React.FC = React.memo(() => {
  const {
    data: incentivesData,
    loading: loadingMoreIncentives,
    error: fetchIncentivesError,
    fetchMore: fetchMoreIncentives,
  } = useQuery(ADMIN_FETCH_INCENTIVES, {
    variables: {
      listQuery: {
        offset: 0,
        limit: INCENTIVES_PER_FETCH,
      },
    },
  });

  useErrorHandler(fetchIncentivesError, {
    name: "incentivesData",
  });
  const handleFetchMoreIncentivesError = useImperativeErrorHandler({
    name: "fetchMoreIncentives",
  });

  const incentivesList = useMemo(() => {
    return incentivesData?.adminFetchIncentives.data;
  }, [incentivesData]);

  const count = useMemo(() => {
    return incentivesData?.adminFetchIncentives.count;
  }, [incentivesData]);

  const allLoaded = useMemo(
    () => (!!incentivesData ? incentivesData.length >= count : false),
    [count, incentivesData]
  );

  const loadMoreData = useCallback(
    async (ev: any) => {
      try {
        if (!incentivesList) return;
        if (allLoaded) return;
        await fetchMoreIncentives({
          variables: {
            listQuery: {
              offset: incentivesList.length,
              limit: INCENTIVES_PER_FETCH,
            },
          },
        });
      } catch (e) {
        handleFetchMoreIncentivesError(e);
      } finally {
        ev.target.complete();
      }
    },
    [
      allLoaded,
      fetchMoreIncentives,
      handleFetchMoreIncentivesError,
      incentivesList,
    ]
  );

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreIncentives} />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>

          <IonButtons slot="primary">
            {/* <SortOptionsPopovers
                trigger={"sort-button"}
                options={sortOptions}
                onOptionsChange={(e: any) => {
                  setSortingParams(e);
                }}
              /> */}

            <IonButton
              color="primary"
              routerLink={generateIncentiveDetailsPagePath("new")}
            >
              新增激勵
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>激勵</h1>
            </IonCardTitle>
          </IonCardContent>
        </IonCard>

        <IonList>
          {incentivesList?.map((incentive: any, index: number) => {
            return (
              <IonItem
                detail
                routerLink={generateIncentiveDetailsPagePath(incentive.uuid)}
                lines="none"
                color="white"
                key={`incentive-item-${index}`}
              >
                <IonLabel className="ion-text-wrap">
                  <IonText color="dark">
                    <h2>{incentive.title}</h2>
                  </IonText>
                  <IonText color="medium">
                    <p>
                      {incentive.startDate} - {incentive.endDate}
                    </p>
                  </IonText>
                  <IonText color="medium">
                    <p>{incentive.description}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>

        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default IncentivesPage;
