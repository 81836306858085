import { useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonImg,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useParams } from "react-router";
import AlbumPhotoItem from "../../components/Album/AlbumPhotoItem";
import {
  ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS,
  ADMIN_REMOVE_ALBUM_PHOTO,
  ADMIN_UPDATE_ALBUM_COVER_PHOTO,
} from "../../graphql/queries";
import {
  AdminFetchAlbumAndAlbumPhotos,
  AdminFetchAlbumAndAlbumPhotosVariables,
} from "../../graphql/__generated__/AdminFetchAlbumAndAlbumPhotos";
import {
  AdminRemoveAlbumPhoto,
  AdminRemoveAlbumPhotoVariables,
} from "../../graphql/__generated__/AdminRemoveAlbumPhoto";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import AlbumImagesUploadModal from "../../modals/AlbumImagesUpload/AlbumImagesUploadModal";
import ContentContainer from "../../components/Container/ContentContainer";
import {
  AdminUpdateAlbumCoverPhoto,
  AdminUpdateAlbumCoverPhotoVariables,
} from "../../graphql/__generated__/AdminUpdateAlbumCoverPhoto";

import "./AlbumPhotosPage.css";
import { generateAlbumsPagePath } from "../Albums/AlbumsPage";
import { generateAlbumDetailsPagePath } from "../AlbumDetails/AlbumDetailsPage";

export const ALBUM_PHOTOS_PAGE_PATH_PATTERN = "/albums/:uuid";

export function generateAlbumPhotosPagePath(uuid: string): string {
  return generatePath(ALBUM_PHOTOS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const UPLOAD_PATH = "album-photo";
const PHOTOS_PER_FETCH = 10;

const AlbumPhotosPage = React.memo(() => {
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const [presentAlert] = useIonAlert();

  const {
    data: photosData,
    loading: loadingMoreAlbum,
    error: fetchAlbumError,
    fetchMore: fetchMorePhotos,
    refetch: refetchPhotosData,
  } = useQuery<
    AdminFetchAlbumAndAlbumPhotos,
    AdminFetchAlbumAndAlbumPhotosVariables
  >(ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS, {
    variables: {
      albumUuid: uuid,
      listQuery: {
        offset: 0,
        limit: PHOTOS_PER_FETCH,
      },
    },
  });

  const [updateAlbumCoverPhoto, { loading: updatingAlbumCoverPhoto }] =
    useMutation<
      AdminUpdateAlbumCoverPhoto,
      AdminUpdateAlbumCoverPhotoVariables
    >(ADMIN_UPDATE_ALBUM_COVER_PHOTO);

  const [removeAlbumPhoto, { loading: removingAlbumPhoto }] = useMutation<
    AdminRemoveAlbumPhoto,
    AdminRemoveAlbumPhotoVariables
  >(ADMIN_REMOVE_ALBUM_PHOTO);

  useErrorHandler(fetchAlbumError, {
    name: "photosData",
  });

  const handleUpdateAlbumCoverPhotoError = useImperativeErrorHandler({
    name: "updateAlbumCoverPhoto",
  });

  const handleRemoveAlbumPhotoError = useImperativeErrorHandler({
    name: "removeAlbumPhoto",
  });

  const handleFetchMorePhotosError = useImperativeErrorHandler({
    name: "fetchMorePhotos",
  });

  const [fetchingMorePhotos, setFetchingMorePhotos] = useState(false);
  const [albumCoverPhotoUuid, setAlbumCoverPhotoUuid] = useState<string | null>(
    null
  );
  const [openImagesUploadModal, setOpenImagesUploadModal] = useState(false);

  const photosList = useMemo(() => {
    return photosData?.adminFetchAlbumPhotos.data;
  }, [photosData]);

  const allPhotosLoaded = useMemo(() => {
    if (!photosData) {
      return false;
    }
    const { count, data } = photosData.adminFetchAlbumPhotos;
    return data.length >= count;
  }, [photosData]);

  const album = useMemo(() => {
    return photosData?.adminFetchAlbum;
  }, [photosData]);

  const onOpenImagesUploadModal = useCallback(() => {
    setOpenImagesUploadModal(true);
  }, []);

  const onDismissImagesUploadModal = useCallback(() => {
    setOpenImagesUploadModal(false);
  }, []);

  const onImagesUploaded = useCallback(() => {
    refetchPhotosData();
  }, [refetchPhotosData]);

  const onAlbumPhotoDelete = useCallback(
    (albumPhotoUuid: string) => {
      presentAlert("你確定要刪除相片嗎？", [
        {
          text: "取消",
          role: "cancel",
        },
        {
          text: "確定",
          handler: async () => {
            try {
              await removeAlbumPhoto({
                variables: {
                  uuid: albumPhotoUuid,
                },
              });
              refetchPhotosData();
            } catch (error) {
              handleRemoveAlbumPhotoError(error);
            }
          },
        },
      ]);
    },
    [
      handleRemoveAlbumPhotoError,
      presentAlert,
      refetchPhotosData,
      removeAlbumPhoto,
    ]
  );

  const onAlbumPhotoIsCoverPhotoChange = useCallback(
    async (albumPhotoUuid: string, checked: boolean) => {
      try {
        // Update local state and then update server-side data
        const newAlbumCoverPhotoUuid = checked ? albumPhotoUuid : null;
        setAlbumCoverPhotoUuid(newAlbumCoverPhotoUuid);
        await updateAlbumCoverPhoto({
          variables: {
            albumUuid: uuid,
            albumPhotoUuid: newAlbumCoverPhotoUuid,
          },
        });
      } catch (error) {
        handleUpdateAlbumCoverPhotoError(error);
      }
    },
    [handleUpdateAlbumCoverPhotoError, updateAlbumCoverPhoto, uuid]
  );

  const onLoadMore = useCallback(async () => {
    try {
      setFetchingMorePhotos(true);
      if (allPhotosLoaded || !photosList) {
        return;
      }
      await fetchMorePhotos({
        variables: {
          listQuery: {
            limit: PHOTOS_PER_FETCH,
            offset: photosList.length,
          },
        },
      });
    } catch (error) {
      handleFetchMorePhotosError(error);
    } finally {
      setFetchingMorePhotos(false);
    }
  }, [
    allPhotosLoaded,
    fetchMorePhotos,
    handleFetchMorePhotosError,
    photosList,
  ]);

  const coverPhotoComponent = useMemo(() => {
    if (!album) {
      return null;
    }
    const coverPhoto = album.albumCoverPhoto;
    if (!coverPhoto) {
      return <h3 className="ion-text-center ion-padding">未選擇封面相片</h3>;
    }
    return (
      <>
        <IonCardTitle className="ion-margin-bottom">封面相片</IonCardTitle>
        <div className="album-photos-page__cover-photo__img-container">
          <div className="album-photos-page__cover-photo__img-wrapper">
            <IonImg
              className="album-photos-page__cover-photo__img"
              src={coverPhoto.image.readSignedUrl}
            />
          </div>
        </div>
      </>
    );
  }, [album]);

  const albumPhotoItemColComponents = useMemo(() => {
    if (!photosList) {
      return;
    }
    return photosList.map((photo: any) => {
      return (
        <IonCol
          key={photo.uuid}
          sizeXl="2"
          sizeLg="3"
          sizeMd="3"
          sizeSm="4"
          sizeXs="6"
        >
          <AlbumPhotoItem
            isCoverPhoto={albumCoverPhotoUuid === photo.uuid}
            albumPhoto={photo}
            onDelete={onAlbumPhotoDelete}
            onIsCoverPhotoChange={onAlbumPhotoIsCoverPhotoChange}
          />
        </IonCol>
      );
    });
  }, [
    albumCoverPhotoUuid,
    onAlbumPhotoDelete,
    onAlbumPhotoIsCoverPhotoChange,
    photosList,
  ]);

  useEffect(() => {
    console.log("LIST", photosList);
  }, [photosList]);

  // Update albumCoverPhotoUuid state when album data changed
  useEffect(() => {
    const uuid = album?.albumCoverPhoto?.uuid;
    setAlbumCoverPhotoUuid(uuid ?? null);
  }, [album?.albumCoverPhoto?.uuid]);

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingMoreAlbum ||
          updatingAlbumCoverPhoto ||
          removingAlbumPhoto ||
          fetchingMorePhotos
        }
      />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonBackButton
                color="primary"
                defaultHref={generateAlbumsPagePath()}
              />
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                routerLink={generateAlbumDetailsPagePath(uuid)}
              >
                修改
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>{album?.title}</h1>
              </IonCardTitle>
              <p style={{ marginTop: 12 }}>{album?.description}</p>
              {album && (
                <p
                  style={{ marginTop: 12 }}
                >{`${album.albumPhotosCount}張照片`}</p>
              )}
              <IonButton
                color="success"
                shape="round"
                expand="block"
                style={{ marginTop: 20 }}
                onClick={onOpenImagesUploadModal}
              >
                新增相片
              </IonButton>
            </IonCardContent>
          </IonCard>

          <ContentContainer>
            <IonCard>
              <IonCardContent>{coverPhotoComponent}</IonCardContent>
            </IonCard>
          </ContentContainer>

          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonRow>{albumPhotoItemColComponents}</IonRow>
              {!allPhotosLoaded && photosData && (
                <IonRow
                  className="ion-justify-content-center"
                  style={{ marginTop: 20 }}
                >
                  <IonButton color="primary" shape="round" onClick={onLoadMore}>
                    載入更多相片
                  </IonButton>
                </IonRow>
              )}
            </IonCardContent>
          </IonCard>
        </IonList>
      </IonContent>

      <AlbumImagesUploadModal
        albumUuid={uuid}
        uploadPath={UPLOAD_PATH}
        isShowModal={openImagesUploadModal}
        onDismiss={onDismissImagesUploadModal}
        onUploaded={onImagesUploaded}
      />
    </IonPage>
  );
});

export default AlbumPhotosPage;
