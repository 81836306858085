import {
  DatetimeChangeEventDetail,
  IonDatetime,
  IonInput,
  useIonPopover,
  UseIonPopoverResult,
} from "@ionic/react";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./DateTimeInput.css";

type IonInputProps = React.ComponentProps<typeof IonInput>;
type IonDatetimeProps = React.ComponentProps<typeof IonDatetime>;
type IonPopoverPresentOptions = Parameters<UseIonPopoverResult[0]>[0];

interface DateTimeInputProps
  extends Omit<
    IonInputProps,
    "readonly" | "value" | "onClick" | "onChange" | "onIonChange" | "onIonInput"
  > {
  displayFormat: string;
  value: moment.Moment | undefined;
  onChange: (value: moment.Moment) => void;
  ionDatetimeProps?: Partial<
    Omit<IonDatetimeProps, "ref" | "value" | "onIonChange">
  >;
  ionPopoverPresentOptions?: Partial<IonPopoverPresentOptions>;
}

const DateTimeInput: React.FC<DateTimeInputProps> = React.memo((props) => {
  const {
    className,
    displayFormat,
    value,
    onChange,
    ionDatetimeProps,
    ionPopoverPresentOptions,
    ...otherProps
  } = props;

  const [dateTimeStringValue, setDateTimeStringValue] = useState<string>(() =>
    value !== undefined ? value.toISOString(true) : ""
  );

  const onIonDateTimeChange = useCallback((event: CustomEvent<DatetimeChangeEventDetail>) => {
    const value = event.detail.value! as string;
    setDateTimeStringValue(value);
    onChange(moment(value));
  }, [onChange]);

  const ionDateTimeComponent = useMemo(
    () => (
      <IonDatetime
        {...ionDatetimeProps}
        value={dateTimeStringValue}
        onIonChange={onIonDateTimeChange}
      />
    ),
    [ionDatetimeProps, dateTimeStringValue, onIonDateTimeChange]
  );
  const [present] = useIonPopover(ionDateTimeComponent);

  const displayText = useMemo(() => {
    if (value === undefined) {
      return "";
    }
    return value.format(displayFormat);
  }, [value, displayFormat]);

  const onClick = useCallback(() => {
    present({
      cssClass: classNames(
        "date-time-input__popover",
        ionPopoverPresentOptions?.cssClass
      ),
      ...ionPopoverPresentOptions,
    });
  }, [present, ionPopoverPresentOptions]);

  // Update dateTimeStringValue when value changed
  useEffect(() => {
    setDateTimeStringValue((prev) => {
      if (!value) {
        return "";
      }
      // Skip update dateTimeStringValue if the corresponding timestamp doesn't change
      if (moment(prev).valueOf() === value.valueOf()) {
        return prev;
      }
      return value.toISOString(true);
    });
  }, [value]);

  return (
    <IonInput
      className={classNames("date-time-input", className)}
      readonly
      value={displayText}
      onClick={onClick}
      {...otherProps}
    />
  );
});

export default DateTimeInput;
