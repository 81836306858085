import { isApolloError } from "@apollo/client";
import { StrapiErrorName } from "../constants/strapi";
import { ErrorHandlerContext } from "../hooks/useErrorHandler";
import { YupErrorFieldNameConfigMapping } from "../models/error";
import { StrapiYupValidationInnerError } from "../models/strapi-error";
import { isStrapiYupValidationErrorDetails } from "./strapi-error";
import { findStrapiGraphQLErrorExtensionsByName } from "./strapi-gql";

export function isNetworkConnectionError(error: Error): boolean {
  switch (error.message) {
    case "Failed to fetch": // Failed to fetch
    case "Load failed": // Safari
    case "NetworkError when attempting to fetch resource.": // Firefox
      return true;
    default:
      return false;
  }
}

function isIndexPathItem(pathItem: string): boolean {
  return !isNaN(parseInt(pathItem, 10));
}

export function getYupPathString(path: string[]): string {
  return path.reduce((prev, item) =>
    isIndexPathItem(item) ? `${prev}[${item}]` : `${prev}.${item}`
  );
}

export function matchYupPathPattern(path: string[], pattern: string): boolean {
  const patternItems = pattern.split(".");
  if (patternItems.length !== path.length) {
    return false;
  }
  return !patternItems.some((patternItem, index) => {
    const pathItem = path[index];
    const isIndexItem = patternItem === "[]";
    if (isIndexItem) {
      return !isIndexPathItem(pathItem);
    }
    return patternItem !== pathItem;
  });
}

function getIndexItemValues(path: string[]): number[] {
  return path
    .map((item, index) =>
      isIndexPathItem(item) ? parseInt(path[index], 10) : null
    )
    .filter((index) => index !== null) as number[];
}

export function getStrapiYupErrorMessageWithNameMapping(
  yupError: StrapiYupValidationInnerError,
  mapping: YupErrorFieldNameConfigMapping
): string {
  const fieldName = getYupPathString(yupError.path);
  const matchedItem = Object.entries(mapping)
    .map(([pattern, displayName]) => ({ pattern, displayName }))
    .find(({ pattern }) => matchYupPathPattern(yupError.path, pattern));
  const displayNameConfig = matchedItem?.displayName ?? fieldName;
  const displayName =
    typeof displayNameConfig === "function"
      ? displayNameConfig(getIndexItemValues(yupError.path))
      : displayNameConfig;
  if (yupError.message.indexOf(fieldName) === -1) {
    return `${displayName}: ${yupError.message}`;
  }
  return yupError.message.replace(fieldName, displayName);
}

export function tryHandleStrapiGraphQLYupValidationError(
  error: Error,
  ctx: ErrorHandlerContext,
  filedNameMapping: YupErrorFieldNameConfigMapping
): boolean {
  if (isApolloError(error)) {
    const extensions = findStrapiGraphQLErrorExtensionsByName(
      error.graphQLErrors,
      StrapiErrorName.VALIDATION_ERROR
    );
    if (extensions) {
      if (isStrapiYupValidationErrorDetails(extensions.error.details)) {
        const msg = extensions.error.details.errors
          .map((error) =>
            getStrapiYupErrorMessageWithNameMapping(error, filedNameMapping)
          )
          .join("\n");
        ctx.showErrorModal(msg, "Validation Error");
        return true;
      }
    }
  }
  return false;
}
