import { StrapiYupValidationErrorDetails } from "../models/strapi-error";

export function isStrapiYupValidationErrorDetails(
  details: Record<string, any>
): details is StrapiYupValidationErrorDetails {
  return (
    "errors" in details &&
    Array.isArray(details["errors"]) &&
    (details["errors"].length === 0
      ? true
      : "name" in details["errors"][0] &&
        "message" in details["errors"][0] &&
        "path" in details["errors"][0] &&
        typeof details["errors"][0]["name"] === "string" &&
        typeof details["errors"][0]["message"] === "string" &&
        Array.isArray(details["errors"][0]["path"]) &&
        typeof details["errors"][0]["path"][0] === "string")
  );
}
