import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export function useEffectSkipFirst(effect: EffectCallback, deps?: DependencyList): void {
  const isFirstRef = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
