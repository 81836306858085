import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import DateTimeInput from "../../components/Input/DateTimeInput";
import {
  ADMIN_FETCH_INCENTIVE,
  ADMIN_FETCH_INCENTIVES,
  ADMIN_MAKE_OR_UPDATE_INCENTIVE,
  ADMIN_PROTECTED_ACCESS,
  ADMIN_REMOVE_INCENTIVE,
} from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import UploadModal from "../../modals/Upload/UploadModal";
import { UploadModalFileType } from "../../modals/Upload/UploadModalFileType";
import { ProtectedFileStatusEnum } from "../../types/ProtectedFileStatus";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { generateIncentivesPagePath } from "../Incentives/IncentivesPage";

import "./IncentiveDetailsPage.css";

export const INCENTIVE_DETAILS_PAGE_PATH_PATTERN = "/incentives/:uuid";

export function generateIncentiveDetailsPagePath(uuid: string): string {
  return generatePath(INCENTIVE_DETAILS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const IncentiveDetailsPage: React.FC = React.memo(() => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );

  const {
    data: incentiveData,
    loading: loadingMoreIncentive,
    error: fetchIncentiveDetailsError,
  } = useQuery(ADMIN_FETCH_INCENTIVE, {
    variables: {
      uuid,
    },
    skip: mode === "CREATE",
  });

  const [resolveProtectedUrl, { loading: loadingResolveProtectedUrl }] =
    useLazyQuery(ADMIN_PROTECTED_ACCESS);

  const [removeIncentive, { loading: loadingRemoveIncentive }] = useMutation(
    ADMIN_REMOVE_INCENTIVE
  );

  const [
    submitMakeOrUpdateIncentive,
    { loading: loadingMakeOrUpdateIncentive },
  ] = useMutation(ADMIN_MAKE_OR_UPDATE_INCENTIVE);

  useErrorHandler(fetchIncentiveDetailsError, {
    name: "incentiveData",
  });
  const handleResolveProtectedUrlError = useImperativeErrorHandler({
    name: "resolveProtectedUrl",
  });
  const handleRemoveIncentiveError = useImperativeErrorHandler({
    name: "removeIncentive",
  });
  const handleSubmitMakeOrUpdateIncentiveError = useImperativeErrorHandler({
    name: "submitMakeOrUpdateIncentive",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "標題",
          startDate: "開始日期",
          endDate: "完結日期",
          description: "簡介",
          protectedFileUuid: "附件",
          thumbnailFileUuid: "縮圖",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const incentiveDataView = useMemo(() => {
    return incentiveData?.adminFetchIncentive;
  }, [incentiveData]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<moment.Moment | undefined>();
  const [endDate, setEndDate] = useState<moment.Moment | undefined>();
  const [selectedThumbnail, setSelectedThumbnail] = useState<any>(null);
  const [updatedProtectedFile, setUpdatedProtectedFile] = useState<any>({});
  const [updatedThumbnail, setUpdatedThumbnail] = useState<any>({});
  const [signedPreviewThumbnailUrl, setSignedPreviewThumbnailUrl] =
    useState("");
  const [openUploadAttachmentModal, setOpenUploadAttachmentModal] =
    useState(false);

  const [openUploadThumbnailModal, setOpenUploadThumbnailModal] =
    useState(false);

  const [present] = useIonAlert();

  useEffect(() => {
    if (!!incentiveDataView) {
      setTitle(incentiveDataView.title);
      setDescription(incentiveDataView.description);
      setStartDate(moment(incentiveDataView.startDate));
      setEndDate(moment(incentiveDataView.endDate));
    }
  }, [incentiveDataView]);

  const protectedFile = useMemo(
    () => incentiveDataView?.protectedFile,
    [incentiveDataView]
  );

  const thumbnail = useMemo(
    () => incentiveDataView?.thumbnail,
    [incentiveDataView]
  );

  const previewThumbnailFileUuid = useMemo(
    () => updatedThumbnail.uuid || thumbnail?.uuid,
    [thumbnail?.uuid, updatedThumbnail.uuid]
  );

  useEffect(() => {
    const fetchSignedUrl = async (protectedFileUuid: string) => {
      if (!protectedFileUuid || protectedFileUuid.length === 0) return "";

      const signedUrl = await resolveProtectedUrl({
        variables: {
          uuid: protectedFileUuid,
        },
      });
      const { data } = signedUrl;
      setSignedPreviewThumbnailUrl(data.adminProtectedAccess);
    };

    fetchSignedUrl(previewThumbnailFileUuid).catch((error) => {
      handleResolveProtectedUrlError(error);
    });
  }, [
    resolveProtectedUrl,
    handleResolveProtectedUrlError,
    previewThumbnailFileUuid,
  ]);

  const currentAttachmentStatus = useMemo(() => {
    if (!!protectedFile && !protectedFile.isExist) {
      return ProtectedFileStatusEnum.HAS_MODEL_NO_FILE;
    }
    if (!!protectedFile && protectedFile.isExist) {
      return ProtectedFileStatusEnum.HAS_MODEL_HAS_FILE;
    }
    return ProtectedFileStatusEnum.NO_MODEL_NO_FILE;
  }, [protectedFile]);

  const currentThumbnailStatus = useMemo(() => {
    if (!!thumbnail && !thumbnail.isExist) {
      return ProtectedFileStatusEnum.HAS_MODEL_NO_FILE;
    }
    if (!!thumbnail && thumbnail.isExist) {
      return ProtectedFileStatusEnum.HAS_MODEL_HAS_FILE;
    }
    return ProtectedFileStatusEnum.NO_MODEL_NO_FILE;
  }, [thumbnail]);

  const newThumbnailUploaded = useMemo(() => {
    return (
      updatedThumbnail && updatedThumbnail.uuid && updatedThumbnail.isExist
    );
  }, [updatedThumbnail]);

  const newAttachmentUploaded = useMemo(() => {
    return (
      updatedProtectedFile &&
      updatedProtectedFile.uuid &&
      updatedProtectedFile.isExist
    );
  }, [updatedProtectedFile]);

  const incentiveHasAttachment = useMemo(() => {
    return newAttachmentUploaded || protectedFile?.isExist;
  }, [newAttachmentUploaded, protectedFile]);

  const incentiveHasThumbnail = useMemo(() => {
    return newThumbnailUploaded || thumbnail?.isExist;
  }, [newThumbnailUploaded, thumbnail]);

  const deleteIncentive = () => {
    present({
      header: "Delete Incentive",
      message: "Are you sure?<br> This action cannot be undone.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeIncentive({
                variables: {
                  uuid: uuid,
                },
              });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_INCENTIVES);
              history.replace(generateIncentivesPagePath());
            } catch (error) {
              handleRemoveIncentiveError(error);
            }
          },
        },
      ],
      onDidDismiss: () => console.log("did dismiss"),
    });
  };

  const saveIncentive = async () => {
    try {
      await submitMakeOrUpdateIncentive({
        variables: {
          adminInput: {
            title,
            ...(mode === "UPDATE"
              ? {
                  uuid,
                }
              : {}),
            description,
            startDate,
            endDate,
            ...(newAttachmentUploaded
              ? {
                  protectedFileUuid: updatedProtectedFile.uuid,
                }
              : {}),
            ...(newThumbnailUploaded
              ? {
                  thumbnailFileUuid: updatedThumbnail.uuid,
                }
              : {}),
          },
        },
      });

      if (mode === "CREATE") {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_INCENTIVES);
      }
      history.replace(generateIncentivesPagePath());
    } catch (e) {
      handleSubmitMakeOrUpdateIncentiveError(e);
    }
  };

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingMoreIncentive ||
          loadingResolveProtectedUrl ||
          loadingRemoveIncentive ||
          loadingMakeOrUpdateIncentive
        }
      />
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={generateIncentivesPagePath()}
            />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={() => saveIncentive()}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonCard className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary">附件 *</IonLabel>
            </IonItem>
            {!incentiveHasAttachment ? (
              <IonButton
                expand="block"
                color="primary"
                onClick={() => setOpenUploadAttachmentModal(true)}
              >
                上傳附件
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                color="success"
                onClick={() => setOpenUploadAttachmentModal(true)}
                disabled={newAttachmentUploaded}
              >
                {newAttachmentUploaded ? "上傳成功" : "更改附件"}
              </IonButton>
            )}
          </IonCardContent>
        </IonCard>
        <IonCard className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary">縮圖 *</IonLabel>
            </IonItem>
            {incentiveHasThumbnail && signedPreviewThumbnailUrl && (
              <img
                alt="thumbnail"
                className={"incentive-thumbnail-preview"}
                src={`${signedPreviewThumbnailUrl}`}
              />
            )}

            {!incentiveHasThumbnail ? (
              <IonButton
                expand="block"
                color="primary"
                onClick={() => setOpenUploadThumbnailModal(true)}
              >
                上傳縮圖
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                color="success"
                onClick={() => setOpenUploadThumbnailModal(true)}
                disabled={newThumbnailUploaded}
              >
                {newThumbnailUploaded ? "上傳成功" : "更改縮圖"}
              </IonButton>
            )}
          </IonCardContent>
        </IonCard>

        <IonCard color="white" className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                UUID
              </IonLabel>
              <IonInput
                placeholder="-"
                value={incentiveDataView?.uuid}
                readonly
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                標題 *
              </IonLabel>
              <IonInput
                placeholder="輸入標題"
                value={title}
                onIonChange={(e) => {
                  setTitle(e.detail.value ?? "");
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                簡介
              </IonLabel>
              <IonInput
                placeholder="輸入簡介"
                value={description}
                onIonChange={(e) => {
                  setDescription(e.detail.value ?? "");
                }}
              />
            </IonItem>

            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                開始日期 *
              </IonLabel>
              <DateTimeInput
                displayFormat="YYYY/MM/DD"
                placeholder="選擇日期"
                ionDatetimeProps={{
                  max: endDate?.toISOString(true),
                  presentation: "date",
                }}
                value={startDate}
                onChange={(value) => setStartDate(value)}
              />
            </IonItem>

            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                完結日期 *
              </IonLabel>
              <DateTimeInput
                displayFormat="YYYY/MM/DD"
                placeholder="選擇日期"
                ionDatetimeProps={{
                  min: startDate?.toISOString(true),
                  presentation: "date",
                }}
                value={endDate}
                onChange={(value) => setEndDate(value)}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <UploadModal
          isShowModal={openUploadAttachmentModal}
          onUploadFinished={(file: any) => {
            console.log("Upload Success", file);
            setUpdatedProtectedFile(file);
            setOpenUploadAttachmentModal(false);
          }}
          title={"上傳附件"}
          path={"incentives/attachments"}
          fileType={UploadModalFileType.document}
          existFileUuid={protectedFile?.uuid}
          currentFileStatus={currentAttachmentStatus}
          dismissModal={() => setOpenUploadAttachmentModal(false)}
        />

        <UploadModal
          isShowModal={openUploadThumbnailModal}
          onUploadFinished={(file: any) => {
            console.log("Upload Success", file);
            setUpdatedThumbnail(file);
            setOpenUploadThumbnailModal(false);
          }}
          path={"incentives/thumbnails"}
          fileType={UploadModalFileType.image}
          title="上傳縮圖"
          existFileUuid={thumbnail?.uuid}
          currentFileStatus={currentThumbnailStatus}
          dismissModal={() => setOpenUploadThumbnailModal(false)}
        />

        {mode === "UPDATE" && (
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonButton
                expand="block"
                color="danger"
                onClick={() => {
                  deleteIncentive();
                }}
              >
                刪除激勵
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
});

export default IncentiveDetailsPage;
