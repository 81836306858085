import { useQuery } from "@apollo/client";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonLabel,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonChip,
  IonNote,
  IonMenuButton,
  IonLoading,
} from "@ionic/react";
import { pencilSharp } from "ionicons/icons";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { ADMIN_FETCH_EMAILS } from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { getEmailStatusColorClass } from "../../types/EmailStatus";
import { generateEmailEditPagePath } from "../EmailEdit/EmailEditPage";

import "./EmailPage.css";

export const EMAIL_PAGE_PAGE_PATH_PATTERN = "/emails";

export function generateEmailPagePath(): string {
  return generatePath(EMAIL_PAGE_PAGE_PATH_PATTERN);
}

const EMAILS_PER_FETCH = 10;

const EmailPage: React.FC = React.memo(() => {
  const [sentFilter, setSentFilter] = useState(false);
  const [draftFilter, setDraftFilter] = useState(false);
  const filterParam = useMemo(() => {
    const params: string[] = [];
    if (draftFilter) {
      params.push("draft");
    }

    if (sentFilter) {
      params.push("sent");
    }
    console.log("Param", params);
    return params;
  }, [draftFilter, sentFilter]);

  const {
    data: emailsData,
    loading: loadingMoreEmails,
    fetchMore: fetchMoreEmails,
    error: fetchEmailsError,
  } = useQuery(ADMIN_FETCH_EMAILS, {
    variables: {
      offset: 0,
      limit: EMAILS_PER_FETCH,
      status: filterParam,
    },
  });

  useErrorHandler(fetchEmailsError, {
    name: "fetchEmailsError",
  });
  const handleFetchMoreEmailsError = useImperativeErrorHandler({
    name: "fetchMoreEmails",
  });

  const emailsList = useMemo(() => {
    return emailsData?.adminFetchEmails.data;
  }, [emailsData]);
  const count = useMemo(() => emailsData?.adminFetchEmails.count, [emailsData]);
  const allLoaded = useMemo(
    () => (!!emailsList ? emailsList.length >= count : false),
    [count, emailsList]
  );
  const loadMoreData = useCallback(
    async (ev: any) => {
      try {
        if (!emailsList) return;
        if (allLoaded) return;
        await fetchMoreEmails({
          variables: {
            offset: emailsList.length,
          },
        });
      } catch (e) {
        handleFetchMoreEmailsError(e);
      } finally {
        ev.target.complete();
      }
    },
    [allLoaded, emailsList, fetchMoreEmails, handleFetchMoreEmailsError]
  );

  const noFilterEnabled = useMemo(() => {
    return !(sentFilter || draftFilter);
  }, [draftFilter, sentFilter]);

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreEmails} />
      <IonHeader></IonHeader>
      <IonContent fullscreen>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>

          <IonButtons slot="primary">
            <IonButton
              color="primary"
              routerLink={generateEmailEditPagePath("new")}
            >
              <IonIcon slot="icon-only" ios={pencilSharp} md={pencilSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCard className="no-margin-card no-margin-top ">
          <IonCardContent>
            <IonCardTitle>
              <h1>電郵</h1>
            </IonCardTitle>

            <IonList className="bg-white">
              <IonChip
                color={noFilterEnabled ? "danger" : "medium"}
                onClick={() => {
                  setDraftFilter(false);
                  setSentFilter(false);
                }}
              >
                <IonLabel color="dark">All</IonLabel>
              </IonChip>
              <IonChip
                color={sentFilter ? "success" : "medium"}
                onClick={() => setDraftFilter(!draftFilter)}
              >
                <IonLabel color="dark">Draft</IonLabel>
              </IonChip>
              <IonChip
                color={draftFilter ? "primary" : "medium"}
                onClick={() => setSentFilter(!sentFilter)}
              >
                <IonLabel color="dark">Sent</IonLabel>
              </IonChip>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonList>
          {emailsList?.map((e: any, index: number) => {
            return (
              <IonItem
                key={`email-list-item-${index}`}
                detail
                lines="none"
                color="white"
                routerLink={generateEmailEditPagePath(e.uuid)}
              >
                <IonLabel className="ion-text-wrap">
                  <IonText color={getEmailStatusColorClass(e.status)}>
                    <h6>{e.status}</h6>
                  </IonText>
                  <IonText color="dark">
                    <h2> {e.title}</h2>
                  </IonText>
                  <IonText color="medium">
                    <p>To: {e.target}</p>
                  </IonText>
                </IonLabel>

                <IonNote color={"medium"}>
                  {moment(e.createdAt).format("MMM DD")}
                </IonNote>
              </IonItem>
            );
          })}
        </IonList>

        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default EmailPage;
