import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  QueryTuple,
  TypedDocumentNode,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery as useOriginalLazyQuery,
} from "@apollo/client";
import { useCallback, useRef } from "react";
import { useRefToValueBinding } from "./useRefToValueBinding";

type Options<TData, TVariables> = Omit<LazyQueryHookOptions<TData, TVariables>, "errorPolicy">;

/**
 * This hook is a wrapper of useLazyQuery from @apollo/client.
 * It fixes that fetch function doesn't throw error when partial data returned from server.
 */
export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Options<TData, TVariables>,
): QueryTuple<TData, TVariables> {
  const [_fetch, result] = useOriginalLazyQuery(query, {
    ...options,
    errorPolicy: "none",
  });

  const resultRef = useRef(result);
  useRefToValueBinding(resultRef, result);

  const fetch = useCallback(async (
    options?: QueryLazyOptions<TVariables>,
  ): Promise<LazyQueryResult<TData, TVariables>> => {
    const newResult = await _fetch(options);

    // Throw error from resultRef if error exists.
    // As result returned from fetch function doesn't include error when partial data returned from server.
    if (resultRef.current.error) {
      throw resultRef.current.error;
    }

    // Returns result if no error
    return newResult;
  }, [_fetch]);

  return [fetch, result];
}
