import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import {
  ADMIN_FETCH_ALBUM,
  ADMIN_FETCH_ALBUMS,
  ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS,
  ADMIN_MAKE_OR_UPDATE_ALBUM,
  ADMIN_REMOVE_ALBUM,
} from "../../graphql/queries";
import {
  AdminMakeOrUpdateAlbum,
  AdminMakeOrUpdateAlbumVariables,
} from "../../graphql/__generated__/AdminMakeOrUpdateAlbum";
import {
  AdminRemoveAlbum,
  AdminRemoveAlbumVariables,
} from "../../graphql/__generated__/AdminRemoveAlbum";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { generateAlbumPhotosPagePath } from "../AlbumPhotosPage/AlbumPhotosPage";
import { generateAlbumsPagePath } from "../Albums/AlbumsPage";
import "./AlbumDetailsPage.css";

export const ALBUM_DETAILS_PAGE_PATH_PATTERN = "/albums/:uuid/edit";

export function generateAlbumDetailsPagePath(uuid: string): string {
  return generatePath(ALBUM_DETAILS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const AlbumDetailsPage: React.FC = React.memo(() => {
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const [present] = useIonAlert();
  const apolloClient = useApolloClient();
  const location = useLocation();
  const history = useHistory();
  const mode = useMemo<"CREATE" | "UPDATE">(
    () => (uuid && uuid === "new" ? "CREATE" : "UPDATE"),
    [uuid]
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const {
    data: albumData,
    loading: loadingMoreAlbum,
    error: fetchAlbumError,
  } = useQuery(ADMIN_FETCH_ALBUM, {
    variables: {
      uuid,
    },
    skip: mode === "CREATE",
  });

  const [submitMakeOrUpdateAlbum, { loading: loadingMakeOrUpdateAlbum }] =
    useMutation<AdminMakeOrUpdateAlbum, AdminMakeOrUpdateAlbumVariables>(
      ADMIN_MAKE_OR_UPDATE_ALBUM
    );

  const [removeAlbum, { loading: loadingRemoveAlbum }] = useMutation<
    AdminRemoveAlbum,
    AdminRemoveAlbumVariables
  >(ADMIN_REMOVE_ALBUM);

  useErrorHandler(fetchAlbumError, {
    name: "albumData",
  });

  const handleSaveAlbumError = useImperativeErrorHandler({
    name: "saveAlbum",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "標題",
          description: "簡介",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const handleRemoveAlbumError = useImperativeErrorHandler({
    name: "removeAlbum",
  });

  const albumDataView = useMemo(() => {
    return albumData?.adminFetchAlbum;
  }, [albumData]);

  useEffect(() => {
    if (!!albumDataView) {
      setTitle(albumDataView.title);
      setDescription(albumDataView.description);
    }
  }, [albumDataView]);

  const saveAlbum = useCallback(async () => {
    try {
      const result = await submitMakeOrUpdateAlbum({
        variables: {
          adminInput: {
            title,
            description,

            ...(mode === "UPDATE"
              ? {
                  uuid,
                }
              : {}),
          },
        },
      });
      if (!result.data) {
        return;
      }

      if (mode === "UPDATE") {
        history.replace(generateAlbumPhotosPagePath(uuid));
      } else {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_ALBUMS);
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS);
        history.replace(
          generateAlbumPhotosPagePath(result.data.adminMakeOrUpdateAlbum.uuid)
        );
      }
    } catch (e) {
      handleSaveAlbumError(e);
    }
  }, [
    apolloClient.cache,
    description,
    handleSaveAlbumError,
    history,
    mode,
    submitMakeOrUpdateAlbum,
    title,
    uuid,
  ]);

  const deleteAlbum = useCallback(() => {
    if (mode !== "UPDATE") {
      return;
    }
    present({
      header: "刪除相集",
      message: "您確定嗎？<br>刪除後將無法復原。",
      buttons: [
        "取消",
        {
          text: "刪除",
          role: "destructive",
          handler: async () => {
            try {
              await removeAlbum({
                variables: {
                  uuid: uuid,
                },
              });
              evictQueryCache(apolloClient.cache, ADMIN_FETCH_ALBUMS);
              evictQueryCache(
                apolloClient.cache,
                ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS
              );
              history.replace(generateAlbumsPagePath());
            } catch (error) {
              handleRemoveAlbumError(error);
            }
          },
        },
      ],
      onDidDismiss: () => console.log("did dismiss"),
    });
  }, [
    mode,
    present,
    removeAlbum,
    uuid,
    apolloClient.cache,
    history,
    handleRemoveAlbumError,
  ]);

  return (
    <IonPage>
      <IonLoading
        isOpen={
          loadingMoreAlbum || loadingMakeOrUpdateAlbum || loadingRemoveAlbum
        }
      />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonBackButton
                color="primary"
                defaultHref={
                  mode === "CREATE"
                    ? generateAlbumsPagePath()
                    : generateAlbumPhotosPagePath(uuid)
                }
              />
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton color="primary" onClick={() => saveAlbum()}>
                儲存
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>相集</h1>
              </IonCardTitle>
            </IonCardContent>
          </IonCard>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              {mode === "UPDATE" && (
                <IonItem lines="none" color="white">
                  <IonLabel color="primary" position="stacked">
                    UUID
                  </IonLabel>
                  <IonInput value={uuid} readonly></IonInput>
                </IonItem>
              )}

              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  標題 *
                </IonLabel>
                <IonInput
                  value={title}
                  onIonChange={(e) => {
                    setTitle(e.detail.value ?? "");
                  }}
                  placeholder="請輸入標題"
                ></IonInput>
              </IonItem>

              <IonItem lines="none" color="white">
                <IonLabel color="primary" position="stacked">
                  簡介
                </IonLabel>
                <IonInput
                  value={description}
                  placeholder="請輸入簡介"
                  onIonChange={(e) => {
                    setDescription(e.detail.value ?? "");
                  }}
                ></IonInput>
              </IonItem>
            </IonCardContent>
          </IonCard>
          {mode === "UPDATE" && (
            <IonCard className="no-margin-card">
              <IonCardContent>
                <IonButton expand="block" color="danger" onClick={deleteAlbum}>
                  刪除相集
                </IonButton>
              </IonCardContent>
            </IonCard>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default AlbumDetailsPage;
