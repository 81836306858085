export enum ArticleStatus {
  published = "published",
  draft = "draft",
}

const getArticleStatus18nText = (status?: ArticleStatus) => {
  switch (status) {
    case ArticleStatus.published:
      return "已發佈";
    case ArticleStatus.draft:
      return "草稿";

    default:
      return "";
  }
};

const getArticleStatusColor = (status?: ArticleStatus) => {
  switch (status) {
    case ArticleStatus.published:
      return "success";
    case ArticleStatus.draft:
      return "danger";

    default:
      return "dark";
  }
};

export { getArticleStatus18nText, getArticleStatusColor };
