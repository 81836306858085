import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import {
  ADMIN_FETCH_CATEGORIES,
  ADMIN_FETCH_CATEGORY_DETAILS,
  ADMIN_MAKE_OR_UPDATE_CATEGORY,
  ADMIN_REMOVE_CATEGORY,
} from "../../graphql/queries";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { evictQueryCache } from "../../utils/apollo";
import { tryHandleStrapiGraphQLYupValidationError } from "../../utils/error";
import { generateVideoCategoriesPagePath } from "../Categories/CategoriesPage";

import "./CategoryDetailsPage.css";

export const CATEGORY_DETAILS_PAGE_CREATION_PATH_PATTERN = "/add-category";
export const CATEGORY_DETAILS_PAGE_EDIT_PATH_PATTERN = "/edit-category/:slug";

export function generateCategoryDetailsPageCreationPath(): string {
  return generatePath(CATEGORY_DETAILS_PAGE_CREATION_PATH_PATTERN);
}

export function generateCategoryDetailsPageEditPath(slug: string): string {
  return generatePath(CATEGORY_DETAILS_PAGE_EDIT_PATH_PATTERN, {
    slug,
  });
}

const CategoryDetailsPage: React.FC = React.memo(() => {
  const history = useHistory();
  const { slug } = useParams<{
    slug: string;
  }>();
  const location = useLocation();
  const apolloClient = useApolloClient();

  const { pathname } = location;
  const mode = useMemo<"CREATE" | "UPDATE">(
    () =>
      pathname && pathname === generateCategoryDetailsPageCreationPath()
        ? "CREATE"
        : "UPDATE",
    [pathname]
  );

  const {
    data: categoryDetailsData,
    loading: loadingMoreCategoryDetails,
    error: fetchCategoryDetailsError,
  } = useQuery(ADMIN_FETCH_CATEGORY_DETAILS, {
    variables: {
      slug,
    },
    skip: mode === "CREATE",
  });

  useErrorHandler(fetchCategoryDetailsError, {
    name: "fetchCategoryDetailsError",
  });

  const [submitMakeOrUpdateCategory, { loading: loadingMakeOrUpdateCategory }] =
    useMutation(ADMIN_MAKE_OR_UPDATE_CATEGORY);

  const handleSaveCategoryError = useImperativeErrorHandler({
    name: "saveCategory",
    errorHandler(error, ctx) {
      if (
        tryHandleStrapiGraphQLYupValidationError(error, ctx, {
          title: "Title",
          slug: "Slug",
          seq: "Seq",
          description: "Introduction",
          thumbnailFile: "Thumbnail",
          coverImageFile: "Cover image",
        })
      ) {
        return true;
      }
      return false;
    },
  });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [updateSlug, setUpdateSlug] = useState("");
  const [selectedThumbnail, setSelectedThumbnail] = useState<any>(null);
  const [selectedCoverImage, setselectedCoverImage] = useState<any>(null);
  const [seq, setSeq] = useState(0);
  const [present] = useIonAlert();

  const selectedThumbnailObjectUrl = useMemo(() => {
    if (selectedThumbnail) {
      return URL.createObjectURL(selectedThumbnail);
    }
    return "";
  }, [selectedThumbnail]);

  const selectedCoverImageObjectUrl = useMemo(() => {
    if (selectedCoverImage) {
      return URL.createObjectURL(selectedCoverImage);
    }
    return "";
  }, [selectedCoverImage]);

  const categoryDataView = useMemo<any>(() => {
    return categoryDetailsData?.adminFetchCategory;
  }, [categoryDetailsData?.adminFetchCategory]);

  useEffect(() => {
    if (!!categoryDataView) {
      setTitle(categoryDataView.title);
      setDescription(categoryDataView.description);
      setUpdateSlug(categoryDataView.slug);
      setSeq(categoryDataView.seq);
    }
  }, [categoryDataView]);

  const [removeCategory, { loading: loadingRemoveCategory }] = useMutation(
    ADMIN_REMOVE_CATEGORY
  );
  const handleRemoveCategoryError = useImperativeErrorHandler({
    name: "removeCategory",
  });

  const deleteCategory = useCallback(() => {
    if (!categoryDataView) {
      return;
    }
    present({
      header: "Delete Lesson",
      message: "Are you sure?<br> This action cannot be undone.",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            try {
              await removeCategory({
                variables: {
                  slug: categoryDataView.slug,
                },
              });

              evictQueryCache(apolloClient.cache, ADMIN_FETCH_CATEGORIES);
              history.replace(generateVideoCategoriesPagePath());
            } catch (error) {
              handleRemoveCategoryError(error);
            }
          },
        },
      ],
      onDidDismiss: () => console.log("did dismiss"),
    });
  }, [
    categoryDataView,
    present,
    removeCategory,
    apolloClient.cache,
    history,
    handleRemoveCategoryError,
  ]);

  const saveCategory = useCallback(async () => {
    try {
      await submitMakeOrUpdateCategory({
        variables: {
          isUpdate: mode === "UPDATE",
          adminInput: {
            id: categoryDataView?.id,
            title,
            slug: updateSlug,
            description,
            seq,
            ...(selectedThumbnail
              ? {
                  thumbnailFile: selectedThumbnail,
                }
              : {}),
            ...(selectedCoverImage
              ? {
                  coverImageFile: selectedCoverImage,
                }
              : {}),
          },
        },
      });

      if (mode === "CREATE") {
        evictQueryCache(apolloClient.cache, ADMIN_FETCH_CATEGORIES);
      }
      history.replace(generateVideoCategoriesPagePath());
    } catch (e) {
      handleSaveCategoryError(e);
    }
  }, [
    submitMakeOrUpdateCategory,
    mode,
    categoryDataView?.id,
    title,
    updateSlug,
    description,
    seq,
    selectedThumbnail,
    selectedCoverImage,
    history,
    apolloClient.cache,
    handleSaveCategoryError,
  ]);

  return (
    <IonPage>
      <IonLoading
        isOpen={loadingMoreCategoryDetails || loadingMakeOrUpdateCategory}
      />
      <IonContent>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={generateVideoCategoriesPagePath()}
            />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={saveCategory}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonCard className="no-margin-card">
          <IonCardContent>
            <IonItem lines="none" color="white">
              {selectedThumbnail ? (
                <>
                  <IonLabel color="success" position="stacked">
                    更改類別縮圖
                  </IonLabel>
                  <img alt="thumbnail" src={`${selectedThumbnailObjectUrl}`} />
                </>
              ) : (
                categoryDataView?.thumbnail?.url && (
                  <img
                    alt="thumbnail"
                    src={`${categoryDataView?.thumbnail?.url}`}
                  />
                )
              )}

              <IonLabel color="primary" position="stacked">
                選擇縮圖 *
              </IonLabel>
              <input
                accept="image/*"
                onChange={(e) => {
                  setSelectedThumbnail(e.target.files![0]);
                }}
                type="file"
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <IonCard className="no-margin-card">
          <IonCardContent>
            <IonItem lines="none" color="white">
              {selectedCoverImage ? (
                <>
                  <IonLabel color="success" position="stacked">
                    Updated category cover image
                  </IonLabel>
                  <img alt="thumbnail" src={`${selectedCoverImageObjectUrl}`} />
                </>
              ) : (
                categoryDataView?.coverImage?.url && (
                  <img
                    alt="thumbnail"
                    src={`${categoryDataView?.coverImage?.url}`}
                  />
                )
              )}

              <IonLabel color="primary" position="stacked">
                Select your cover image *
              </IonLabel>
              <input
                accept="image/*"
                onChange={(e) => {
                  setselectedCoverImage(e.target.files![0]);
                }}
                type="file"
              />
            </IonItem>
          </IonCardContent>
        </IonCard>

        <IonCard color="white" className="no-margin-card ">
          <IonCardContent>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Title *
              </IonLabel>
              <IonInput
                placeholder="Enter title"
                value={title}
                onIonChange={(e) => {
                  setTitle(e.detail.value ?? "");
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Slug *
              </IonLabel>
              <IonInput
                placeholder="Enter slug"
                value={updateSlug}
                onIonChange={(e) => {
                  setUpdateSlug(e.detail.value ?? "");
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Sequence *
              </IonLabel>
              <IonInput
                placeholder="Enter sequence"
                value={seq}
                type="number"
                onIonChange={(e) => {
                  setSeq(
                    !!e.detail.value ? parseFloat(e.detail.value ?? "0") : 0
                  );
                }}
              />
            </IonItem>
            <IonItem lines="none" color="white">
              <IonLabel color="primary" position="stacked">
                Introduction
              </IonLabel>
              <IonInput
                placeholder="Enter introduction"
                value={description}
                onIonChange={(e) => {
                  setDescription(e.detail.value ?? "");
                }}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
        {mode === "UPDATE" && (
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonButton expand="block" color="danger" onClick={deleteCategory}>
                Delete Category
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
});

export default CategoryDetailsPage;
