import { useMutation } from "@apollo/client";
import {
  IonPage,
  IonContent,
  IonButton,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonCardTitle,
  IonLoading,
} from "@ionic/react";
import React, { useCallback, useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { LOGIN_QUERY } from "../../graphql/queries";
import { Login, LoginVariables } from "../../graphql/__generated__/Login";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import { generateMemberPagePath } from "../Member/MemberPage";
import "./LoginPage.css";

export const LOGIN_PAGE_PATH_PATTERN = "/login";

export function generateLoginPagePath(): string {
  return generatePath(LOGIN_PAGE_PATH_PATTERN);
}

const LoginPage: React.FC = React.memo(() => {
  const auth = useAuth();
  const history = useHistory();

  const handleLoginError = useImperativeErrorHandler(
    useMemo(
      () => ({
        name: "login",
      }),
      []
    )
  );

  const [loginQuery, { loading }] = useMutation<Login, LoginVariables>(
    LOGIN_QUERY
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = useCallback(() => {
    loginQuery({ variables: { username, password } })
      .then((result) => {
        if (!result.data) {
          return;
        }
        const { jwt } = result.data.login;
        if (jwt !== null) {
          auth.login(jwt);
          history.replace(generateMemberPagePath());
        }
      })
      .catch((error) => {
        handleLoginError(error);
      });
  }, [auth, handleLoginError, history, loginQuery, password, username]);

  return (
    <IonPage>
      <IonLoading isOpen={loading} />
      <IonContent>
        <div className={"login-card-container"}>
          <IonCard className="no-shadow login-card">
            <IonCardContent>
              <IonCardTitle>
                <h1>登入</h1>
              </IonCardTitle>
              <IonItem color="white" lines="none">
                <IonLabel position="stacked">用戶名稱</IonLabel>
                <IonInput
                  placeholder="Enter username"
                  onIonChange={(e) => setUsername(e.detail.value ?? "")}
                  value={username}
                />
              </IonItem>

              <IonItem color="white" lines="none">
                <IonLabel position="stacked">密碼</IonLabel>
                <IonInput
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value ?? "")}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>

          <IonCard className="no-shadow login-card">
            <IonCardContent>
              <IonButton
                color="primary"
                expand={"block"}
                onClick={() => login()}
              >
                登入
              </IonButton>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
});

export default LoginPage;
