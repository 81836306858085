import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  videocamSharp,
  mailSharp,
  settingsSharp,
  peopleSharp,
  micSharp,
  calendarSharp,
  rocketSharp,
  newspaperSharp,
  clipboardSharp,
  bookSharp,
  albumsSharp,
  logOutSharp,
} from "ionicons/icons";
import React, { useCallback } from "react";
import { Redirect, Route, useHistory } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import CategoriesPage, {
  generateVideoCategoriesPagePath,
  VIDEO_CATEGORIES_PAGE_PATH_PATTERN,
} from "../pages/Categories/CategoriesPage";
import CategoryDetailsPage, {
  CATEGORY_DETAILS_PAGE_CREATION_PATH_PATTERN,
  CATEGORY_DETAILS_PAGE_EDIT_PATH_PATTERN,
} from "../pages/CategoryDetails/CategoryDetailsPage";
import EmailPage, {
  EMAIL_PAGE_PAGE_PATH_PATTERN,
  generateEmailPagePath,
} from "../pages/Email/EmailPage";
import EmailEditPage, {
  EMAIL_EDIT_PAGE_PAGE_PATH_PATTERN,
} from "../pages/EmailEdit/EmailEditPage";
import LeadPage, {
  generateLeadPagePath,
  LEAD_PAGE_PAGE_PATH_PATTERN,
} from "../pages/Lead/LeadPage";
import LeadDetailsPage, {
  LEAD_DETAILS_PAGE_PAGE_PATH_PATTERN,
} from "../pages/LeadDetails/LeadDetailsPage";
import LessonsPage, {
  LESSONS_PAGE_PAGE_PATH_PATTERN,
} from "../pages/Lessons/LessonsPage";
import MemberPage, {
  generateMemberPagePath,
  MEMBER_PAGE_PAGE_PATH_PATTERN,
} from "../pages/Member/MemberPage";
import MemberDetailsPage, {
  MEMBER_DETAILS_PAGE_PAGE_PATH_PATTERN,
} from "../pages/MemberDetail/MemberDetailsPage";
import LessonDetailsPage, {
  LESSON_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/LessonDetails/LessonDetailsPage";
import LoginPage, {
  generateLoginPagePath,
  LOGIN_PAGE_PATH_PATTERN,
} from "../pages/Login/LoginPage";
import SettingPage, {
  generateSettingPagePath,
  SETTING_PAGE_PAGE_PATH_PATTERN,
} from "../pages/Setting/SettingPage";
import EventsPage, {
  EVENTS_PAGE_PATH_PATTERN,
  generateEventsPagePath,
} from "../pages/Events/EventsPage";
import EventDetailsPage, {
  EVENT_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/EventDetails/EventDetailsPage";
import BillboardsPage, {
  BILLBOARDS_PAGE_PATH_PATTERN,
  generateBillboardsPagePath,
} from "../pages/Billboards/BillboardsPage";

import "./Bootstrap.css";
import IncentivesPage, {
  generateIncentivesPagePath,
  INCENTIVES_PAGE_PAGE_PATH_PATTERN,
} from "../pages/Incentives/IncentivesPage";
import ArticlesPage, {
  ARTICLES_PAGE_PATH_PATTERN,
  generateArticlesPagePath,
} from "../pages/Articles/ArticlesPage";
import ArticleDetailsPage, {
  ARTICLE_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/ArticleDetails/ArticleDetailsPage";
import BillboardDetailsPage, {
  BILLBOARD_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/BillboardDetails/BillboardDetailsPage";
import QuizzesPage, {
  generateQuizzesPagePath,
  QUIZZES_PAGE_PAGE_PATH_PATTERN,
} from "../pages/Quizzes/QuizzesPage";
import QuizDetailsPage, {
  QUIZ_DETAILS_PAGE_PAGE_PATH_PATTERN,
} from "../pages/QuizDetails/QuizDetailsPage";
import IncentiveDetailsPage, {
  INCENTIVE_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/IncentiveDetails/IncentiveDetailsPage";
import AlbumsPage, {
  ALBUMS_PAGE_PATH_PATTERN,
  generateAlbumsPagePath,
} from "../pages/Albums/AlbumsPage";
import AlbumDetailsPage, {
  ALBUM_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/AlbumDetails/AlbumDetailsPage";
import AlbumPhotosPage, {
  ALBUM_PHOTOS_PAGE_PATH_PATTERN,
} from "../pages/AlbumPhotosPage/AlbumPhotosPage";
import AlbumPhotoDetailsPage, {
  ALBUM_PHOTO_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/AlbumPhotoDetails/AlbumPhotoDetailsPage";

const AuthorizedRoutesWrapper: React.FC = React.memo((props) => {
  const { children } = props;

  const auth = useAuth();

  if (!auth.isLoggedIn) {
    return <Redirect to={generateLoginPagePath()} />;
  }

  return <>{children}</>;
});

const Bootstrap: React.FC = React.memo(() => {
  const auth = useAuth();
  const history = useHistory();

  const logout = useCallback(() => {
    auth.logout();
    //   history.push("/");
  }, [auth]);

  const appPages = [
    {
      title: "相集",
      url: generateAlbumsPagePath(),
      icon: albumsSharp,
    },
    {
      title: "消息",
      url: generateArticlesPagePath(),
      icon: newspaperSharp,
    },
    {
      title: "龍虎榜",
      url: generateBillboardsPagePath(),
      icon: clipboardSharp,
    },
    {
      title: "成員",
      url: generateMemberPagePath(),
      icon: peopleSharp,
    },
    {
      title: "機會",
      url: generateLeadPagePath(),
      icon: micSharp,
    },
    {
      title: "課程",
      url: generateVideoCategoriesPagePath(),
      icon: videocamSharp,
    },
    {
      title: "電郵",
      url: generateEmailPagePath(),
      icon: mailSharp,
    },
    {
      title: "活動",
      url: generateEventsPagePath(),
      icon: calendarSharp,
    },
    {
      title: "激勵",
      url: generateIncentivesPagePath(),
      icon: rocketSharp,
    },
    {
      title: "測驗",
      url: generateQuizzesPagePath(),
      icon: bookSharp,
    },
    {
      title: "設定",
      url: generateSettingPagePath(),
      icon: settingsSharp,
    },
  ];

  return (
    <IonReactRouter>
      <>
        <IonSplitPane contentId="main" when="lg" className="split-pane">
          <IonMenu type="overlay" contentId="main" className="side-menu">
            <IonHeader>
              <IonToolbar color="primary" className="side-menu-toolbar">
                <IonTitle>Prosperity</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonList className="side-menu-list">
              <IonMenuToggle autoHide={false}>
                {appPages.map((p) => {
                  return (
                    <IonItem
                      routerLink={p.url}
                      key={`menu-${p.title}`}
                      disabled={!auth.isLoggedIn}
                      lines="none"
                    >
                      {p.title}
                    </IonItem>
                  );
                })}

                {auth.isLoggedIn ? (
                  <IonItem
                    onClick={logout}
                    key={`menu-logout`}
                    color="danger"
                    className="side-menu-logout-button"
                  >
                    登出
                    <IonIcon icon={logOutSharp} slot="end" />
                  </IonItem>
                ) : (
                  <IonItem
                    key={`menu-logout`}
                    color="primary"
                    className="side-menu-logout-button"
                    disabled={true}
                  >
                    請先登入
                  </IonItem>
                )}
              </IonMenuToggle>
            </IonList>
          </IonMenu>

          <IonRouterOutlet id="main">
            <Route exact path={LOGIN_PAGE_PATH_PATTERN}>
              <LoginPage />
            </Route>
            <AuthorizedRoutesWrapper>
              <Route exact path={ALBUMS_PAGE_PATH_PATTERN}>
                <AlbumsPage />
              </Route>
              <Route exact path={ALBUM_PHOTOS_PAGE_PATH_PATTERN}>
                <AlbumPhotosPage />
              </Route>
              <Route exact path={ALBUM_PHOTO_DETAILS_PAGE_PATH_PATTERN}>
                <AlbumPhotoDetailsPage />
              </Route>
              <Route exact path={ALBUM_DETAILS_PAGE_PATH_PATTERN}>
                <AlbumDetailsPage />
              </Route>
              <Route exact path={ARTICLES_PAGE_PATH_PATTERN}>
                <ArticlesPage />
              </Route>
              <Route exact path={ARTICLE_DETAILS_PAGE_PATH_PATTERN}>
                <ArticleDetailsPage />
              </Route>
              <Route exact path={BILLBOARDS_PAGE_PATH_PATTERN}>
                <BillboardsPage />
              </Route>
              <Route exact path={BILLBOARD_DETAILS_PAGE_PATH_PATTERN}>
                <BillboardDetailsPage />
              </Route>
              <Route exact path={LESSON_DETAILS_PAGE_PATH_PATTERN}>
                <LessonDetailsPage />
              </Route>
              <Route exact path={VIDEO_CATEGORIES_PAGE_PATH_PATTERN}>
                <CategoriesPage />
              </Route>
              <Route exact path={EVENTS_PAGE_PATH_PATTERN}>
                <EventsPage />
              </Route>
              <Route exact path={EVENT_DETAILS_PAGE_PATH_PATTERN}>
                <EventDetailsPage />
              </Route>
              <Route exact path={INCENTIVES_PAGE_PAGE_PATH_PATTERN}>
                <IncentivesPage />
              </Route>
              <Route exact path={INCENTIVE_DETAILS_PAGE_PATH_PATTERN}>
                <IncentiveDetailsPage />
              </Route>
              <Route exact path={CATEGORY_DETAILS_PAGE_CREATION_PATH_PATTERN}>
                <CategoryDetailsPage />
              </Route>
              <Route exact path={CATEGORY_DETAILS_PAGE_EDIT_PATH_PATTERN}>
                <CategoryDetailsPage />
              </Route>
              <Route exact path={LESSONS_PAGE_PAGE_PATH_PATTERN}>
                <LessonsPage />
              </Route>
              <Route exact path={EMAIL_EDIT_PAGE_PAGE_PATH_PATTERN}>
                <EmailEditPage />
              </Route>
              <Route exact path={EMAIL_PAGE_PAGE_PATH_PATTERN}>
                <EmailPage />
              </Route>
              <Route exact path={QUIZ_DETAILS_PAGE_PAGE_PATH_PATTERN}>
                <QuizDetailsPage />
              </Route>
              <Route exact path={QUIZZES_PAGE_PAGE_PATH_PATTERN}>
                <QuizzesPage />
              </Route>

              <Route exact path={MEMBER_PAGE_PAGE_PATH_PATTERN}>
                <MemberPage />
              </Route>
              <Route exact path={MEMBER_DETAILS_PAGE_PAGE_PATH_PATTERN}>
                <MemberDetailsPage />
              </Route>
              <Route exact path={LEAD_PAGE_PAGE_PATH_PATTERN}>
                <LeadPage />
              </Route>
              <Route exact path={LEAD_DETAILS_PAGE_PAGE_PATH_PATTERN}>
                <LeadDetailsPage />
              </Route>
              <Route exact path={SETTING_PAGE_PAGE_PATH_PATTERN}>
                <SettingPage />
              </Route>
              <Route exact path="/">
                <Redirect to={generateMemberPagePath()} />
              </Route>
            </AuthorizedRoutesWrapper>
          </IonRouterOutlet>
        </IonSplitPane>
      </>
    </IonReactRouter>
  );
});

export default Bootstrap;
