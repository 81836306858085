import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonRouterLink,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useCallback } from "react";
import { AdminFetchAlbumAndAlbumPhotos_adminFetchAlbumPhotos_data } from "../../graphql/__generated__/AdminFetchAlbumAndAlbumPhotos";
import { generateAlbumPhotoDetailsPagePath } from "../../pages/AlbumPhotoDetails/AlbumPhotoDetailsPage";

import "./AlbumPhotoItem.css";

interface Props {
  isCoverPhoto: boolean;
  albumPhoto: AdminFetchAlbumAndAlbumPhotos_adminFetchAlbumPhotos_data;
  onDelete: (uuid: string) => void;
  onIsCoverPhotoChange: (uuid: string, checked: boolean) => void;
}

const AlbumPhotoItem: React.FC<Props> = React.memo((props) => {
  const { isCoverPhoto, albumPhoto, onDelete, onIsCoverPhotoChange } = props;

  const _onDelete = useCallback(
    (event: React.MouseEvent<HTMLIonButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete(albumPhoto.uuid);
    },
    [albumPhoto, onDelete]
  );

  const onCoverPhotoCheckboxClick = useCallback(
    (event: React.MouseEvent<HTMLIonCheckboxElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onIsCoverPhotoChange(albumPhoto.uuid, !isCoverPhoto);
    },
    [albumPhoto.uuid, isCoverPhoto, onIsCoverPhotoChange]
  );

  return (
    <IonRouterLink
      className="album-photo-item"
      routerLink={generateAlbumPhotoDetailsPagePath(albumPhoto.uuid)}
    >
      <div className="album-photo-item__image-wrapper">
        <IonImg
          className="album-photo-item__image"
          src={albumPhoto.image.readSignedUrl}
        />
      </div>
      <IonButton
        className="album-photo-item__delete-btn"
        color="light"
        size="small"
        onClick={_onDelete}
      >
        <IonIcon slot="icon-only" icon={trash} />
      </IonButton>
      <IonLabel className="album-photo-item__caption">
        {albumPhoto.alt}
      </IonLabel>
      <IonItem color="white" lines="none">
        <IonCheckbox
          className="album-photo-item__cover-photo-checkbox"
          color="secondary"
          checked={isCoverPhoto}
          onClick={onCoverPhotoCheckboxClick}
        />
        <IonLabel
          className="album-photo-item__cover-photo-checkbox-label"
          color="medium"
        >
          封面相片
        </IonLabel>
      </IonItem>
    </IonRouterLink>
  );
});

export default AlbumPhotoItem;
