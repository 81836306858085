import { useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  SearchbarChangeEventDetail,
} from "@ionic/react";
import { linkOutline } from "ionicons/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ADMIN_FETCH_MEMBERS_QUERY } from "../../graphql/queries";
import {
  AdminFetchMembers,
  AdminFetchMembersVariables,
} from "../../graphql/__generated__/AdminFetchMembers";
import { useErrorHandler, useImperativeErrorHandler } from "../../hooks/useErrorHandler";

export interface SimpleUser {
  id: string;
  username: string;
}

export function createSimpleUserFromUserLike<T extends SimpleUser>(
  user: T
): SimpleUser {
  return {
    id: user.id,
    username: user.username,
  };
}

interface Props {
  selectedMember: SimpleUser | undefined;
  isShowModal: boolean;
  dismissModal: () => void;
  onSelect: (member: SimpleUser | undefined) => void;
}

const MEMBERS_PER_FETCH = 10;

const MemberSelectModal: React.FC<Props> = React.memo((props) => {
  const { selectedMember, isShowModal, dismissModal, onSelect } = props;

  const [searchKeyword, setSearchKeyword] = useState("");
  const [_selectedMember, _setSelectedMember] = useState<
    SimpleUser | undefined
  >(() => (selectedMember ? { ...selectedMember } : undefined));

  const {
    data: membersData,
    loading: loadingMoreMembers,
    fetchMore: fetchMoreMembers,
    error: fetchMembersError,
  } = useQuery<AdminFetchMembers, AdminFetchMembersVariables>(
    ADMIN_FETCH_MEMBERS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        keyword: searchKeyword,
        offset: 0,
        limit: MEMBERS_PER_FETCH,
      },
    }
  );

  useErrorHandler(fetchMembersError, {
    name: "membersData",
  });
  const handleFetchMoreMembersError = useImperativeErrorHandler({
    name: "fetchMoreMembers",
  });

  const memberList = useMemo(() => {
    return membersData?.adminFetchMembers?.data;
  }, [membersData]);

  const memberListWithoutSelected = useMemo(() => {
    if (!memberList) {
      return undefined;
    }
    if (_selectedMember === undefined) {
      return memberList;
    }
    return memberList.filter((member) => {
      return _selectedMember.id !== member.id;
    });
  }, [memberList, _selectedMember]);

  const count = useMemo(
    () => membersData?.adminFetchMembers?.count,
    [membersData]
  );

  const allLoaded = useMemo(
    () =>
      !!memberList && count !== undefined ? memberList.length >= count : false,
    [count, memberList]
  );

  const onModalDidDismiss = useCallback(() => {
    if (isShowModal) {
      dismissModal();
    }
  }, [isShowModal, dismissModal]);

  const onClickClose = useCallback(() => {
    dismissModal();
  }, [dismissModal]);

  const onClickFinish = useCallback(() => {
    if (_selectedMember) {
      onSelect(createSimpleUserFromUserLike(_selectedMember));
    } else {
      onSelect(undefined);
    }
    dismissModal();
  }, [_selectedMember, dismissModal, onSelect]);

  const onSearchbarChange = useCallback(
    (event: CustomEvent<SearchbarChangeEventDetail>) => {
      setSearchKeyword(event.detail.value ?? "");
    },
    []
  );

  const onSelectedItemClick = useCallback(() => {
    _setSelectedMember(undefined);
  }, []);

  const loadMoreData = useCallback(
    async (event: CustomEvent<void>) => {
      try {
        if (allLoaded || !memberList) {
          return;
        }
        await fetchMoreMembers({
          variables: {
            offset: memberList.length,
          },
        });
      } catch (error) {
        handleFetchMoreMembersError(error);
      } finally {
        (event.target as HTMLElementTagNameMap["ion-infinite-scroll"]).complete();
      }
    },
    [allLoaded, fetchMoreMembers, handleFetchMoreMembersError, memberList]
  );

  const selectedMemberItemComponent = useMemo(() => {
    if (!_selectedMember) {
      return null;
    }
    return (
      <IonItem
        lines="none"
        color="white"
        key={_selectedMember.id}
        onClick={onSelectedItemClick}
      >
        <IonLabel className="ion-text-wrap">
          <IonText color="dark">
            <h2>{_selectedMember.username}</h2>
          </IonText>
          <IonText color="medium">
            <p>{_selectedMember.id}</p>
          </IonText>
        </IonLabel>
        <IonIcon
          color="primary"
          ios={linkOutline}
          md={linkOutline}
          slot="end"
        />
      </IonItem>
    );
  }, [_selectedMember, onSelectedItemClick]);

  const memberItemComponents = useMemo(() => {
    if (!memberListWithoutSelected) {
      return null;
    }
    return memberListWithoutSelected.map((member) => {
      return (
        <IonItem
          lines="none"
          color="white"
          key={`${member.id}`}
          onClick={() => {
            _setSelectedMember({
              id: member.id,
              username: member.username,
            });
          }}
        >
          <IonLabel className="ion-text-wrap">
            <IonText color="dark">
              <h2>{member.username}</h2>
            </IonText>
            <IonText color="medium">
              <p>{member.id}</p>
            </IonText>
          </IonLabel>
        </IonItem>
      );
    });
  }, [memberListWithoutSelected]);

  // Reset _selectedMember when modal appear
  useEffect(() => {
    if (isShowModal) {
      _setSelectedMember(selectedMember);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModal]);

  return (
    <IonModal isOpen={isShowModal} onDidDismiss={onModalDidDismiss}>
      <IonContent>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={onClickClose}>
                關閉
              </IonButton>
            </IonButtons>
            <IonTitle>連結成員</IonTitle>
            <IonButtons slot="primary">
              <IonButton color="primary" onClick={onClickFinish}>
                完成
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card">
            <IonCardContent>
              <IonSearchbar debounce={500} onIonChange={onSearchbarChange} />
            </IonCardContent>
          </IonCard>

          {loadingMoreMembers && (
            <IonCard className="no-margin-card no-margin-top ">
              <IonCardContent className="ion-text-center">
                <IonSpinner />
              </IonCardContent>
            </IonCard>
          )}

          <IonListHeader>
            <IonLabel>已連結成員</IonLabel>
          </IonListHeader>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>{selectedMemberItemComponent}</IonCardContent>
          </IonCard>

          <IonListHeader>
            <IonLabel>其他成員</IonLabel>
          </IonListHeader>

          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>{memberItemComponents}</IonCardContent>
          </IonCard>
        </IonList>
        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          />
        </IonInfiniteScroll>
      </IonContent>
    </IonModal>
  );
});

export default MemberSelectModal;
