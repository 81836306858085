import classNames from "classnames";
import React from "react";

import "./HorizontalPaddingContainer.css";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const HorizontalPaddingContainer: React.FC<Props> = React.memo((props) => {
  const { className, children } = props;

  return (
    <div className={classNames("horizontal-padding-container", className)}>
      {children}
    </div>
  )
});

export default HorizontalPaddingContainer;
