import { useIonPopover } from "@ionic/react";
import { useCallback, useMemo, useState } from "react";
import ProgressDialog, {
  ProgressDialogProps,
} from "../components/Dialog/ProgressDialog";

export function useProgressDialog() {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const progressDialogProps = useMemo<ProgressDialogProps>(() => {
    return {
      progress,
      message,
    };
  }, [progress, message]);

  const [_present, _dismiss] = useIonPopover(
    ProgressDialog,
    progressDialogProps
  );

  const updateProgress = useCallback((progress: number) => {
    setProgress(progress);
  }, []);

  const updateMessage = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const present = useCallback(() => {
    setProgress(0);
    _present({
      cssClass: "progress-dialog__popover",
      backdropDismiss: false,
    });
    return {
      updateProgress: updateProgress,
      updateMessage: updateMessage,
      dismiss: _dismiss,
    };
  }, [_dismiss, _present, updateMessage, updateProgress]);

  return present;
}
