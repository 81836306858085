import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonLoading,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  TextareaChangeEventDetail,
  useIonAlert,
} from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { generatePath, useHistory, useParams } from "react-router";
import ContentContainer from "../../components/Container/ContentContainer";
import {
  ADMIN_FETCH_ALBUMS,
  ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTO,
  ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS,
  ADMIN_REMOVE_ALBUM_PHOTO,
  ADMIN_UPDATE_ALBUM_PHOTO,
} from "../../graphql/queries";
import {
  AdminFetchAlbumAndAlbumPhoto,
  AdminFetchAlbumAndAlbumPhotoVariables,
} from "../../graphql/__generated__/AdminFetchAlbumAndAlbumPhoto";
import {
  AdminRemoveAlbumPhoto,
  AdminRemoveAlbumPhotoVariables,
} from "../../graphql/__generated__/AdminRemoveAlbumPhoto";
import {
  AdminUpdateAlbumPhoto,
  AdminUpdateAlbumPhotoVariables,
} from "../../graphql/__generated__/AdminUpdateAlbumPhoto";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { evictQueryCache } from "../../utils/apollo";
import { generateAlbumsPagePath } from "../Albums/AlbumsPage";
import { generateAlbumPhotosPagePath } from "../AlbumPhotosPage/AlbumPhotosPage";

export const ALBUM_PHOTO_DETAILS_PAGE_PATH_PATTERN = "/album-photo/:uuid";

export function generateAlbumPhotoDetailsPagePath(uuid: string): string {
  return generatePath(ALBUM_PHOTO_DETAILS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const AlbumPhotoDetailsPage: React.FC = React.memo(() => {
  const history = useHistory();
  const { uuid } = useParams<{
    uuid: string;
  }>();
  const [presentAlert] = useIonAlert();
  const apolloClient = useApolloClient();

  const [caption, setCaption] = useState("");

  const {
    data: albumPhotoData,
    loading: loadingAlbumPhoto,
    error: fetchAlbumPhotoError,
  } = useQuery<
    AdminFetchAlbumAndAlbumPhoto,
    AdminFetchAlbumAndAlbumPhotoVariables
  >(ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTO, {
    variables: {
      albumPhotoUuid: uuid,
    },
  });

  const [updateAlbumPhoto, { loading: updatingAlbumPhoto }] = useMutation<
    AdminUpdateAlbumPhoto,
    AdminUpdateAlbumPhotoVariables
  >(ADMIN_UPDATE_ALBUM_PHOTO);

  const [removeAlbumPhoto, { loading: removingAlbumPhoto }] = useMutation<
    AdminRemoveAlbumPhoto,
    AdminRemoveAlbumPhotoVariables
  >(ADMIN_REMOVE_ALBUM_PHOTO);

  useErrorHandler(fetchAlbumPhotoError, {
    name: "photoData",
  });

  const handleUpdateAlbumPhotoError = useImperativeErrorHandler({
    name: "updateAlbumPhoto",
  });

  const handleRemoveAlbumPhotoError = useImperativeErrorHandler({
    name: "removeAlbumPhoto",
  });

  const albumPhotosPageLink = useMemo(() => {
    const uuid = albumPhotoData?.adminFetchAlbumByAlbumPhoto?.uuid;
    if (!uuid) {
      return undefined;
    }
    return generateAlbumPhotosPagePath(uuid);
  }, [albumPhotoData?.adminFetchAlbumByAlbumPhoto]);

  const onClickSave = useCallback(async () => {
    try {
      await updateAlbumPhoto({
        variables: {
          uuid: uuid,
          alt: caption !== "" ? caption : null,
        },
      });
    } catch (error) {
      handleUpdateAlbumPhotoError(error);
    }
  }, [caption, handleUpdateAlbumPhotoError, updateAlbumPhoto, uuid]);

  const onCaptionChange = useCallback(
    (event: CustomEvent<TextareaChangeEventDetail>) => {
      setCaption(event.detail.value!);
    },
    []
  );

  const onClickDelete = useCallback(() => {
    presentAlert("你確定要刪除相片嗎？", [
      {
        text: "取消",
        role: "cancel",
      },
      {
        text: "確定",
        handler: async () => {
          try {
            await removeAlbumPhoto({
              variables: {
                uuid: uuid,
              },
            });
            if (albumPhotosPageLink) {
              evictQueryCache(apolloClient.cache, ADMIN_FETCH_ALBUMS);
              evictQueryCache(
                apolloClient.cache,
                ADMIN_FETCH_ALBUM_AND_ALBUM_PHOTOS
              );
              history.replace(albumPhotosPageLink);
            }
          } catch (error) {
            handleRemoveAlbumPhotoError(error);
          }
        },
      },
    ]);
  }, [
    albumPhotosPageLink,
    apolloClient.cache,
    handleRemoveAlbumPhotoError,
    history,
    presentAlert,
    removeAlbumPhoto,
    uuid,
  ]);

  useEffect(() => {
    setCaption(albumPhotoData?.adminFetchAlbumPhoto?.alt ?? "");
  }, [albumPhotoData?.adminFetchAlbumPhoto?.alt]);

  return (
    <IonPage>
      <IonLoading
        isOpen={loadingAlbumPhoto || updatingAlbumPhoto || removingAlbumPhoto}
      />
      <IonHeader>
        <IonToolbar color="white">
          <IonButtons slot="start">
            <IonBackButton
              color="primary"
              defaultHref={albumPhotosPageLink ?? generateAlbumsPagePath()}
            />
          </IonButtons>
          <IonTitle>相集相片</IonTitle>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={onClickSave}>
              儲存
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContentContainer>
          <IonCard>
            <IonCardContent>
              <IonCardHeader>
                <IonCardTitle>
                  {albumPhotoData?.adminFetchAlbumByAlbumPhoto?.title}
                </IonCardTitle>
              </IonCardHeader>
              <IonImg
                src={albumPhotoData?.adminFetchAlbumPhoto?.image.readSignedUrl}
              />
              <IonTextarea
                className="ion-margin-vertical"
                placeholder="Caption"
                autoGrow={true}
                rows={1}
                value={caption}
                onIonChange={onCaptionChange}
              />
              <IonButton color="danger" expand="block" onClick={onClickDelete}>
                刪除相片
              </IonButton>
            </IonCardContent>
          </IonCard>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
});

export default AlbumPhotoDetailsPage;
