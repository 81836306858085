import { ApolloCache, DocumentNode, ServerError } from "@apollo/client";
import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldPolicy,
  FieldReadFunction,
  KeySpecifier,
} from "@apollo/client/cache/inmemory/policies";
import { FieldNode } from "graphql";

interface KeyArgItem<T, N extends keyof T = keyof T> {
  name: N;
  subfields?: KeyArgItem<NonNullable<T[N]>>[];
}

export function isApolloServerError(value: unknown): value is ServerError {
  return (
    value instanceof Error
    && "response" in value
    && "result" in value
    && "statusCode" in value
    && typeof value["response"] === "object"
    && typeof value["result"] === "object"
    && typeof value["statusCode"] === "number"
  );
}

/**
 * Create KeyArgItem with enhanced type inference.
 */
export function createKeyArgItem<T, N extends keyof T = keyof T>(
  name: N,
  subfields?: KeyArgItem<NonNullable<T[N]>>[]
): KeyArgItem<T, N> {
  return {
    name,
    subfields,
  };
}

/**
 * Flatten argItems to Apollo's nested argument keyArgs array.
 * @see {@link https://www.apollographql.com/docs/react/pagination/key-args#keyargs-array}
 */
function flattenArgItems<T>(argItems: KeyArgItem<T>[]): KeySpecifier {
  return argItems
    .map((item) => [
      item.name as string,
      ...(item.subfields ? [flattenArgItems(item.subfields)] : []),
    ])
    .reduce<KeySpecifier>(
      (list, keySpecifier) => [...list, ...keySpecifier],
      []
    );
}

/**
 * Create Apollo's FieldPolicy with enhanced type inference.
 */
export function createFieldPolicy<
  T extends Record<string, any>,
  TVars extends Record<string, any>,
  TArgs = Record<string, any>
>(policy: {
  keyArgs?: KeyArgItem<TArgs>[];
  merge?: FieldMergeFunction<T, T, FieldFunctionOptions<TArgs, TVars>>;
  read?: FieldReadFunction<T, T, FieldFunctionOptions<TArgs, TVars>>;
}): FieldPolicy<T | undefined, T, T> {
  return {
    keyArgs: policy.keyArgs ? flattenArgItems(policy.keyArgs) : undefined,
    merge: policy.merge as any,
    read: policy.read as any,
  };
}

export function getFieldNodesFromDocNode(docNode: DocumentNode): FieldNode[] {
  const fields: FieldNode[] = [];
  for (const def of docNode.definitions) {
    if (def.kind !== "OperationDefinition") {
      continue;
    }
    for (const selection of def.selectionSet.selections) {
      if (selection.kind !== "Field") {
        continue;
      }
      fields.push(selection);
    }
  }
  return fields;
}

export function evictQueryCache(
  cache: ApolloCache<any>,
  docNode: DocumentNode
): void {
  const fields = getFieldNodesFromDocNode(docNode);
  for (const field of fields) {
    cache.evict({
      fieldName: field.name.value,
    });
  }
}
