import { useQuery } from "@apollo/client";
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { imagesOutline } from "ionicons/icons";
import React, { useCallback, useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { ADMIN_FETCH_ALBUMS } from "../../graphql/queries";
import {
  AdminFetchAlbums,
  AdminFetchAlbumsVariables,
} from "../../graphql/__generated__/AdminFetchAlbums";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { generateAlbumDetailsPagePath } from "../AlbumDetails/AlbumDetailsPage";
import { generateAlbumPhotosPagePath } from "../AlbumPhotosPage/AlbumPhotosPage";
import "./AlbumsPage.css";

export const ALBUMS_PAGE_PATH_PATTERN = "/albums";

export function generateAlbumsPagePath(): string {
  return generatePath(ALBUMS_PAGE_PATH_PATTERN);
}

const ALBUMS_PER_FETCH = 10;

const AlbumsPage: React.FC = React.memo(() => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const {
    data: albumsData,
    loading: loadingMoreAlbums,
    error: fetchAlbumsError,
    fetchMore: fetchMoreAlbums,
  } = useQuery<AdminFetchAlbums, AdminFetchAlbumsVariables>(
    ADMIN_FETCH_ALBUMS,
    {
      variables: {
        listQuery: {
          keyword: searchKeyword,
          offset: 0,
          limit: ALBUMS_PER_FETCH,
        },
      },
    }
  );

  useErrorHandler(fetchAlbumsError, {
    name: "albumsData",
  });
  const handleFetchMoreAlbumsError = useImperativeErrorHandler({
    name: "fetchMoreAlbums",
  });

  const albumsList = useMemo(() => {
    return albumsData?.adminFetchAlbums?.data;
  }, [albumsData]);

  const count = useMemo(
    () => albumsData?.adminFetchAlbums?.count,
    [albumsData]
  );

  const allLoaded = useMemo(
    () =>
      !!albumsList && count !== undefined ? albumsList.length >= count : false,
    [count, albumsList]
  );

  const loadMoreData = useCallback(
    async (ev: any) => {
      try {
        if (!albumsList) return;
        if (allLoaded) return;
        await fetchMoreAlbums({
          variables: {
            offset: albumsList.length,
          },
        });
      } catch (e) {
        handleFetchMoreAlbumsError(e);
      } finally {
        ev.target.complete();
      }
    },
    [albumsList, allLoaded, fetchMoreAlbums, handleFetchMoreAlbumsError]
  );

  return (
    <IonPage>
      <IonLoading isOpen={loadingMoreAlbums} />
      <IonContent fullscreen>
        <IonList>
          <IonToolbar color="white">
            <IonButtons slot="start">
              <IonMenuButton color="primary" />
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                routerLink={generateAlbumDetailsPagePath("new")}
              >
                增加相集
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              <IonCardTitle>
                <h1>相集</h1>
              </IonCardTitle>

              <IonSearchbar
                placeholder="搜尋"
                onIonChange={(e) => {
                  setSearchKeyword(e.detail.value ?? "");
                }}
              ></IonSearchbar>
            </IonCardContent>
          </IonCard>
          <IonCard className="no-margin-card no-margin-top ">
            <IonCardContent>
              {albumsList?.map((album) => {
                return (
                  <IonItem
                    detail
                    lines="none"
                    color="white"
                    key={`${album.title}`}
                    routerLink={generateAlbumPhotosPagePath(album.uuid)}
                  >
                    <IonAvatar
                      className="albums-page__item__avatar"
                      slot="start"
                    >
                      {album.albumCoverPhoto ? (
                        <IonImg
                          src={album.albumCoverPhoto?.image.readSignedUrl}
                        />
                      ) : (
                        <IonIcon
                          className="albums-page__item__avatar__placeholder"
                          icon={imagesOutline}
                        />
                      )}
                    </IonAvatar>
                    <IonLabel className="ion-text-wrap">
                      <IonText color="dark">
                        <h2>{album.title}</h2>
                      </IonText>
                      <IonText color="medium">
                        <p>{album.description}</p>
                      </IonText>
                    </IonLabel>
                    <IonBadge slot="end">{album.albumPhotosCount}</IonBadge>
                  </IonItem>
                );
              })}
            </IonCardContent>
          </IonCard>
        </IonList>
        <IonInfiniteScroll
          disabled={allLoaded}
          onIonInfinite={loadMoreData}
          threshold="100px"
          // disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="載入資料中..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});

export default AlbumsPage;
